import React, { useState, useEffect } from "react";
import Select, { MultiValue, ActionMeta } from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import EncryptDecryptService from "../../services/EncryptDecryptService";
import CustomAlert from "../../components/atoms/CustomAlert";
import { t } from "../Translations/TranslationUtils";
import InputTextfield from "../../components/atoms/InputTextField";
import LabelField from "../../components/atoms/LabelField";
import Button from "../../components/atoms/Button";
import ErrorMessage from "../../components/atoms/ErrorMessage";
import { APICALL } from "../../services/ApiServices";
import {
  createUserUrl,
  fetchLanguagesUrl,
  fetchRolesUrl,
  getusers,
  locationAndCountryUrl,
} from "../../routes/ApiEndPoints";
import MultiSelect, {
  OptionValue,
} from "../../components/atoms/MultiSelectField";
import AccessControl from "../../services/AccessControl";
import { CommonConstants } from "../Shipment/Constants/ShipmentConstants";
import { useUserContext } from "../../routes/Contextlib";
import WindowHeightComponent from "../../commonComponents/WindowHeightComponent";
import CommonServices from "../../services/CommonServices";
import AccessDenied from "../AccessDenied";

const CreateUser: React.FC = () => {
  const user_id = localStorage.getItem("user_id");

  interface FormValues {
    first_name: string;
    last_name: string;
    username: string;
    email: string;
    role: { value: string; label: string }[];
    supervisor: OptionValue[];
    user_id: string;
    user_language: OptionValue[];
    warehouse_location: OptionValue[];
    country: OptionValue[];
  }

  interface FormErrors {
    first_name: string;
    last_name: string;
    username: string;
    email: string;
    role: string; // Update type to array of strings
    supervisor: string;
    warehouse_location: string;
    user_language: string;
  }
  const { user, setUser } = useUserContext();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const editId = searchParams.get("edit");
  const [formValues, setFormValues] = useState<FormValues>({
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    role: [], // Initialize roles as an empty array
    supervisor: [],
    user_language: [],
    user_id: "",
    warehouse_location: [],
    country: [],
  });

  const [errors, setErrors] = useState<FormErrors>({
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    role: "", // Initialize roles as an empty array
    supervisor: "",
    warehouse_location: "",
    user_language: "",
  });
  const [role, setRole] = useState<Array<{ value: string; label: string }>>([]);
  const [country, setCountry] = useState<OptionValue[]>([]);
  const [supervisors, setSupervisors] = useState<OptionValue[]>([]);
  const [warehouseLocation, setWarehouseLocation] = useState<OptionValue[]>([]);
  const [languageOptions, setLanguageOptions] = useState<OptionValue[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [requiredPermissions, setRequiredPermissions] = useState<any>([]);

  //To display alert message
  useEffect(() => {
    const storageMessage = localStorage.getItem("errorAlertMessage");

    if (storageMessage) {
      localStorage.removeItem("errorAlertMessage");
      CustomAlert("error", t(storageMessage), 6000);
    }
  });

  useEffect(() => {
    let refresh = false;
    if (languageOptions?.length !== 0 && warehouseLocation?.length !== 0) {
      refresh = true;
    }

    if (editId && refresh) {
      fetchUserDetails(editId);
    }
  }, [country, warehouseLocation, languageOptions]);

  useEffect(() => {
    fetchSupervisors();
    const storedOptions = localStorage?.getItem("languageOptions");
    if (storedOptions) {
      try {
        const options = JSON.parse(storedOptions);
        setLanguageOptions(options);
      } catch (error) {
        fetchLanguages();
      }
    } else {
      fetchLanguages();
    }
    fetchCountriesAndLocations();
    fetchRoles();
  }, []);

  const fetchLanguages = async () => {
    try {
      const postData = {
        // Encrypting the request data
        method: "GET",
        data: {},
      };
      const response = await APICALL.service(
        fetchLanguagesUrl,
        "POST",
        postData,
        true
      );
      if (response?.status !== 200) {
        throw new Error("Request failed");
      }

      // Decrypting the response
      const mappedLanguages = response.data.map((item: any) => ({
        value: item.language_code,
        label: item.language_name,
      }));
      // Sort roles alphabetically by label
      mappedLanguages.sort((a: any, b: any) => a.label.localeCompare(b.label));
      setLanguageOptions(mappedLanguages);
    } catch (error) {
      console.error(error);
    }
  };
  // Fetch roles from backend and update the state
  const fetchRoles = async () => {
    try {
      const postData = {
        // Encrypting the request data
        method: "POST",
        data: EncryptDecryptService.encryptData(JSON.stringify({})),
      };
      const response = await APICALL.service(fetchRolesUrl, "POST", postData);
      let decrypteddata = JSON.parse(
        EncryptDecryptService.decryptData(response)
      );

      if (decrypteddata?.status !== 200) {
        throw new Error("Request failed");
      }
      // Decrypting the response
      const mappedRoles = decrypteddata.data
        ?.filter((item: any) => item.status === true)
        .map((item: any) => ({
          value: item.role_id,
          label: item.title.charAt(0).toUpperCase() + item.title.slice(1),
        }));
      // Sort roles alphabetically by label
      mappedRoles.sort((a: any, b: any) => a.label.localeCompare(b.label));
      setRole(mappedRoles);
    } catch (error) {
      console.error(error);
    }
  };
  // Fetch supervisors from backend and update the state
  const fetchSupervisors = async () => {
    try {
      const postData = {
        // Encrypting the request data
        method: "POST",
        data: EncryptDecryptService.encryptData(
          JSON.stringify({ supervisor: true })
        ),
      };
      const response = await APICALL.service(getusers, "POST", postData);
      let decrypteddata = JSON.parse(
        EncryptDecryptService.decryptData(response)
      );

      if (decrypteddata?.status !== 200) {
        throw new Error("Request failed");
      }
      const supervisorsData = decrypteddata.data;

      const supervisors = Object.values(supervisorsData)
        ?.map((user: any) => {
          if (
            user.status &&
            user.roles &&
            user_id != user.user_id &&
            Array.isArray(user.roles)
          ) {
            const firstName =
              user.first_name.charAt(0).toUpperCase() +
              user.first_name.slice(1);
            const lastName = user.last_name
              ? user.last_name.charAt(0).toUpperCase() + user.last_name.slice(1)
              : "";
            const username = `${firstName}${lastName ? " " + lastName : ""}`;
            return {
              value: user.user_id,
              label: username.trim(),
            } as OptionValue;
          }
          return undefined;
        })
        .filter(Boolean) as OptionValue[];
      // Sort supervisors alphabetically by username
      supervisors.sort((a, b) => a.label.localeCompare(b.label));
      setSupervisors(supervisors);
    } catch (error) {
      console.error(error);
    }
  };
  // Fetch countries
  const fetchCountriesAndLocations = async () => {
    try {
      const postData = {
        // Encrypting the request data
        method: "GET",
        data: EncryptDecryptService.encryptData(JSON.stringify({})),
      };
      const response = await APICALL.service(
        locationAndCountryUrl,
        "POST",
        postData
      );
      let decrypteddata = JSON.parse(
        EncryptDecryptService.decryptData(response)
      );
      if (decrypteddata?.status !== 200) {
        throw new Error("Request failed");
      }
      // Map the data and create an array of Country objects
      const mappedCountries: OptionValue[] = decrypteddata?.data?.country?.map(
        (item: any) => ({
          value: item.code,
          label: CommonServices.stringFormat(item.title),
        })
      );

      // Map the data and create an array of location objects
      const mappedLocations: OptionValue[] = decrypteddata?.data?.location?.map(
        (item: any) => ({
          value: item.warehouse_location_id,
          label: CommonServices.stringFormat(item.title),
        })
      );

      // Sort supervisors alphabetically by username
      mappedCountries?.sort((a, b) => a.label.localeCompare(b.label));
      setCountry(mappedCountries);

      // Sort locations alphabetically by locations
      mappedLocations?.sort((a, b) => a.label.localeCompare(b.label));
      setWarehouseLocation(mappedLocations);
    } catch (error) {
      console.error(error);
    }
  };
  // Fetch user details from the backend using the userId
  const fetchUserDetails = async (editId: any) => {
    try {
      const postData = {
        // Encrypting the request data
        method: "POST",
        data: EncryptDecryptService.encryptData(
          JSON.stringify({ userid: editId })
        ),
      };
      const response = await APICALL.service(getusers, "POST", postData);
      let decrypteddata = JSON.parse(
        EncryptDecryptService.decryptData(response)
      );
      if (decrypteddata?.status !== 200) {
        throw new Error("Request failed");
      }
      const userData = decrypteddata?.data[0];
      const supervisorOption = supervisors.find(
        (s) => s.value === userData.supervisor_id
      );
      const warehouseLocationOption = warehouseLocation.find(
        (w) => w.value === userData.warehouse_location
      );
      const countryOption = country.find((c) => c.value === userData.country);
      const userLanguageOption = languageOptions?.find(
        (c) => c.value === userData?.user_language
      );

      setFormValues({
        ...formValues,
        user_id: editId,
        first_name: userData.first_name ? userData.first_name : "",
        last_name: userData.last_name ? userData.last_name : "",
        username: userData.username ? userData.username : "",
        email: userData.email ? userData.email : "",
        role: userData?.roles
          ?.filter((role: any) => role?.status == true)
          .map((role: any) => ({
            value: role.role_id,
            label: role.title,
          })),
        supervisor: supervisorOption ? [supervisorOption] : [],
        user_language: userLanguageOption ? [userLanguageOption] : [],
        country: countryOption ? [countryOption] : [],
        warehouse_location: warehouseLocationOption
          ? [warehouseLocationOption]
          : [],
      });
    } catch (error) {
      console.error(error);
    }
  };

  //To handle change for every field
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  //To handle roles change
  const handleRolesChange = (
    selectedRoles: MultiValue<{ value: string; label: string }>,
    actionMeta: ActionMeta<{ value: string; label: string }>
  ) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      role: selectedRoles as { value: string; label: string }[],
    }));
  };

  //To remove error
  const removeError = (fieldName: string) => {
    // Handle the click event here
    setErrors((prevState) => ({
      ...prevState,
      [fieldName]: "", // Set the value of the field dynamically
    }));
  };

  //To handle the submit
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const validationErrors = validateForm();
    if (validationErrors === false) {
      try {
        setIsSaving(true);
        // Convert selected values to single values
        let supervisor: any = formValues?.supervisor;
        let warehouse_location: any = formValues?.warehouse_location;
        let country: any = formValues?.country;
        let language: any = formValues?.user_language;
        
        const convertedFormValues: any = {
          ...formValues,
          user_language: language ? (language[0]?.value ?? language?.value) : null,
          supervisor: supervisor ? (supervisor[0]?.value ?? supervisor?.value) : null,
          warehouse_location: warehouse_location ? 
            (warehouse_location[0]?.value ??
            warehouse_location?.value) : null,
          country: country ? (country[0]?.value ?? country?.value) : null,
        };
        const postData = {
          // Encrypting the request data
          method: "POST",
          data: convertedFormValues,
        };
        const response = await APICALL.service(
          createUserUrl,
          "POST",
          postData,
          true
        );

        if (response?.status === 409) {
          const validationErrors: FormErrors = {
            first_name: "",
            last_name: "",
            username: response?.filed === "username" ? response?.message : "",
            email: response?.filed === "email" ? response?.message : "",
            role: "",
            supervisor: "",
            warehouse_location: "",
            user_language: "",
          };
          setErrors(validationErrors);
          setIsSaving(false);
        } else if (response?.status !== 200) {
          setIsSaving(false);
          CustomAlert(
            "error",
            t(`Error while ${editId ? "updating" : "creating"} user`),
            6000
          );
          throw new Error("Request failed");
        } else {
          localStorage.setItem("successAlertMessage", response?.message);
          // Redirect to the manageUsers page with updated state
          const userPerm: any = user?.permissions;
          const truePerm: any = userPerm?.find(
            (item: any) => item.permission.toLowerCase().trim() === "users"
          );
          if (truePerm?.read === true) {
            navigate("/users/manage");
          } else {
            navigate("/backend");
          }
        }
      } catch (error) {
        setIsSaving(false);
        localStorage.setItem(
          "errorAlertMessage",
          t(`Error while ${editId ? "updating" : "creating"} user`)
        );
        console.error(error);
      }
    } else {
      // Form is invalid, set the validation errors
      console.log("Validation Errors:", validationErrors);
    }
  };

  //Validation rules for each field
  const validateForm = (): FormErrors | boolean => {
    const validationErrors: FormErrors = {
      first_name: "",
      last_name: "",
      username: "",
      email: "",
      role: "",
      supervisor: "",
      warehouse_location: "",
      user_language: "",
    };

    // Add validation rules for each field
    if (formValues.first_name.trim() === "") {
      validationErrors.first_name = t("First name is required");
    } else if (formValues.first_name.trim().length > 255) {
      validationErrors.first_name = t(
        "First name should be less than 255 characters"
      );
    }

    if (formValues.last_name.trim() === "") {
      validationErrors.last_name = t("Last name is required");
    } else if (formValues.last_name.trim().length > 255) {
      validationErrors.last_name = t(
        "Last name should be less than 255 characters"
      );
    }

    if (formValues.username.trim() === "") {
      validationErrors.username = t("Username is required");
    } else if (formValues.username.trim().length > 255) {
      validationErrors.username = t(
        "Username should be less than 255 characters"
      );
    }

    if (formValues.email?.trim() !== "" && !isValidEmail(formValues.email)) {
      validationErrors.email = t("Invalid email format");
    }

    if (formValues.role.length === 0) {
      validationErrors.role = t("Role is required");
    }

    if (
      !formValues?.warehouse_location ||
      formValues?.warehouse_location?.length === 0
    ) {
      validationErrors.warehouse_location = t("Warehouse location is required");
    }

    if (!formValues?.user_language || formValues?.user_language?.length === 0) {
      validationErrors.user_language = t("Language is required");
    }
    setErrors(validationErrors);

    if (
      validationErrors.first_name === "" &&
      validationErrors.last_name === "" &&
      validationErrors.username === "" &&
      validationErrors.email === "" &&
      validationErrors.role === "" &&
      validationErrors.warehouse_location === "" &&
      validationErrors.user_language === ""
    ) {
      return false;
    } else {
      return validationErrors;
    }
  };

  const isValidEmail = (email: string): boolean => {
    // Basic email validation pattern
    const emailRegex =
      /^[\w\s\S]+([\.-]?[\w\s\S]+)*@[\w\s\S]+([\.-]?[\w\s\S]+)*(\.[\w\s\S]{2,3})+$/;
    return emailRegex.test(email);
  };

  const hasError = (field: keyof FormErrors): boolean => {
    return Array.isArray(errors[field])
      ? errors[field].length > 0
      : !!errors[field];
  };

  const handleBackButtonClick = () => {
    if (editId) {
      navigate("/users/manage");
    } else {
      navigate("/backend");
    }
  };

  useEffect(() => {
    let permissions = [
      {
        permission: "users",
        create: true,
        update: true,
      },
    ];
    if (editId) {
      permissions[0].create = false;
    } else {
      permissions[0].update = false;
    }
    setRequiredPermissions(permissions);
  }, [editId]);

  return ( 
    <AccessControl
      requiredPermissions={requiredPermissions}
      renderNoAccess={true}
    >
    {(editId == null || (editId !== undefined && 
      editId != process.env.REACT_APP_ADMIN_USER_ID)) ? (
      <div className="row">
        <div className="col-md-11 ms-auto px-4">
          {/* Page title */}
          <WindowHeightComponent>
            <div className="d-flex flex-column h-100">
              <div className="py-xxl-4 py-xl-3 py-lg-3 page-title top-0">
                {editId ? t("Update user") : t("Create user")}
              </div>
              {/* form */}
              <div className="flex-1 overflow-auto">
                <form className="h-100">
                  <div className="d-flex flex-column h-100">
                    <div className="flex-1 overflow-auto">
                      <div className="create-form p-4">
                        <div className="row m-0">
                          <div className="col-md-10 m-auto">
                            <div className="row">
                              {/* First name */}
                              <div className="col-md-6 mb-3">
                                <div className="pe-2">
                                  <LabelField
                                    title={t("First name")}
                                    mandatory={true}
                                  />
                                  <InputTextfield
                                    id="last_name"
                                    type="text"
                                    name="first_name"
                                    value={formValues.first_name}
                                    isDisabled={editId ? true : false}
                                    handleChange={handleChange}
                                    customStyle={
                                      hasError("first_name")
                                        ? { borderColor: "red" }
                                        : {}
                                    }
                                    className="form-control shadow-none"
                                    handleClick={() =>
                                      removeError("first_name")
                                    }
                                  />
                                  {hasError("first_name") && (
                                    <ErrorMessage
                                      errorMessage={t(errors.first_name)}
                                      className="error"
                                      customStyle={{ color: "red" }}
                                    />
                                  )}
                                </div>
                              </div>
                              {/* Last name */}
                              <div className="col-md-6 mb-3">
                                <div className="ps-2">
                                  <label htmlFor="last_name">
                                    {t("Last name")}{" "}
                                    <span className="asterick">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    id="last_name"
                                    name="last_name"
                                    disabled={editId ? true : false}
                                    value={formValues.last_name}
                                    onChange={handleChange}
                                    style={
                                      hasError("last_name")
                                        ? { borderColor: "red" }
                                        : {}
                                    }
                                    className="form-control shadow-none"
                                    onClick={() => removeError("last_name")}
                                  />
                                  {hasError("last_name") && (
                                    <div
                                      className="error"
                                      style={{ color: "red" }}
                                    >
                                      {t(errors.last_name)}
                                    </div>
                                  )}
                                </div>
                              </div>

                              {/* Username */}
                              <div className="col-md-6 mb-3">
                                <div className="pe-2">
                                  <label htmlFor="username">
                                    {t("Username")}{" "}
                                    <span className="asterick"> *</span>
                                  </label>
                                  <input
                                    type="text"
                                    id="username"
                                    name="username"
                                    value={formValues.username}
                                    onChange={handleChange}
                                    disabled={editId ? true : false}
                                    className="form-control shadow-none"
                                    style={
                                      hasError("username")
                                        ? { borderColor: "red" }
                                        : {}
                                    }
                                    onClick={() => removeError("username")}
                                  />
                                  {hasError("username") && (
                                    <div
                                      className="error"
                                      style={{ color: "red" }}
                                    >
                                      {t(errors.username)}
                                    </div>
                                  )}
                                </div>
                              </div>
                              {/* Email */}
                              <div className="col-md-6 mb-3">
                                <div className="ps-2">
                                  <label htmlFor="email">{t("Email")}</label>
                                  <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formValues.email}
                                    className="form-control shadow-none"
                                    onChange={handleChange}
                                    style={
                                      hasError("email")
                                        ? { borderColor: "red" }
                                        : {}
                                    }
                                    onClick={() => removeError("email")}
                                    // disabled={editId ? true : false}
                                  />
                                  {hasError("email") && (
                                    <div
                                      className="error"
                                      style={{ color: "red" }}
                                    >
                                      {t(errors.email)}
                                    </div>
                                  )}
                                </div>
                              </div>
                              {/* Supervisor */}
                              <div className="col-md-6 mb-3 multi-border-none">
                                <div className="pe-2">
                                  <label htmlFor="supervisor">
                                    {t("Supervisor")}{" "}
                                  </label>
                                  <MultiSelect<OptionValue[]>
                                    options={supervisors} // Provide your array of supervisor options
                                    standards={formValues.supervisor}
                                    handleChange={(newValue, actionMeta) => {
                                      setFormValues((prevValues) => ({
                                        ...prevValues,
                                        supervisor: newValue as OptionValue[],
                                      }));
                                    }}
                                    placeholder={t("Select")}
                                    menuPlacement="auto"
                                    isSearchable={true}
                                    isMulti={false}
                                    name="supervisor"
                                    classNamePrefix=""
                                    isClearable={true}
                                  />
                                </div>
                              </div>
                              {/* Roles */}
                              <div className="col-md-6 user-roles mb-3 ">
                                <div className="ps-2">
                                  <label htmlFor="role">
                                    {t("Roles")}{" "}
                                    <span className="asterick"> *</span>
                                  </label>
                                  <div onClick={() => removeError("role")}>
                                    <Select
                                      id="role"
                                      name="role"
                                      isMulti
                                      options={role}
                                      value={formValues.role}
                                      onChange={handleRolesChange}
                                      placeholder="Select"
                                      // onClick={removeError}
                                      styles={{
                                        control: (provided, state) => ({
                                          ...provided,
                                          borderColor: hasError("role")
                                            ? "red !important"
                                            : provided.borderColor,
                                        }),
                                      }}
                                      className="form-control shadow-none"
                                      classNamePrefix="select"
                                    />
                                  </div>
                                  {hasError("role") && (
                                    <div
                                      className="error-message"
                                      style={{ color: "red" }}
                                    >
                                      {t(errors.role)}
                                    </div>
                                  )}
                                </div>
                              </div>

                              {/* Location */}
                              <div className="col-md-6 mb-3 multi-border-none">
                                <div
                                  onClick={() =>
                                    removeError("warehouse_location")
                                  }
                                  className="pe-2"
                                >
                                  <label htmlFor="location">
                                    {t("Warehouse location")}
                                    <span className="asterick"> *</span>
                                  </label>
                                  <MultiSelect<OptionValue[]>
                                    options={warehouseLocation} // Provide your array of warehouse_location options
                                    standards={formValues.warehouse_location}
                                    handleChange={(newValue, actionMeta) => {
                                      setFormValues((prevValues) => ({
                                        ...prevValues,
                                        warehouse_location:
                                          newValue as OptionValue[],
                                      }));
                                    }}
                                    customStyle={
                                      hasError("warehouse_location")
                                        ? {
                                            border: "1px solid red",
                                            borderRadius: "5px",
                                          }
                                        : undefined
                                    }
                                    placeholder={t("Select")}
                                    menuPlacement="auto"
                                    isSearchable={true}
                                    isMulti={false}
                                    name="warehouse_location"
                                    classNamePrefix="react-select-prefix"
                                    isClearable={true}
                                  />
                                  {hasError("warehouse_location") && (
                                    <div
                                      className="error"
                                      style={{ color: "red" }}
                                    >
                                      {t(errors.warehouse_location)}
                                    </div>
                                  )}
                                </div>
                              </div>
                              {/* Country */}
                              <div className="col-md-6 mb-3 multi-border-none">
                                <div className="ps-2">
                                  <label htmlFor="country">
                                    {t("Country")}
                                  </label>
                                  <MultiSelect<OptionValue[]>
                                    options={country} // Provide your array of country options
                                    standards={formValues.country}
                                    handleChange={(newValue, actionMeta) => {
                                      setFormValues((prevValues) => ({
                                        ...prevValues,
                                        country: newValue as OptionValue[],
                                      }));
                                    }}
                                    placeholder={t("Select")}
                                    menuPlacement="auto"
                                    isSearchable={true}
                                    isMulti={false}
                                    name="country"
                                    classNamePrefix="react-select-prefix"
                                    isClearable={true}
                                  />
                                </div>
                              </div>
                              {/* Languages */}
                              <div className="col-md-6 mb-3 multi-border-none">
                                <div
                                  onClick={() => removeError("user_language")}
                                  className="pe-2"
                                >
                                  <label htmlFor="role">
                                    {t("Languages")}
                                    <span className="asterick"> *</span>
                                  </label>
                                  <MultiSelect<OptionValue[]>
                                    options={languageOptions} // Provide your array of country options
                                    standards={formValues.user_language}
                                    handleChange={(newValue, actionMeta) => {
                                      setFormValues((prevValues) => ({
                                        ...prevValues,
                                        user_language:
                                          newValue as OptionValue[],
                                      }));
                                    }}
                                    customStyle={
                                      hasError("user_language")
                                        ? {
                                            border: "1px solid red",
                                            borderRadius: "5px",
                                          }
                                        : undefined
                                    }
                                    placeholder={t("Select")}
                                    menuPlacement="auto"
                                    isSearchable={true}
                                    isMulti={false}
                                    name="user_language"
                                    classNamePrefix="react-select-prefix"
                                    isClearable={true}
                                  />
                                  {hasError("user_language") && (
                                    <div
                                      className="error"
                                      style={{ color: "red" }}
                                    >
                                      {t(errors.user_language)}
                                    </div>
                                  )}
                                </div>
                                {/* end of row */}
                              </div>
                              {/* end of row */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Create button */}
                    <div className="row m-0 mt-2">
                      <div className="col-md-6 float-start p-0">
                        <Button
                          title={t(CommonConstants.BACK)}
                          handleClick={() => handleBackButtonClick()}
                          className="back-btn shadow-none float-start text-decoration-none"
                        />
                      </div>
                      <div className="col-md-6 float-end p-0">
                        <Button
                          title={
                            isSaving
                              ? t("Saving...")
                              : editId
                              ? t("Update user")
                              : t("Create user")
                          }
                          className="submit-btn shadow-none float-end"
                          disabled={isSaving}
                          handleClick={handleSubmit}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </WindowHeightComponent>
        </div>
        {/* end of col-md-11 */}
      </div>
    ) : (
      <AccessDenied />
    )}
    </AccessControl>
  );
};

export default CreateUser;
