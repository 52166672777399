import { useReducer, useEffect, useState } from "react";
import { UTILITYFN } from "../utility/Utility";
import "react-calendar/dist/Calendar.css";
import ColorPicker from "../utility/colorPicker";
import styles from "../../brandpartner.module.css"
import { t } from "../../../Translations/TranslationUtils";
import { GenerateRefreshToken } from "../../../../services/RefreshTokenService";
import ActionButtonGroup from "../../../../components/molecules/ActionButtonGroup";
import { useNavigate } from "react-router-dom";
import customAlert from "../../../../components/atoms/CustomAlert";
import FormValidation from "../../../../services/FormValidation";

const initialForm = {
  colors: [],
};

interface Props {
  draftSaved: boolean;
  backbutton: string;
  saveAsDraft: string;
  saveAndNext: string;
  formPageState: any;
  dispatchFormPageState: any;
  setPageDirection: (page: number) => void;
}

interface SelectedChildColors {
  [parentId: string]: { color: string; title?: string }[];
}

function SecondTab(props: Props) {
  const {
    draftSaved,
    backbutton,
    saveAndNext,
    saveAsDraft,
    formPageState,
    dispatchFormPageState,
    setPageDirection
  } = props;

  function formReducer(prevState: any, newsState: any) {
    return { ...prevState, ...newsState };
  }
  const [selectedParentColors, setSelectedParentColors] = useState<string[]>(
    []
  ); // Default selected parent colors
  const [color_code, setSelectedChildColors] =
    useState<SelectedChildColors>({});
  const [state, dispatch] = useReducer(formReducer, initialForm);
  const [hasError, setHasError] = useState(false);
  const navigate = useNavigate();
  window.addEventListener('beforeunload', () => {
    sessionStorage.clear()
  });

  useEffect(() => {
    if (sessionStorage?.getItem("colorState")) {
      const color = {
        ...JSON.parse(sessionStorage.getItem("colorState") || ""),
      };
      setSelectedChildColors(color?.colors);
    } else {
      sessionStorage.setItem("colorState", JSON.stringify(state));
    }
  }, []);

  useEffect(() => {
    setHasError(false);
    dispatch({ colors: color_code });
  }, [color_code]);

  const validate = () => {
    let errormessage: any;
    if (!(Object.values(state.colors).some((values: any) => values.length > 0))) {

      errormessage = t("No color is selected");
      return errormessage;
    } else {
      errormessage = Object.keys(state?.colors).some(colorKey => {
        return state?.colors[colorKey]?.some((colorObject: any) => {
          return FormValidation?.nameValidation(colorObject?.title, 20);
        });
      });
      return errormessage ? t("Some of the color title is empty or more than 20 characters") : "";
    }
  }
  const validateFormData = async (
    event: React.MouseEvent<HTMLButtonElement>,
    props: Props,
    draft?: boolean
  ) => {
    event.preventDefault();
    let updatedFormPageState = { ...formPageState };
    let haserror: any = validate();

    if (!draft && (haserror)) {
      // setHasError(true);
      customAlert("error", t(haserror), 6000);
      updatedFormPageState.pageTwo.validationStatus = false;
      dispatchFormPageState({ ...formPageState, ...updatedFormPageState });
    } else {
      try {

        let token = await GenerateRefreshToken();
        const bp_id = Number(sessionStorage.getItem("brand_partner_id"));
        const response = await fetch(
          process.env.REACT_APP_MASTER_DATA_URL +
          "api/master/create-brand-partner",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            body: JSON.stringify({
              // Encrypting the request data
              "bp_color_code": color_code,
              "brand_partner_id": bp_id
            }),
          }
        );
        if (response?.status === 200) {
          if (draft) {
            navigate('/brand-partner/manage')
          }
          sessionStorage.setItem("colorState", JSON.stringify(state));
          updatedFormPageState.pageTwo.validationStatus = true;
          dispatchFormPageState({ ...formPageState, ...updatedFormPageState });
          UTILITYFN.setCurrentPageData(props, 3);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  return (
    <>
      <form className="contract-date-form flex-1 overflow-auto d-flex flex-column h-100" >
        <div className={`${styles.colorbox} flex-1 mt-1 overflow-auto colorbox`}>
          <ColorPicker
            selectedChildColors={color_code}
            selectedParentColors={selectedParentColors}
            setSelectedParentColors={setSelectedParentColors}
            setSelectedChildColors={setSelectedChildColors}
          />
        </div>
        <div
          className={`invalid-feedback d-block text-center ${hasError ? "d-block" : "d-none"
            } `}
        >
        </div>
        <div>
          <ActionButtonGroup
            backTitle={t(backbutton)}
            saveAsDraftTitle={draftSaved ? "" : t(saveAsDraft)}
            saveAndNextTitle={t(saveAndNext)}
            handleBackClick={() => UTILITYFN.setCurrentPageData(props, 1)}
            handleSaveAsDraftClick={(e) => validateFormData(e, props, true)}
            handleSaveAndNextClick={(e) => validateFormData(e, props)}
          />
        </div>
      </form>
    </>
  );
}

export default SecondTab;
