import React, {
  useState,
  useEffect
} from "react";
import Layout from "../../pages/Shipment/Layout";
import { t } from "../../pages/Translations/TranslationUtils";
import { APICALL } from "../../services/ApiServices";
import {
  createFinishing,
  fetchConfigData,
  getFinishing,
} from "../../routes/ApiEndPoints";
import AdminCard from "../molecules/AdminDashBoardCard";
import { useNavigate } from "react-router-dom";
import Button from "../atoms/Button";
import CommonShipmentServices from "../../services/CommonShipmentServices";
import { WorkflowStageNames } from "../../utils/constants/WorkflowStageNames";
import styles from "../organisms/organisms.module.css"
import ScreenDisable from "../../utils/ScreenDisable";
import { CommonConstants } from "../../pages/Shipment/Constants/ShipmentConstants";
import { useUserContext } from "../../routes/Contextlib";
import CommonServices from "../../services/CommonServices";

const CreateFinishingOrganism: React.FC = () => {
  const navigate = useNavigate();
  const localdata = CommonShipmentServices.getPostData();
  const { user } = useUserContext();
  const urlParams = new URLSearchParams(window.location.search);
  const [finishingData, setFinishingData] = useState<any>();
  const [configData, setConfigData] = useState();
  const [selectedData, setSelectedData] = useState<Number[]>([]);
  const renewalid = urlParams.get("edit");
  const [totaltime, setTotalTime] = useState(0);
  const [timer, setTimer] = useState(true);
  const [commentData, setCommentData] = useState({
    type: "renewal_record",
    type_id: null,
    stagename: "finishing",
    commentdata: {}
  });
  const getConfigData = async () => {
    try {
      const postData = {
        method: "POST",
        data: {
          type: "model",
          name: "FinishingSteps",
        },
      };
      const response: any = await APICALL.service(
        fetchConfigData,
        "POST",
        postData,
        true
      );

      const activeData = response?.data?.filter((value: any) => value?.status == true);
      setConfigData(CommonServices.sortAlphabattically(activeData));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    try {
      const postData = {
        renewal_id: renewalid,
      };
      const response = await APICALL.service(getFinishing, "POST", postData);
      if (response?.status === 200) {
        setCommentData({ ...commentData, type_id: response?.data?.renewal_id, commentdata: response?.commentdata });
        setFinishingData(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const saveFinishing = async () => {
    try {
      const postData = {
        renewal_id: renewalid,
        serial_number: finishingData?.serial_number,
        ...localdata
      };

      const response = await APICALL.service(createFinishing, "POST", postData);
      if (response.status == 200) {
        navigate("/finishing/manage");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    timeTracker();

    return () => {
      if (user != null && commentData?.type_id != null) {
        CommonShipmentServices.saveTimerApi(
          'renewal',
          commentData?.type_id,
          user?.user_id,
          WorkflowStageNames.FINISHING,
          false
        );
      }
    };
  }, [commentData?.type_id != null, user != null]);

  const timeTracker = async () => {
    try {
      if (user != null && commentData?.type_id != null) {
        let time = await CommonShipmentServices.saveTimerApi(
          'renewal',
          commentData?.type_id,
          user?.user_id,
          WorkflowStageNames.FINISHING
        );
        // setTotalTime(time);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getConfigData();
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const handleClick = (item: any) => {
    if (timer) {
      if (selectedData.includes(item.finishing_steps_id)) {
        const updatedArray = selectedData.filter(
          (id: any) => id != item.finishing_steps_id
        );
        setSelectedData(updatedArray);
      } else {
        const updatedArray = [...selectedData, item.finishing_steps_id];
        setSelectedData(updatedArray);
      }
    }
  };

  const handleSubmit = () => {
    saveFinishing();
  };

  const setTimerRunning = (timer: boolean) => {
    setTimer(timer);
  }

  return (
    <Layout
      pagename={("Finishing")}
      logo={true}
      timer={true}
      comments={commentData}
      hold={true}
      ongoing
      totaltime={totaltime}
      stage={WorkflowStageNames.FINISHING}
      setTimerRunning={setTimerRunning}
    >
      <div className={`${styles.finising_body} flex-1 overflow-auto`}>
        <div className={`text-gray text-break ${styles.page_title}`} title={t('serial number')}>
          {finishingData?.serial_number}
        </div>
        <div className="d-flex flex-wrap gap-5 max-content-height position-relative px-2 justify-content-center">
          <ScreenDisable display="none" />
          {configData &&
            Object.values(configData)?.map((item: any) => {
              return (
                <AdminCard
                  title={item?.title}
                  className={
                    ` ${selectedData.includes(item.finishing_steps_id)
                      ? "border-danger  text-red"
                      : ""} ${styles.finishingcard}`
                  }
                  mainclassName={styles.finishing_card_outer}
                  handleClick={() => handleClick(item)}
                />
              );
            })}
        </div>
      </div>
      <div className={`d-flex justify-content-between mt-2`}>
        <Button
          className="back-btn "
          title={t(CommonConstants.BACK)}
          handleClick={() => navigate(localStorage?.getItem('shipment_dashboard_url') ?? "/shipment-dashboard")}
        // handleClick={() => navigate("/finishing/manage")}
        />
        <Button
          title={t(CommonConstants.SAVE_AND_SCAN_NEXT)}
          handleClick={handleSubmit}
          className="submit-btn shadow-none"
        />
      </div>
    </Layout>
  );
};
export default CreateFinishingOrganism;
