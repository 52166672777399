import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { t } from "./TranslationUtils";
import { APICALL } from "../../services/ApiServices";
import {
  createLanguageStringsUrl,
  fetchLanguageStringsUrl,
} from "../../routes/ApiEndPoints";
import AccessControl from "../../services/AccessControl";
import { CommonConstants } from "../Shipment/Constants/ShipmentConstants";
import PageNotFound from "../PageNotFound";
import WindowHeightComponent from "../../commonComponents/WindowHeightComponent";

const UpdateTranslations: React.FC = () => {
  interface languageInterface {
    translation_id: string;
    en: string;
    fr: string;
    nl: string;
  }
  interface FormErrors {
    en: string;
    fr: string;
    nl: string;
  }

  const [devString, setDevString] = useState<string>("");
  const [isSaving, setIsSaving] = useState(false);
  const [langState, setLangState] = useState<languageInterface>({
    translation_id: "",
    en: "",
    fr: "",
    nl: "",
  });
  const [errors, setErrors] = useState<FormErrors>({
    en: "",
    fr: "",
    nl: "",
  });
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const editId = Number(searchParams.get("edit"));

  useEffect(() => {
    fetchTranslations(editId);
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, name } = event.target;
    setLangState((prevState) => ({ ...prevState, [id]: event.target.value }));
  };

  //Validation rules for each field
  const validateForm = (): FormErrors | undefined => {
    const validationErrors: FormErrors = {
      en: "",
      fr: "",
      nl: "",
    };

    // Add validation rules for each field
    if (langState.en.length > 250) {
      validationErrors.en = t("Maximum length is 250 characters");
    }
    if (langState.fr.length > 250) {
      validationErrors.fr = t("Maximum length is 250 characters");
    }
    if (langState.nl.length > 250) {
      validationErrors.nl = t("Maximum length is 250 characters");
    }

    setErrors(validationErrors);
    if (
      validationErrors.en === "" &&
      validationErrors.fr === "" &&
      validationErrors.nl === ""
    ) {
      return undefined;
    } else {
      return validationErrors;
    }
  };

  const hasError = (field: keyof FormErrors): boolean => {
    return Array.isArray(errors[field])
      ? errors[field].length > 0
      : !!errors[field];
  };

  const removeError = (event: React.MouseEvent<HTMLInputElement>) => {
    // Handle the click event here
    const fieldName = (event.target as HTMLInputElement).name;
    setErrors((prevState) => ({
      ...prevState,
      [fieldName]: "", // Set the value of the field dynamically
    }));
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const validationErrors = validateForm();
    if (validationErrors === undefined) {
      updateTranslations();
    }
  };

  async function updateTranslations() {
    try {
      setIsSaving(true);
      const postData = {
        method: "POST",
        data: langState,
      };
      const response: any = await APICALL.service(
        createLanguageStringsUrl,
        "POST",
        postData,
        true
      );

      if (response.status === 200) {
        let message: string = "Translation updated successfully";
        localStorage.setItem("successAlertMessage", message);
        setIsSaving(false);
        navigate("/translations/manage");
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchTranslations(editId: number) {
    try {
      const postData = {
        method: "POST",
        data: { translation_id: editId },
      };
      const response: any = await APICALL.service(
        fetchLanguageStringsUrl,
        "POST",
        postData,
        true
      );
      if (response.status === 200) {
        const result = response.data[0];
        setDevString(result.development_string);
        setLangState((prevState) => ({
          ...prevState,
          translation_id: result.translation_id,
          en: result.en,
          fr: result.fr,
          nl: result.nl,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  }

  window.addEventListener("beforeunload", () => {
    const currentURL = new URL(window.location.href);
    // const urlExtension = `/translations/manage/${editId ?? editId}`;
    // localStorage.setItem("backbuttonURL", urlExtension);
  });

  const goToPreviousPage = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    let backButtonURL: any = localStorage.getItem("backbuttonURL");
    localStorage.removeItem("backbuttonURL");
    navigate(backButtonURL ?? "/");
  };

  return (
    editId ?
      <AccessControl
        requiredPermissions={[
          {
            permission: "translations",
            update: true,
          },
        ]}
        renderNoAccess={true}
      >
        <>
          <div className="row">
            <div className="col-md-11 ms-auto px-4">
              <WindowHeightComponent>
                <div className="d-flex flex-column h-100 overflow-auto">
                  <div className="py-xxl-4 py-xl-3 py-lg-3 page-title top-0">
                    {t("Update translation")}
                  </div>

                  <div className="flex-1 overflow-auto">
                    <form onSubmit={handleSubmit} className="d-flex flex-column overflow-auto h-100">

                      <div className="row m-0 flex-1 overflow-auto">
                        <div className="col-12">
                          <div className="create-form p-4 mb-4">
                            <div className="row">
                              <div className="col-10 m-auto">
                                <div className="mb-3 ">
                                  <b className="font20px text-break">
                                    {t("Translation for")}: {devString}
                                  </b>
                                </div>
                                <div className="row">
                                  <div className="col-10">
                                    <label>{t('English')}</label>
                                  </div>
                                  <div className="form-group col-md-12 mb-4">
                                    <input
                                      type="text"
                                      name="en"
                                      id="en"
                                      className="shadow-none form-control"
                                      value={langState.en}
                                      onChange={handleInputChange}
                                    />
                                    {hasError("en") && (
                                      <div
                                        className="error mb-2"
                                        style={{ color: "red" }}
                                      >
                                        {t(errors.en)}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-10">
                                    <label>{('French')}</label>
                                  </div>
                                  <div className="form-group col-md-12 mb-4">
                                    <input
                                      type="text"
                                      name="fr"
                                      id="fr"
                                      className="shadow-none form-control"
                                      value={langState.fr}
                                      onChange={handleInputChange}
                                    />
                                    {hasError("fr") && (
                                      <div
                                        className="error mb-2"
                                        style={{ color: "red" }}
                                      >
                                        {t(errors.fr)}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-10">
                                    <label>{t('Dutch')}</label>
                                  </div>
                                  <div className="form-group col-md-12 mb-4">
                                    <input
                                      type="text"
                                      name="nl"
                                      id="nl"
                                      className="shadow-none form-control"
                                      value={langState.nl}
                                      onChange={handleInputChange}
                                    />
                                    {hasError("nl") && (
                                      <div
                                        className="error mb-2"
                                        style={{ color: "red" }}
                                      >
                                        {t(errors.fr)}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row m-0 mt-2">
                        <div className="col-md-6 float-start">
                          <button
                            className="back-btn shadow-none float-start text-decoration-none"
                            onClick={goToPreviousPage}
                          >
                            {t(CommonConstants.BACK)}
                          </button>
                        </div>
                        <div className="col-md-6 float-end">
                          <button
                            type="submit"
                            className="submit-btn shadow-none float-end"
                          >
                            {t("Update")}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </WindowHeightComponent>
            </div>
          </div>
        </>
      </AccessControl>
      : <PageNotFound />
  );
};

export default UpdateTranslations;
