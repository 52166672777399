import { InputWithLabel } from "../../components/molecules/InputWithLabel";
import { CommonConstants } from "../Shipment/Constants/ShipmentConstants";
import { t } from "../Translations/TranslationUtils";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import FormValidation from "../../services/FormValidation";
import { APICALL } from "../../services/ApiServices";
import {
  fetchProductCatalogue,
  importProductCatalogue,
  updateCatalogue,
} from "../../routes/ApiEndPoints";
import customAlert from "../../components/atoms/CustomAlert";
import WindowHeightComponent from "../../commonComponents/WindowHeightComponent";
import CommonServices from "../../services/CommonServices";

type ProductState = {
  brand_title: string;
  product_description: string;
  product_type: string;
  product_group: string;
  gender: string;
  country: string;
  currency: string;
  msrp: string;
  renewal_fee: string;
  resale_price: string;
  resale_price_2: string;
  id: any;
  brand_partner_id: any;
};

const initialProductState: ProductState = {
  brand_title: "",
  product_description: "",
  product_type: "",
  product_group: "",
  gender: "",
  country: "",
  currency: "",
  msrp: "",
  renewal_fee: "",
  resale_price: "",
  resale_price_2: "",
  id: null,
  brand_partner_id: null,
};

interface FormErrors {
  product_description: string;
  product_type: string;
  product_group: string;
  gender: string;
  country: string;
  currency: string;
  msrp: string;
  renewal_fee: string;
  resale_price: string;
  resale_price_2: string;
}

const EditProductCatalogue = () => {
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const editId = searchParams.get("edit");
  const [refresh, setRefresh] = useState(false);
  const [productState, setProductState] =
    useState<ProductState>(initialProductState);
  const [errors, setErrors] = useState<FormErrors>({
    product_description: "",
    product_type: "",
    product_group: "",
    gender: "",
    country: "",
    currency: "",
    msrp: "",
    renewal_fee: "",
    resale_price: "",
    resale_price_2: "",
  });
  const [productCatalogueFieldNames] = useState<FormErrors>({
    product_description: "Product description",
    product_type: "Product type",
    product_group: "Product group",
    gender: "Gender",
    country: "Country",
    currency: "Currency",
    msrp: "MSRP",
    renewal_fee: "Renewal fee",
    resale_price: "Resale price",
    resale_price_2: "Resale price (Tier 2)",
  });

  useEffect(() => {
    getProductCatalogue();
    setProductState((prev) => ({
      ...prev,
      id: editId,
    }));
  }, [editId]);

  useEffect(() => {
    const message: string = localStorage?.getItem("errorAlertMessage") ?? "";
    localStorage.removeItem("errorAlertMessage");
    if (message != "") {
      customAlert("error", t(message), 2000);
    }
  }, [refresh]);

  async function getProductCatalogue() {
    try {
      let postdata = {
        method: "POST",
        data: {
          id: editId,
        },
      };
      const response = await APICALL.service(
        fetchProductCatalogue,
        "POST",
        postdata,
        true
      );
      if (response.status == 200 && response.data) {
        const responseData = response.data.data[0];
        setProductState((prev) => ({
          ...prev,
          brand_title: responseData.brand_partner?.title,
          product_description: responseData.product_description,
          product_group: responseData.product_group,
          product_type: responseData.product_type,
          gender: responseData.gender,
          country: responseData.country,
          currency: responseData.currency,
          msrp: responseData.msrp,
          renewal_fee: responseData.renewal_fee,
          resale_price: responseData.resale_price,
          resale_price_2: responseData.resale_price_2,
          brand_partner_id: responseData.brand_partner_id,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setProductState((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const removeError = (event: React.FocusEvent<HTMLInputElement>) => {
    let fieldName = (event.target as HTMLInputElement).name;
    setErrors((prevState) => ({
      ...prevState,
      [fieldName]: "",
    }));
  };

  const validateForm = () => {
    const newErrors: any = { ...errors };
    let error = "";
    const columnlengthcheck = {
      names: ['product_group', 'product_description'],
      lengths: [10, 80]
    }
    Object.keys(productState).map((item: string) => {
      const key = item as keyof ProductState;
      if (columnlengthcheck.names?.includes(key)) {
        error = FormValidation.nameValidation(productState[key], Number(columnlengthcheck?.lengths[columnlengthcheck.names.indexOf(key)]));
        newErrors[key] = error;
      } else if (key != "id" && key != "brand_partner_id" && key != "brand_title") {
        error = FormValidation.nameValidation(productState[key]);
        newErrors[key] = error;
      }
    });

    setErrors(newErrors);
    return Object.values(newErrors).every((item: any) => item == "");
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const valid = validateForm();
    if (valid) {
      UpdateData();
    }
  };

  const UpdateData = async () => {
    const postData = {
      method: "POST",
      data: productState,
    };
    const response = await APICALL.service(
      updateCatalogue,
      "POST",
      postData,
      true
    );
    if (response?.status == 200) {
      localStorage.setItem("successAlertMessage", response?.message);
      navigate("/product-catalogue/manage");
    } else {
      localStorage.setItem("errorAlertMessage", response?.message);
    }
    setRefresh(!refresh);
  };

  return (
    <div className="row">
      <div className="col-md-11 ms-auto px-4 ">
        <WindowHeightComponent>
          <div className="d-flex flex-column h-100 overflow-auto">
            <div className="py-xxl-4 py-xl-3 py-lg-3 page-title top-0">
              {t("Update product catalogue: ")}
              {productState.brand_title}
            </div>

            <form onSubmit={handleSubmit} className="flex-1 d-flex flex-column h-100 overflow-auto">
              <div className="flex-1 overflow-auto pt-3">
                <div className="d-flex justify-content-between m-0 row">
                  {Object.keys(productCatalogueFieldNames).map(
                    (key: string, index: number) => {
                      const productkey = key as keyof FormErrors;
                      const columnClass = index % 2 === 0 ? "col-md-6" : "col-md-6";

                      return (
                        <div className={columnClass} key={key}>
                          <InputWithLabel
                            label={`${t(productCatalogueFieldNames[productkey])} :`}
                            type="text"
                            name={productkey}
                            value={CommonServices.stringFormat(productState[productkey])}
                            className={`shadow-none form-control ${errors[productkey] !== "" ? ' border-danger' : "dd"}`}
                            handleChange={handleInputChange}
                            handleClick={removeError}
                            mandatory
                            error={
                              errors[productkey] !== "" ? t(errors[productkey]) : ""
                            }
                          />
                        </div>
                      );
                    }
                  )}
                </div>
              </div>

              <div className="row m-0 mt-1">
                <div className="col-md-6 float-start">
                  <button
                    className="back-btn shadow-none float-start text-decoration-none"
                    onClick={() => navigate("/product-catalogue/manage")}
                  >
                    {t(CommonConstants.BACK)}
                  </button>
                </div>
                <div className="col-md-6 float-end">
                  <button
                    type="submit"
                    className="submit-btn shadow-none float-end"
                  >
                    {t("Update")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </WindowHeightComponent>
      </div>
    </div>
  );
};

export default EditProductCatalogue;
