export const ConfigDataHelpTexts: Record<string, string> = {
  category: '',
  sort_category: '',
  product_group: 'The maximum length allowed for the title field is 10 characters.',
  environment: '',
  issue_size: '',
  // issue_location: '',
  repair_assessment: '',
  finishing_steps: '',
  fibre_content: '',
  stain_treatment: '',
  product_type: 'Please upload front and back product images with dimensions ranging from 400 x 400 to 512 x 512, making sure that each file size does not exceed 2 MB.',
  currency: 'Currency standard money used within a country or region.',
  dimension:
    'Dimension measurement of garment defined by brand, typically describing length, for example - 32” or short.',
  external_vendor:
    'External Vendor third party company providing services to Bleckmann, for example, dry cleaner.',
  gender: 'Gender intended gender of garments as defined by brand. The  WMS id field should consist of a single character, for example, "M" or "F".',
  issue_type:
    'Issue Type specific problem with garment or textile, for example, snag or hole.',
  warehouse_location: 'Location warehouse site location. The maximum length allowed for the WMS id field is 10 characters',
  merch_rack:
    'Merchandising Rack physical racks (typically numbered) that are used to sort garments for publishing based on attributes like quality tier or destination.',
  product_tier:
    'Product Tier final assessment of garment quality after renewal. The maximum length allowed for the WMS id field is 10 characters',
  renewal_clean_process:
    'Cleaning Process how a garment is cleaned, for example, wet wash, ozone, or dry clean.',
  repair_guideline:
    'Repair Guidelines standards by which various issues are repaired.',
  size: 'Size general garment measurements as defined by brands, for example, small, 4, or 36. The maximum length allowed for the title field is 10 characters',
  washing_station:
    'Washing Station physical washing equipment, for example, industrial wet wash 1, industrial wet wash 2, or ozone cabinet.',
  work_area:
    'Work Area specific locations on a garment or textile, for example, left shoulder, or back hem.',
  zone: 'Zone general location on a garment or textile as defined by renewal workshop tiering guidelines.'
}
