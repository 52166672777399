import React from 'react'

const SettingsSidebar: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="29.1" height="28.819" viewBox="0 0 29.1 28.819">
  <g id="_10878751351594721599" data-name="10878751351594721599" transform="translate(-3 -2)">
    <ellipse id="Ellipse_209" data-name="Ellipse 209" cx="10" cy="5" rx="10" ry="5" transform="translate(4 3)" fill="none" stroke="#989b9f" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2"/>
    <g id="Group_48167" data-name="Group 48167">
      <ellipse id="Ellipse_210" data-name="Ellipse 210" cx="11" cy="6" rx="11" ry="6" transform="translate(3 2)" fill="#545960"/>
      <path id="Path_56106" data-name="Path 56106" d="M14,24c-4.8,0-8.8-1.4-11-3.6V24c0,3.4,4.8,6,11,6a24.519,24.519,0,0,0,2.7-.2A10.12,10.12,0,0,1,14,24Z" fill="#545960"/>
      <path id="Path_56107" data-name="Path 56107" d="M3,12.4V16c0,3.4,4.8,6,11,6h.1a10.13,10.13,0,0,1,3-6.2A30.284,30.284,0,0,1,14,16C9.2,16,5.2,14.6,3,12.4Z" fill="#545960"/>
    </g>
    <path id="Path_56108" data-name="Path 56108" d="M31.7,20.9a1.028,1.028,0,0,0-1.2-.7c-.7.2-1.2,0-1.3-.2s0-.7.5-1.3a.967.967,0,0,0,0-1.4,8.178,8.178,0,0,0-3.6-2.1,1.028,1.028,0,0,0-1.2.7c-.2.7-.6,1-.9,1s-.6-.4-.9-1a1.086,1.086,0,0,0-1.2-.7,8.178,8.178,0,0,0-3.6,2.1.967.967,0,0,0,0,1.4,1.362,1.362,0,0,1,.5,1.3c-.1.2-.6.4-1.3.2a1.028,1.028,0,0,0-1.2.7,7.5,7.5,0,0,0,0,4.2,1.028,1.028,0,0,0,1.2.7c.7-.2,1.2,0,1.3.2s0,.7-.5,1.3a.967.967,0,0,0,0,1.4,8.178,8.178,0,0,0,3.6,2.1,1.028,1.028,0,0,0,1.2-.7c.2-.7.6-1,.9-1s.6.4.9,1a.993.993,0,0,0,1,.7h.3a8.178,8.178,0,0,0,3.6-2.1.967.967,0,0,0,0-1.4,1.362,1.362,0,0,1-.5-1.3c.1-.2.6-.4,1.3-.2a1.028,1.028,0,0,0,1.2-.7,7.612,7.612,0,0,0,.3-2.1A7.789,7.789,0,0,0,31.7,20.9ZM24,26a3,3,0,1,1,3-3A2.946,2.946,0,0,1,24,26Z" fill="#545960"/>
  </g>
</svg>

  )
}

export default SettingsSidebar