import React, { useEffect, useState } from 'react'
import Layout from '../../pages/Shipment/Layout'
import { APICALL } from '../../services/ApiServices'
import { getDataEntry } from '../../routes/ApiEndPoints'
import TableStructure from '../atoms/TableStructure'
import { useNavigate } from 'react-router-dom'
import { t } from '../../pages/Translations/TranslationUtils'
import Popup from '../../utils/popup'
import { ScannerMolecule } from '../molecules/ScannerMolecule'

const ManageMerchandisingOrganism = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const shipmentDetails = JSON.parse(localStorage.getItem('shipment_details') ?? '{}');
    const bpDetails = JSON.parse(localStorage.getItem('bp_details') ?? '{}');
    const [popup, setPopup] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const postData = {
                shipment_id: shipmentDetails.shipment_id,
                brand_partner_id: bpDetails.brand_partner_id,
                stage_name: "merchandising"
            };
            const response = await APICALL.service(getDataEntry, 'POST', postData);
            if (response.status === 200) {
                setData(response.data[0]);
                if (response.data[0].length > 0) {
                    setPopup(true)
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleEdit = async (value: any) => {
        navigate(`/merchandising/create?edit=${value.renewal_id}`)
    }

    const handleScan = async (value: any) => {
        const scan_value: any = Object.values(data).find((item: any) => item.serial_number == value.trim())
        if (scan_value) {
            navigate(`/merchandising/create?edit=${scan_value.renewal_id}`)
        } else {
            setError(true);
        }
    }

    const filteredData = data.filter((value: any) => value.status == "active");

    const TABLE_HEADERS = [
        { name: t('Serial number'), width: 50 },
    ]
    const TABLE_VALUES = [
        'serial_number',
    ]
    return (
        <Layout pagename={t('Merchandising')} logo={true} timer={false}>
            <div className="flex-1 overflow-auto">
                <TableStructure
                    isAction
                    data={filteredData}
                    headers={TABLE_HEADERS}
                    values={TABLE_VALUES}
                    handleEdit={handleEdit}
                />
            </div>
            {popup && <Popup modalSize="xl" title={t("Scan renewal record")} cancel={() => setPopup(false)} body={<ScannerMolecule handleClick={handleScan} error={error} />} />}
        </Layout >
    )
}

export default ManageMerchandisingOrganism;
