import React, { ChangeEvent, useEffect, useState } from 'react'
import Layout from '../Layout'
import styles from "./dataentry.module.css"
import InputTextfield from '../../../components/atoms/InputTextField'
import { CommonConstants, DataEntryConstants } from '../Constants/ShipmentConstants'
import MultiSelect from '../../../components/atoms/MultiSelectField'
import EncryptDecryptService from '../../../services/EncryptDecryptService'
import { APICALL } from '../../../services/ApiServices'
import {
    createDataEntry,
    fetchMediaLibraries,
    getDataEntry,
    getDataEntryFields,
    getProductsWithSku,
    getShipments,
    storeProducts
} from '../../../routes/ApiEndPoints'
import FormValidation from '../../../services/FormValidation'
import { useNavigate } from 'react-router-dom'
import customAlert from '../../../components/atoms/CustomAlert'
import Button from '../../../components/atoms/Button'
import ErrorMessage from '../../../components/atoms/ErrorMessage'
import Popup from '../../../utils/popup'
import MediaLibrary from '../../MediaLibrary/MediaLibrary'
import { Upload } from '../../../static/icons/Upload'
import GenerateBarcodeMolecule from '../../../components/molecules/GenerateBarcodeMolecule'
import CommonShipmentServices from '../../../services/CommonShipmentServices'
import LabelField from '../../../components/atoms/LabelField'
import { t } from '../../Translations/TranslationUtils'
import { WorkflowStageNames } from '../../../utils/constants/WorkflowStageNames'
import ImageCarousel from '../../../components/atoms/ImageCarousel'
import ScreenDisable from '../../../utils/ScreenDisable'
import CommonServices from '../../../services/CommonServices'
import { useUserContext } from '../../../routes/Contextlib'

interface Data {
    skudata?: any,
    selectdata?: any,
    shipment?: any
}
const DataEntryOverview = () => {
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);
    const { user } = useUserContext();
    const id = urlParams.get('id');
    const edit = urlParams.get('edit');
    const sku = urlParams.get('sku');
    const [varState, setVarState] = useState({
        popup: false,
        barcode: false,
        barcodeurl: '',
        barcode_id: "",
        totaltime: 0,
        timerrunning: true,
        nextflowcount: true,
        viewBarcode: false
    })
    const [data, setData] = useState<Data>();
    const localdata = CommonShipmentServices.getPostData();
    const bpdetails = JSON.parse(localStorage.getItem('bp_details') ?? '{}');
    const [state, setState] = useState({
        brand_partner_id: localdata?.brand_partner_id,
        gender: null,
        color: null,
        color_label: "",
        size_label: "",
        dimension: null,
        product_type: null,
        style: '',
        warehouse_location: bpdetails.warehouse_location,
        size: null,
        clean_process: null,
        weight: '',
        product_group: null,
        fibre_content: null,
        shipment_id: localdata?.shipment_id ?? null,
        image_id: [],
        image_url: []
    });
    const [errors, setErrors] = useState({
        gender: '',
        color: '',
        dimension: '',
        product_type: '',
        style: '',
        warehouse_location: '',
        size: '',
        clean_process: '',
        weight: '',
        product_group: '',
        fibre_content: '',
        shipment_id: ''
    });

    useEffect(() => {
        if (edit) {
            getDataEntryDetails();
        } else if (id) {
            getSkuDetails();
        } else {
            fetchFieldOptions();
        }
    }, [edit, id]);

    const timeTracker = async () => {
        try {
            let time = await CommonShipmentServices.saveTimerApi(
                'bp',
                localdata?.brand_partner_id,
                user?.user_id,
                WorkflowStageNames.DATAENTRY
            );
            // setVarState(prevState => ({
            //     ...prevState,
            //     totaltime: time
            // }));
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (user != null) {
            timeTracker();
            return () => {
                CommonShipmentServices.saveTimerApi(
                    'bp',
                    localdata?.brand_partner_id,
                    user?.user_id,
                    WorkflowStageNames.DATAENTRY,
                    false
                );
            };
        }
    }, [user != null]);

    const fetchFieldOptions = async () => {
        try {
            const postData = {
                // Encrypting the request data
                method: "GET",
                data: EncryptDecryptService.encryptData(JSON.stringify({})),
            };
            const response = await APICALL.service(getDataEntryFields + '/' + localdata?.brand_partner_id, 'POST', postData)
            let decrypteddata = JSON.parse(EncryptDecryptService.decryptData(response ?? ''));

            if (decrypteddata?.status === 200) {
                setData({ ...data, selectdata: decrypteddata.data });
                setState((prevState: any) => ({ ...prevState, style: sku }));
                await fetchShipments();
            }
        } catch (error) {
            console.log(error);
        }
    }

    const fetchShipments = async () => {
        try {
            const postData: any = {
                ...localdata,
                onlyshipments: true,
                status: ['in progress', 'ready']
            };
            const res = await APICALL.service(getShipments, "POST", postData);

            if (res?.status === 200) {
                let shipment = res?.data?.map((shipmentItem: any) => ({
                    value: shipmentItem?.shipment_id,
                    label: shipmentItem?.title,
                    name: 'shipment_id'
                }));

                setData((prevData) => ({
                    ...prevData,
                    shipment: CommonServices.sortAlphabattically(shipment, 'label')
                }));
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getDataEntryDetails = async () => {
        try {
            const postData = {
                id: edit,
                ...localdata
            };
            const response = await APICALL.service(getDataEntry, 'POST', postData);

            if (response?.status === 200) {
                let shipment = response?.data[2]?.map((shipmentItem: any) => ({
                    value: shipmentItem?.shipment_id,
                    label: shipmentItem?.title,
                    name: 'shipment_id'
                }));
                setData({
                    ...data,
                    skudata: response?.data[0],
                    selectdata: response?.data[1],
                    shipment: CommonServices.sortAlphabattically(shipment, 'label')
                });
            }
            setState((prevState) => ({
                ...prevState,
                sku: response?.data[0]?.sku,
                product_description: response?.data[0]?.product_description,
                product_catalogue_id: response?.data[0]?.product_catalogue_id,
                clean_process: response?.data[0]?.clean_process,
                gender: response?.data[0]?.gender,
                color: response?.data[0]?.color,
                color_label: response?.data[0]?.colorlabel,
                dimension: response?.data[0]?.dimension,
                product_type: response?.data[0]?.product_type,
                product_group: response?.data[0]?.product_group != 0 ? response?.data[0]?.product_group : null,
                style: response?.data[0]?.style,
                warehouse_location: response?.data[0]?.warehouse_location,
                size: response?.data[0]?.size,
                size_label: response?.data[0]?.sizelabel,
                fibre_content: response?.data[0]?.fibre_content,
                weight: response?.data[0]?.weight,
                renewal_id: edit,
                shipment_id: Number(response?.data[0]?.shipment_id)
            }));

            if (response.data[0].image_id.length) {
                await getMediaLibraries(response?.data[0].image_id);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getMediaLibraries = async (id: any) => {
        try {
            const postData = {
                method: "POST",
                data: {
                    medialibrary_id: id,
                },
            };
            const response = await APICALL.service(
                fetchMediaLibraries,
                "POST",
                postData,
                true
            );

            if (response.status === 200) {
                let files: any = Object.values(response?.data).filter(
                    (item: any) => id.includes(item?.medialibrary_id)
                );
                const mediaUrls = files?.map((item: any) => item.url);
                const mediaIds = files?.map((item: any) => item.medialibrary_id);
                setState((prevState) => ({ ...prevState, image_id: mediaIds, image_url: mediaUrls }));
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getSkuDetails = async () => {
        try {
            const postData = {
                // Encrypting the request data
                method: "POST",
                data: EncryptDecryptService.encryptData(JSON.stringify({
                    id: id,
                    ...localdata
                })),
            };
            const response = await APICALL.service(getProductsWithSku, 'POST', postData);
            let decrypted = JSON.parse(EncryptDecryptService.decryptData(response ?? ''));

            if (decrypted?.status === 200) {
                setData({ ...data, skudata: decrypted?.data[0], selectdata: decrypted?.data[1] });
                setState((prevState) => ({
                    ...prevState,
                    product_catalogue_id: decrypted?.data[0]?.product_catalogue_id,
                    gender: decrypted?.data[0]?.gender_id !== null ?
                        decrypted?.data[0]?.gender_id :
                        prevState.gender,
                    gender_label: decrypted?.data[0]?.gender,
                    product_group: decrypted?.data[0]?.product_group_id !== null ?
                        decrypted?.data[0]?.product_group_id :
                        prevState.product_group,
                    product_group_label: decrypted?.data[0]?.product_group,
                    style: decrypted?.data[0]?.sku,
                    product_type: decrypted?.data[0]?.product_type_id !== null ?
                        decrypted?.data[0]?.product_type_id :
                        prevState.product_type,
                    product_type_label: decrypted?.data[0]?.product_type,
                    color: decrypted?.data[0]?.color_id,
                    color_label: decrypted?.data[0]?.color_label,
                    size: decrypted?.data[0]?.size_id,
                    size_label: decrypted?.data[0]?.size
                }));
                await fetchShipments();
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (varState.timerrunning) {
            const { name, value } = e.target ?? e;
            setState((prevFormData) => ({ ...prevFormData, [name]: value }));
        }
    }

    const handleInputChangeWithLabel = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, label: string) => {
        if (varState.timerrunning) {
            const { name, value } = e.target ?? e;
            setState((prevFormData) => ({ ...prevFormData, [name]: value, [`${name}_label`]: label }));
        }
    }

    const validate = (formData: any) => {
        const ignoreValidation = [
            'product_catalogue_id',
            'product_description',
            'sku',
            'weight',
            'warehouse_location',
            'dimension',
            'gender',
            'gender_label',
            'clean_process',
            'user_id',
            'image_id',
            'image_url',
            'fibre_content',
        ];

        try {
            const error: any = [];
            let sku = (formData.style + '-' + formData.color_label + '.' + formData.size_label) ?? "";
            Object.entries(formData).forEach(([key, value]) => {
                if (key == 'style') {
                    error[key] = FormValidation.nameValidation(value, 15);
                } else if (ignoreValidation.includes(key)) {
                    error[key] = '';
                } else {
                    error[key] = FormValidation.selectValidation(value);
                }
                setErrors((prevFormData) => ({ ...prevFormData, [key]: error[key] }));
            });
            if (sku?.length > 50) {
                customAlert('error', t("The SKU being generated is longer than 50 characters"), 6000);
                return false;
            }
            if (Object.keys(error).length !== 0) {
                return Object.values(error).every((errorMessage) => errorMessage === "");
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    const saveDataEntry = async (isBarCode: boolean) => {
        try {
            let postdata = {
                ...localdata,
                user_id: user?.user_id,
                ...state
            }
            const response = await APICALL.service(createDataEntry, 'POST', postdata);

            if (response?.status === 200) {
                if (sku || id) {
                    const postData = {
                        method: "POST",
                        data: state
                    };
                    const styleResponse = await APICALL.service(storeProducts, 'POST', postData, true);
                    if (styleResponse?.status === 200) {
                        isBarCode
                            ? setVarState({ ...varState, viewBarcode: true, barcode: true, barcodeurl:response?.data[0], barcode_id: response?.data[1], nextflowcount: response?.res?.newPresentFlowCount > 0})
                            : navigate(localStorage?.getItem('shipment_dashboard_url') ?? "/shipment-dashboard")
                    }
                } else {
                    isBarCode
                        ? setVarState({ ...varState, viewBarcode: true, barcode: true, barcodeurl:response?.data[0], barcode_id: response?.data[1], nextflowcount: response?.res?.newPresentFlowCount > 0})
                        : navigate(localStorage?.getItem('shipment_dashboard_url') ?? "/shipment-dashboard")
                }
            } else {
                customAlert(
                    "error",
                    t(response.message),
                    6000
                )
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleSubmit = (event: React.FormEvent, isBarCode: boolean) => {
        event.preventDefault();
        let isValidated = validate(state);
        isValidated && saveDataEntry(isBarCode);
    };

    const getImagesFromPopup = (modalimages: any) => {
        const mediaUrls = modalimages?.map((item: any) => item.media_url);
        const mediaIds = modalimages?.map((item: any) => item.media_id);
        setState({ ...state, image_id: mediaIds, image_url: mediaUrls });
        setVarState({ ...varState, popup: false });
    }

    const setTimerRunning = (timer: boolean) => {
        setVarState((prevState: any) => ({ ...prevState, timerrunning: timer }));
    }

    const createAnotherDataentry = () => {
        const currentUrl = window.location.pathname;
        if (currentUrl === "/data-entry/create") {
            // setState((item) => ({ ...item,
            //     gender: null, color: null, dimension: null,product_type: null, style: '', size: null,
            //     clean_process: null, weight: '', product_group: null,fibre_content: null, image_id: [], image_url: [] 
            // }));
            // setErrors({
            //     gender: '',color: '', dimension: '', product_type: '', style: '',warehouse_location: '',
            //     size: '', clean_process: '', weight: '', product_group: '', fibre_content: '', shipment_id: ''
            // });
            // setVarState((item) => ({ ...item,
            //     popup: false, barcode: false, barcodeurl: '', totaltime: 0, timerrunning: true
            // }));
            // setRefresh(!refresh);
            window.location.reload();
        } else {
            // Navigate to "/data-entry/create" if the current URL is different
            navigate("/data-entry/create");
        }
    };
    const handlePrint = async (data: any) => {
        try {
            const postdata = {
                "printerName": data?.inputValue.trim(),
                "labelType": "SerialNumber",
                "labelData": {
                    "serialNumber": varState?.barcode_id
                }
            }
            let error: any = validatepostdata(postdata);
            if (!error) {
                let res: any = await CommonServices.printLabelApi(postdata);
                res?.message && customAlert(res?.type, t(res?.message), 6000);
                if (res.statusCode === 201) {
                    setVarState((prev) => ({ ...prev, viewBarcode: false }));
                }
            } else {
                let errortext = Object.keys(error).filter(key => error[key] !== "").map(key => `<div>${t(error[key])}</div>`).join("");
                errortext && customAlert('error', errortext, 6000);
            }
        } catch (error) {
            console.log(error);
        }
    }
    const validatepostdata = (data: any) => {
        let error = {
            "printerName": FormValidation.nameValidation(data?.printerName, 255, 'Printer name'),
            "serialNumber": FormValidation.nameValidation(data?.labelData?.serialNumber, 8, 'Serial number'),
        }
        return Object.values(error)?.some(item => item !== "") ? error : false;
    }
    return (
        <Layout
            pagename={('Data entry')}
            logo={true}
            timer={true}
            stage={WorkflowStageNames.DATAENTRY}
            ongoing={!varState.barcode}
            totaltime={varState.totaltime}
            setTimerRunning={setTimerRunning}
        >
            {varState.popup && <Popup
                body={
                    <MediaLibrary
                        modal={true}
                        handleImagePopupSubmit={getImagesFromPopup}
                        closePopup={() => setVarState({ ...varState, popup: false })}
                        selectedImages={state.image_id?.map((media_id, index) => ({
                            media_id: media_id,
                            media_url: state.image_url[index]
                        })) ?? []}
                    />
                }
                cancel={() => setVarState({ ...varState, popup: false })}
                modalSize='xl'
                modalclass="upload_media_lib_popup"
                title={t('Media library')}
            />}
            {varState.barcode ?
                <>
                    <div className="create-form p-4 flex-1 overflow-auto">
                        <GenerateBarcodeMolecule
                            popup={varState}
                            setPopup={setVarState}
                            handlePrint={handlePrint}
                            barcodeUrl={varState?.barcodeurl}
                            title={t('dataentry')}
                        />
                    </div>
                    <div className="d-flex justify-content-between mt-2">
                        <Button
                            title={t(CommonConstants.BACK)}
                            className={'back-btn shadow-none text-decoration-none'}
                            handleClick={() => navigate(localStorage?.getItem('shipment_dashboard_url') ?? "/shipment-dashboard")}
                        />
                        {varState.nextflowcount && <div>
                            <Button
                                title={t(DataEntryConstants.SAVE_AND_NEXT)}
                                className={'submit-btn shadow-none'}
                                handleClick={() => createAnotherDataentry()}
                            />
                        </div>}
                    </div>

                </> :
                <>
                    <div className="flex-1 overflow-auto">
                        <div className="d-flex overlow-auto position-relative ">
                            {/* left box starts */}
                            <ScreenDisable display='none' />
                            <div className="rounded border shadow equal_block w-25 me-4 ">
                                <div className="py-5 px-xxl-5 px-2 text-center d-flex flex-column h-100">
                                    <div className={`mb-5 ${styles.data_entry_left_block} position-relative data-entry-image-swiper flex-1`}>
                                        <ImageCarousel images={state.image_url} />
                                    </div>
                                    <div className={`text-gray my-2 my-xxl-4 text-break`}>
                                        {data?.skudata?.product_description}
                                    </div>
                                    <span className={`${styles.add_btn} width_maxcontent m-auto`}
                                        onClick={() => setVarState({ ...varState, popup: varState.timerrunning })}
                                    >
                                        <Upload />
                                    </span>
                                </div>
                            </div>
                            {/* left box ends */}
                            {/* right block starts */}
                            <div className="border rounded shadow p-4 p-xxl-5 pb-0 equal_block w-75 ">
                                <div className={`${styles.sku_title}`}>
                                    {!id && !sku && data?.skudata?.sku && <div className='font_Brandon_regular text-truncate'>{t("SKU number") + ': ' + data?.skudata?.sku}</div>}
                                </div>
                                <div className="mt-4">
                                    <div className="row">
                                        <div className={`col-6 ${styles.form_content}`}>
                                            <div className="d-flex justify-content-between  mb-4">
                                                <LabelField
                                                    title={t('Style number') + ':'}
                                                    className={`${styles.overview_label}`}
                                                    mandatory
                                                />
                                                <div className="w-50">
                                                    <InputTextfield
                                                        className="border rounded text-gray w-100 px-3 form-control"
                                                        name={'style'}
                                                        value={state.style}
                                                        handleChange={
                                                            (e) => e !== null ?
                                                                handleInputChange(e) :
                                                                setState((prevFormData) => ({ ...prevFormData, style: '' }))
                                                        }
                                                        handleClick={() => setErrors((prevFormData) => ({ ...prevFormData, style: '' }))}
                                                        readOnly={varState.timerrunning ? (id !== null || edit !== null) : true}
                                                    />
                                                    <ErrorMessage
                                                        errorMessage={t(errors.style)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`col-6 ${styles.form_content}`}>
                                            <div className="d-flex justify-content-between  mb-4">
                                                <LabelField
                                                    title={t('Brand partner') + ':'}
                                                    className={`${styles.overview_label}`}
                                                />
                                                <div className="w-50">
                                                    <InputTextfield
                                                        className="border rounded text-gray w-100 px-3 form-control"
                                                        name={'brand_partner'}
                                                        value={localdata?.bpname}
                                                        handleChange={() => { }}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`col-6 ${styles.form_content}`}>
                                            <div className="d-flex justify-content-between  mb-4">
                                                <LabelField
                                                    title={t('Color') + ':'}
                                                    className={`${styles.overview_label}`}
                                                    mandatory
                                                />
                                                <div className="w-50">
                                                    <MultiSelect
                                                        name={'color'}
                                                        isClearable
                                                        isSearchable
                                                        value={state.color}
                                                        standards={data?.selectdata?.color.find((option: any) => option.value === state.color)}
                                                        options={data?.selectdata?.color}
                                                        handleChange={
                                                            (e) => e !== null ?
                                                                handleInputChangeWithLabel(e, e.label) :
                                                                setState((prevFormData) => ({ ...prevFormData, color: null, color_label: '' }))
                                                        }
                                                        handleClick={() => setErrors((prevFormData) => ({ ...prevFormData, color: '' }))}
                                                        disabled={!varState.timerrunning}
                                                    />
                                                    <ErrorMessage
                                                        errorMessage={t(errors.color)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`col-6 ${styles.form_content}`}>
                                            <div className="d-flex justify-content-between  mb-4">
                                                <LabelField
                                                    title={t('Weight') + ':'}
                                                    className={`${styles.overview_label}`}
                                                />
                                                <div className="w-50">
                                                    <InputTextfield
                                                        className="border rounded text-gray w-100 px-3 form-control"
                                                        name={'weight'}
                                                        value={state.weight}
                                                        handleChange={
                                                            (e) => e !== null ?
                                                                handleInputChange(e) :
                                                                setState((prevFormData) => ({ ...prevFormData, weight: '' }))
                                                        }
                                                        handleClick={() => setErrors((prevFormData) => ({ ...prevFormData, weight: '' }))}
                                                        readOnly={!varState.timerrunning}
                                                    />
                                                    <ErrorMessage
                                                        errorMessage={t(errors.weight)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`col-6 ${styles.form_content}`}>
                                            <div className="d-flex justify-content-between  mb-4">
                                                <LabelField
                                                    title={t('Size') + ':'}
                                                    className={`${styles.overview_label}`}
                                                    mandatory
                                                />
                                                <div className="w-50">
                                                    <MultiSelect
                                                        name={'size'}
                                                        isClearable
                                                        isSearchable
                                                        standards={data?.selectdata?.size.find((option: any) => option.value === state.size)}
                                                        value={state.size}
                                                        options={data?.selectdata?.size}
                                                        handleChange={
                                                            (e) => e !== null ?
                                                                handleInputChangeWithLabel(e, e.label) :
                                                                setState((prevFormData) => ({ ...prevFormData, size: null, size_label: '' }))
                                                        }
                                                        handleClick={() => setErrors((prevFormData) => ({ ...prevFormData, size: '' }))}
                                                        disabled={!varState.timerrunning}
                                                    />
                                                    <ErrorMessage
                                                        errorMessage={t(errors.size)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`col-6 ${styles.form_content}`}>
                                            <div className="d-flex justify-content-between  mb-4">
                                                <LabelField
                                                    title={t('Clean process') + ':'}
                                                    className={`${styles.overview_label}`}
                                                />
                                                <div className="w-50">
                                                    <MultiSelect
                                                        name={'clean_process'}
                                                        isClearable
                                                        isSearchable
                                                        standards={data?.selectdata?.clean_process.find((option: any) => option.value === state.clean_process)}
                                                        options={data?.selectdata?.clean_process}
                                                        value={state.clean_process}
                                                        handleChange={
                                                            (e) => e !== null ?
                                                                handleInputChange(e) :
                                                                setState((prevFormData) => ({ ...prevFormData, clean_process: null }))
                                                        }
                                                        handleClick={() => setErrors((prevFormData) => ({ ...prevFormData, clean_process: '' }))}
                                                        disabled={!varState.timerrunning}
                                                    />
                                                    <ErrorMessage
                                                        errorMessage={t(errors.clean_process)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`col-6 ${styles.form_content}`}>
                                            <div className="d-flex justify-content-between  mb-4">
                                                <LabelField
                                                    title={t('Gender') + ':'}
                                                    className={`${styles.overview_label}`}
                                                />
                                                <div className="w-50">
                                                    <MultiSelect
                                                        name={'gender'}
                                                        isClearable
                                                        isSearchable
                                                        standards={data?.selectdata?.gender.find((option: any) => option.value === state.gender)}
                                                        options={data?.selectdata?.gender}
                                                        value={state.gender}
                                                        handleChange={
                                                            (e) => e !== null ?
                                                                handleInputChangeWithLabel(e, e.label) :
                                                                setState((prevFormData) => ({ ...prevFormData, gender: null, gender_label: '' }))
                                                        }
                                                        handleClick={() => setErrors((prevFormData) => ({ ...prevFormData, gender: '' }))}
                                                        disabled={!varState.timerrunning}
                                                    />
                                                    <ErrorMessage
                                                        errorMessage={t(errors.gender)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`col-6 ${styles.form_content}`}>
                                            <div className="d-flex justify-content-between  mb-4">
                                                <LabelField
                                                    title={t('Dimension') + ':'}
                                                    className={`${styles.overview_label}`}
                                                />
                                                <div className="w-50">
                                                    <MultiSelect
                                                        name={'dimension'}
                                                        isClearable
                                                        isSearchable
                                                        standards={data?.selectdata?.dimension.find((option: any) => option.value === state.dimension)}
                                                        options={data?.selectdata?.dimension}
                                                        value={state.dimension}
                                                        handleChange={
                                                            (e) => e !== null ?
                                                                handleInputChange(e) :
                                                                setState((prevFormData) => ({ ...prevFormData, dimension: null }))
                                                        }
                                                        handleClick={() => setErrors((prevFormData) => ({ ...prevFormData, dimension: '' }))}
                                                        disabled={!varState.timerrunning}
                                                    />
                                                    <ErrorMessage
                                                        errorMessage={t(errors.dimension)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`col-6 ${styles.form_content}`}>
                                            <div className="d-flex justify-content-between  mb-4">
                                                <LabelField
                                                    title={t('Product group') + ':'}
                                                    className={`${styles.overview_label}`}
                                                    mandatory
                                                />
                                                <div className="w-50">
                                                    <MultiSelect
                                                        name={'product_group'}
                                                        isClearable
                                                        isSearchable
                                                        standards={data?.selectdata?.product_group.find((option: any) => option.value === state.product_group)}
                                                        options={data?.selectdata?.product_group}
                                                        value={state.product_group}
                                                        handleChange={
                                                            (e) => e !== null ?
                                                                handleInputChangeWithLabel(e, e.label) :
                                                                setState((prevFormData) => ({ ...prevFormData, product_group: null, product_group_label: '' }))
                                                        }
                                                        handleClick={() => setErrors((prevFormData) => ({ ...prevFormData, product_group: '' }))}
                                                        disabled={!varState.timerrunning}
                                                    />
                                                    <ErrorMessage
                                                        errorMessage={t(errors.product_group)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`col-6 ${styles.form_content}`}>
                                            <div className="d-flex justify-content-between  mb-4">
                                                <LabelField
                                                    title={t('Fibre content') + ':'}
                                                    className={`${styles.overview_label}`}
                                                />
                                                <div className="w-50">
                                                    <MultiSelect
                                                        name={'fibre_content'}
                                                        isClearable
                                                        isSearchable
                                                        standards={data?.selectdata?.fibre_content.find((option: any) => option.value === state.fibre_content)}
                                                        options={data?.selectdata?.fibre_content}
                                                        value={state.fibre_content}
                                                        handleChange={
                                                            (e) => e !== null ?
                                                                handleInputChange(e) :
                                                                setState((prevFormData) => ({ ...prevFormData, fibre_content: null }))
                                                        }
                                                        handleClick={() => setErrors((prevFormData) => ({ ...prevFormData, fibre_content: '' }))}
                                                        disabled={!varState.timerrunning}
                                                    />
                                                    <ErrorMessage
                                                        errorMessage={t(errors.fibre_content)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`col-6 ${styles.form_content}`}>
                                            <div className="d-flex justify-content-between  mb-4">
                                                <LabelField
                                                    title={t('Product type') + ':'}
                                                    className={`${styles.overview_label}`}
                                                    mandatory
                                                />
                                                <div className="w-50">
                                                    <MultiSelect
                                                        name={'product_type'}
                                                        isClearable
                                                        isSearchable
                                                        standards={data?.selectdata?.product_type.find((option: any) => option.value === state.product_type)}
                                                        options={data?.selectdata?.product_type}
                                                        value={state.product_type}
                                                        handleChange={
                                                            (e) => e !== null ?
                                                                handleInputChangeWithLabel(e, e.label) :
                                                                setState((prevFormData) => ({ ...prevFormData, product_type: null, product_type_label: '' }))
                                                        }
                                                        handleClick={() => setErrors((prevFormData) => ({ ...prevFormData, product_type: '' }))}
                                                        disabled={!varState.timerrunning}
                                                    />
                                                    <ErrorMessage
                                                        errorMessage={t(errors.product_type)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`col-6 ${styles.form_content}`}>
                                            <div className="d-flex justify-content-between  mb-4">
                                                <LabelField
                                                    title={'Shipment:'}
                                                    className={`${styles.overview_label}`}
                                                    mandatory
                                                />
                                                <div className="w-50">
                                                    <MultiSelect
                                                        name={'shipment_id'}
                                                        isClearable
                                                        isSearchable
                                                        standards={data?.shipment?.find((option: any) => option.value === state.shipment_id)}
                                                        options={data?.shipment}
                                                        value={state.shipment_id}
                                                        handleChange={
                                                            (e) => e !== null ?
                                                                handleInputChange(e) :
                                                                setState((prevFormData) => ({ ...prevFormData, shipment_id: null }))
                                                        }
                                                        handleClick={() => setErrors((prevFormData) => ({ ...prevFormData, shipment_id: '' }))}
                                                        disabled={!varState.timerrunning || state.shipment_id}
                                                    />
                                                    <ErrorMessage
                                                        errorMessage={errors.shipment_id}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* right block ends */}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between mt-2">
                        <Button
                            title={t(CommonConstants.BACK)}
                            className={'back-btn shadow-none text-decoration-none'}
                            handleClick={() => navigate(-1)}
                        />
                        <div>
                            {!edit && <Button
                                title={t(CommonConstants.SAVE_AND_GENERATE_BARCODE)}
                                className={'submit-btn shadow-none'}
                                handleClick={(event) => handleSubmit(event, true)}
                            />}
                            {/* <Button
                                        title={t(CommonConstants.SAVE)}
                                        className={'submit-btn_reverse shadow-none'}
                                        handleClick={(event) => handleSubmit(event, false)}
                                    /> */}
                        </div>
                    </div>
                </>
            }
        </Layout>
    )
}

export default DataEntryOverview;
