import React, { useState } from "react";
import ImageField from "../atoms/ImageField";
import IconWithLabel from "../atoms/IconWithLabel";
import IconData from "../../static/IconData";
import LabelField from "../atoms/LabelField";
import { t } from "../../pages/Translations/TranslationUtils";
import Popup from "../../utils/popup";
import BarcodeBody from "../organisms/BarcodeBody";

interface GenerateBarcodeMoleculeProps {
  barcodeUrl: any;
  title: any;
  handlePrint: (param: any) => void;
  setPopup: any;
  popup: any;
}
const GenerateBarcodeMolecule: React.FC<
  GenerateBarcodeMoleculeProps
> = ({ barcodeUrl, title, handlePrint, setPopup, popup }) => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "48vh" }}
    >
      <div className="text-center">
        <div className="p-5 bg-white">
          <p className="text-center ">
            <span className="py-2 page-sub-title text-gray">
              {t('We have successfully generated the barcode')}
            </span>
          </p>
          <div className="p-2">
            <ImageField
              className="w-10 shipment-barcode"
              value={barcodeUrl}
              altText={t("Barcode")}
            />{" "}
          </div>
          <LabelField title={t("Print barcode")} />
          <IconWithLabel
            hoverText={t("Print barcode")}
            svgData={IconData.PrinterIcon}
            handleClick={() => setPopup((prev: any) => ({ ...prev, viewBarcode: true }))}
            classname1="hoversvg"
          />
        </div>
      </div>
      {popup?.viewBarcode && (
        <Popup
          body={
            <BarcodeBody
              handlePrint={handlePrint}
            />
          }
          title={t("Print barcode")}
          cancel={() => { setPopup((prev: any) => ({ ...prev, viewBarcode: false }))}}
        />
      )}
    </div>
  );
};

export default GenerateBarcodeMolecule;
