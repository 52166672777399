export const statevalue = {
    renewal_id: "",
    sku: "",
    serial_number: "",
    style_number: "",
    color: null,
    colorlabel: '',
    size: null,
    sizelabel: '',
    dimension: null,
    product_type: null,
    product_type_title: "",
    product_tier: null,
    product_group: null,
    product_group_title: "",
    gender: null,
    clean_process: null,
    weight: "",
    merchandise_image: [],
    merchandise_image_url: [],
    merch_rack: "",
    old_merch_rack:"",
    merchandise_status: true,
    fibre_content: null,
    product_description: "",
    createdAt: null,
    updatedAt: null
}

export const getrowvalues = (configdata: any) => {
    let rowvalues = [
        {
            0: {
                'name': 'style_number',
                'type': 'text',
                'required': true,
                'validation': 'required_text',
                'value': ''
            },
            1: {
                'name': 'product_tier',
                'type': 'buttonwithpopup',
                'value': null,
                'validation': 'select',
                'options': configdata.bpdata.product_tier
            }
        },
        {
            0: {
                'name': 'color',
                'type': 'select',
                'required': true,
                'value': null,
                'validation': 'select',
                'options': configdata.bpdata.color
            },
            1: {
                'name': 'weight',
                'type': 'text'
            }
        },
        {
            0: {
                'name': 'size',
                'type': 'select',
                'required': true,
                'value': null,
                'validation': 'select',
                'options': configdata.bpdata.size
            },
            1: {
                'name': 'clean_process',
                'type': 'select',
                'value': null,
                'options': configdata.bpdata.clean_process
            }
        },
        {
            0: {
                'name': 'gender',
                'type': 'select',
                'value': null,
                'validation': 'select',
                'options': configdata.bpdata.gender
            },
            1: {
                'name': 'dimension',
                'type': 'buttonwithpopup',
                'value': null,
                'validation': 'select',
                'options': configdata.bpdata.dimension
            }
        },
        {
            0: {
                'name': 'product_group',
                'type': 'select',
                'required': true,
                'value': null,
                'validation': 'select',
                'options': configdata.bpdata.product_group
            },
            1: {
                'name': 'fibre_content',
                'type': 'select',
                'value': null,
                'validation': 'select',
                'options': configdata.bpdata.fibre_content,
                'disable':true
            }
        },
        {
            0: {
                'name': 'product_type',
                'type': 'select',
                'required': true,
                'value': null,
                'validation': 'select',
                'options': configdata.bpdata.product_type
            },
            1: {
                'name': 'product_description',
                'type': 'text',
                'disable':true
            }
        }
    ]
    return rowvalues;
}