import { APICALL } from "./ApiServices";
import { fetchConfigData, getUsernames, printLabel } from "../routes/ApiEndPoints";
import * as markerjs2 from 'markerjs2';
import { capitalize } from "@mui/material";
import { Options } from "../@types/options";

const CommonServices = {
  capitalizeLabel: function (str: string): string {
    let convertedstr = '';
    convertedstr = str.replace(/\d+$/, '');
    convertedstr = convertedstr.charAt(0).toUpperCase() + convertedstr.slice(1);
    convertedstr = convertedstr.replace(/_/g, " ");
    return convertedstr;
  },
  capitalizeFirstLetter: function (str:string): string {
    str = str.charAt(0).toUpperCase() + str.slice(1);
    return str;
  },
  convertIntoModelName: function (str: string): string {
    const words = str.split('_');
    const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    return capitalizedWords.join('');
  },
  convertModelNameIntoSnakeCase: function (str: string): string {
    let convertedstr = '';
    convertedstr = str.replace(/([a-z])([A-Z])/g, '$1_$2')
    convertedstr = convertedstr.toLowerCase()
    return convertedstr;
  },
  stringFormat: function (str: string): string {
    if (!str) return "";
    if (Array.isArray(str)) {
      str = str[0] ?? ""
    }
    let formattedString = str.charAt(0).toUpperCase() + str.slice(1);
    // Preserve spaces
    formattedString = formattedString.replace(/\s+/g, ' ');
    return formattedString;
  },
  removeUnderScore: function (str: string): string {
    let convertedstr = str.replaceAll("_", " ");
    return convertedstr;
  },
  formatToValueLabel: function (list:any, idname:string, title = 'title') {
    const formatteddata: Options[] = list.map((item: any) => ({
        value: item[idname],
        label: item[title].charAt(0).toUpperCase() + item[title].slice(1),
      })
    );
    return formatteddata;
  },
  fetchMasterData: async function (type: string, name: string, status: boolean = false, id: any = '') {
    let data: any = {
      "type": type,
      "name": name
    }
    status ? data.status = status : null
    id != '' ? data.id = id : null;

    let postdata = {
      'method': 'POST',
      "data": data
    }
    let response = await APICALL.service(fetchConfigData, 'POST', postdata, true);
    return response.data;
  },

  convertBase64imageToBlob: async function (url: any) {
    const base64Data = await url.replace(/^data:image\/(png|jpg);base64,/, '');
    const blob = new Blob([Uint8Array.from(atob(base64Data), c => c.charCodeAt(0))], { type: 'image/png' });
    return blob;
  },

  showDateBasedOnFormat: function (timestamp: string, format: string, time: boolean = false) {
    const date = new Date(timestamp);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const abbreviatedMonth = monthNames[date.getUTCMonth()];

    let formatted = '';

    switch (format) {
      case 'dd/MM/yyyy':
        formatted = `${day}/${month}/${year}`;
        break;
      case 'MM/dd/yyyy':
        formatted = `${month}/${day}/${year}`;
        break;
      case 'yyyy/MM/dd':
        formatted = `${year}/${month}/${day}`;
        break;
      case 'MM/yyyy/dd':
        formatted = `${month}/${year}/${day}`;
        break;
      case 'dd-MM-yyyy':
        formatted = `${day}-${month}-${year}`;
        break;
      case 'MM-dd-yyyy':
        formatted = `${month}-${day}-${year}`;
        break;
      case 'yyyy-MM-dd':
        formatted = `${year}-${month}-${day}`;
        break;
      case 'MM-yyyy-dd':
        formatted = `${month}-${year}-${day}`;
        break;
      case 'dd/MMM/yyyy':
        formatted = `${day}/${abbreviatedMonth}/${year}`;
        break;
      case 'dd-MMM-yyyy':
        formatted = `${day}-${abbreviatedMonth}-${year}`;
        break;
      case 'MMM dd, yyyy':
        formatted = `${abbreviatedMonth} ${day}, ${year}`;
        break;
      default:
        formatted = `${day}-${month}-${year}`;
    }

    if (time)
      formatted = `${formatted} ${hours}:${minutes}`;
    return formatted;
  },

  fetchUsernamesByIds: async function (userIds?: any) {
    try {
      let postData = {
        method: "POST",
        data: { userIds }
      };
      let response = await APICALL.service(getUsernames, "POST", postData, true);
      if (response?.status !== 200) {
        throw new Error("Request failed");
      }
      return response?.data ?? [];
    } catch (error) {
      console.error("Error fetching user names:", error);
      return false;
    }
  },
  toggleLoadingIcon: async function (status: any) {
    const loadingIcon = document.getElementById("loading-div-id");
    if (loadingIcon !== null)
      loadingIcon.setAttribute("style", "display:" + status + ";");
  },

  delay: async (milliseconds: number) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  },

  convertNumberIntoTwoDigits: function (number: number | string) {
    return Number(number).toString().padStart(2, '0');
  },

  sortAlphabattically: function (data: any, label: string = 'title') {
    return data.sort((a: any, b: any) => a[label].localeCompare(b[label]));
  },
  removeSpaceAndAddUnderscore: (inputString: string): string => {
    const modifiedString = inputString.replace(/\s+/g, '_').toLowerCase();
    return modifiedString;
  },
  removeSearchQueryFromUrl:function (url:string) {
    if(url != "" && url != undefined && url != null){
      const parsedurl = new URL(url);
      parsedurl.search = "";
      const newurl = parsedurl.toString();
      return newurl;
    } else {
      return ""
    }
  },
  formatTimeToDays: (timeString: string) => {
    const parts = timeString.split(':');
    if (parts.length < 3) {
      return "Invalid time format";
    }
    const [hours, minutes, seconds] = parts.map(parseFloat).map(val => isNaN(val) ? 0 : val);
    const formatUnit = (value: number, unit: string) => (value === 1) ? `${value} ${unit}` : (value > 1) ? `${value} ${unit}s` : '';
    const totalMilliseconds = (hours * 3600 + minutes * 60 + seconds) * 1000;
    if (totalMilliseconds < 1000) {
      return '0 second';
    }
    if (totalMilliseconds >= 86400000) {
      const days = Math.floor(totalMilliseconds / 86400000);
      const remainingMilliseconds = totalMilliseconds % 86400000;
      const [remainingHours, remainingMinutes, remainingSeconds] = [
        Math.floor(remainingMilliseconds / 3600000),
        Math.floor((remainingMilliseconds % 3600000) / 60000),
        Math.floor((remainingMilliseconds % 60000) / 1000)
      ];
      return [formatUnit(days, 'day'), formatUnit(remainingHours, 'hour'), formatUnit(Math.floor(remainingMinutes), 'minute'), formatUnit(remainingSeconds, 'second')]
        .filter(part => part !== '')
        .join(' ');
    } else {
      return [formatUnit(hours, 'hour'), formatUnit(minutes, 'minute'), formatUnit(Math.floor(seconds), 'second')]
        .filter(part => part !== '')
        .join(' ');
    }
  },
  convertTime: (timeValue: any, isStringToSeconds = false) => { // To convert seconds to string and string to seconds
    if (!timeValue) {
      return isStringToSeconds ? 0 : '00:00:00';
    }

    if (!isStringToSeconds) {
      const [hours, minutes, seconds] = timeValue.split(':');
      return (parseInt(hours, 10) * 3600) + (parseInt(minutes, 10) * 60) + parseFloat(seconds);
    } else {
      const hours = Math.floor(timeValue / 3600);
      const minutes = Math.floor((timeValue % 3600) / 60);
      const seconds = Math.floor(timeValue % 60);

      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    }
  },
  getTimezoneOffset: async (dateString: any) => { // To convert time according to zone
    if (dateString) {
      const date = new Date(dateString);
      const timezoneOffset = date.getTimezoneOffset() * 60 * 1000;
      const formattedDate = new Date(date.getTime() - timezoneOffset).toISOString().slice(0, 10);
      return formattedDate;
    }
    return null;
  },
  checkPermissions: async (
    userPermissions: any,
    requiredPermissions: any,
    strict: any,
    actions: any,
    override?: any
  ) => {

    if (override === true) {
      return true;
    }

    if (requiredPermissions.length === 0) {
      return true; // No allowed permissions specified, grant access
    }

    if (strict) {
      // Strictly check all allowed permissions.
      for (const allowedPermission of requiredPermissions) {
        const matchedPermissions = userPermissions?.filter(
          (perm: any) =>
            perm.permission.toLowerCase() ===
            allowedPermission.permission.toLowerCase()
        );

        if (!matchedPermissions) {
          // If the permission is not found in matchedPermissions, it's not satisfied.
          return false;
        }

        const matchedTruePermissions = matchedPermissions.find((item: any) =>
          Object.values(item).some((val) => val === true)
        );
        if (!matchedTruePermissions) {
          // If the permission is not found in matchedPermissions, it's not satisfied.
          return false;
        }

        // Check each specific permission (create, read, update, delete).
        if (allowedPermission.read && !matchedTruePermissions.read) {
          return false;
        }
        if (allowedPermission.create && !matchedTruePermissions.create) {
          return false;
        }
        if (allowedPermission.update && !matchedTruePermissions.update) {
          return false;
        }
        if (allowedPermission.delete && !matchedTruePermissions.delete) {
          return false;
        }
      }
      return true;
    } else if (actions) {
      for (const allowedPermission of requiredPermissions) {
        const matchedPermissions = userPermissions?.filter(
          (perm: any) =>
            perm.permission.toLowerCase() ===
            allowedPermission.permission.toLowerCase()
        );

        const isAnyPermissionTrue = Object.keys(allowedPermission).some((key: any) => {

          return matchedPermissions.some((matched: any) =>
            key != "permission" && matched.permission.toLowerCase() === allowedPermission.permission.toLowerCase() &&
            allowedPermission[key] ==
            matched[key]
          );
        });

        return isAnyPermissionTrue
      }
    } else {
      let status;
      for (const allowedPermission of requiredPermissions) {
        const matchedPermissions = userPermissions?.filter(
          (perm: any) =>
            perm.permission.toLowerCase() ===
            allowedPermission.permission.toLowerCase()
        );

        const matchedTruePermissions = matchedPermissions?.find((item: any) =>
          Object.values(item).some((val) => val === true)
        );

        if (!matchedTruePermissions) {
          // If the permission is not found in matchedPermission, it's not satisfied.
          status = false;
        } else {
          for (const key in matchedTruePermissions) {

            if (matchedTruePermissions[key] === true) {
              status = true;
              return status
            }
          }
        }
      }
      return status
    }
  },
  handleMarkedAreaColor: function (markedarea:any, color?:string, ) {    
    let data:any =  markedarea.currentstate   
    if( color != '') {
      data.markers[0].fillColor = color;
      data.markers[0].strokeColor = color;
    }
    let element:any = markedarea.imageElement
    let target = element.target
    let imageElement:any = document.getElementById(target.id);
    let markerArea1 = new markerjs2.MarkerArea(imageElement);
    markerArea1.renderState(data);
    markerArea1.addEventListener('render', event1 => {
        target.src = event1.dataUrl;
    });
  },
  printLabelApi: async function (postdata?: any) {
    try {
      let postData = {
        method: "POST",
        data: postdata
      };
      let response = await APICALL.service(printLabel, "POST", postData);      
      if (response.statusCode !== 201) {
        return { ...response, message: "Error while printing label. Try again after sometime.", type: 'error' };
      } else {
        return { ...response, message: "Label printed successfully", type: 'success' };      
      }
    } catch (error) {
      console.error("Error while printing label: ", error);
      return false;
    }
  },
};
export default CommonServices;
