let alertText = '';

/**
 * [alert description]
 * @param  {String} [alertType]               [success/error/info/warning]
 * @param  {String} [text]                    [description]
 * @param  {Number} [timeout]                  [description]
 * @return {[void]}                [description]
 */
export default function customAlert(alertType: string = '', text: string = '',  timeout: number = 0): void {
  alertText = text;
  showAlert(alertType);
  setTimeout(() => {
    const customAlertDiv = document.getElementById('custom-alert-div-id');
    if (customAlertDiv) {
      customAlertDiv.remove();
    }
  }, timeout);
}

function showAlert(alertType: string): void {
  let alertClass = '';
  switch (alertType) {
    case 'success':
      alertClass = 'alert-success';
      break;
    case 'error':
      alertClass = 'alert-danger';
      break;
    case 'warning':
      alertClass = 'alert-warning';
      break;
    case 'info':
      alertClass = 'alert-info';
      break;
    default:
      alertClass = 'd-none';
  }
  if (document) {
    appendHtml(alertClass);
  }
}

function appendHtml(alertClass: string): void {
  const el = document.getElementById('__next');
  if (el) {
    const div = document.createElement('div');
    div.style.cssText = 'position: relative;';
    div.innerHTML = `<div
      style="position:fixed; top:80px; z-index:2000; width:50%; text-align:center; margin:auto;left:30%;"
      class="alert ${alertClass} rounded-0 border-0"
      role="alert"
      id="custom-alert-div-id"
    >
      <span class="fw-bold text-break">${alertText}</span>
      <span class="btn-close" aria-label="Close" id="custom-alert-close-button" style="position: absolute; cursor: pointer; top: 2px; right: 5px;">X<Close /></span>
      </div>`;
    if (div.children.length > 0) {
      el.appendChild(div.children[0]);
    }

    const closeButton = document.getElementById('custom-alert-close-button');
    if (closeButton) {
      closeButton.addEventListener('click', () => {
        const customAlertDiv = document.getElementById('custom-alert-div-id');
        if (customAlertDiv) {
          customAlertDiv.remove();
        }
      });
    }
  }
}
