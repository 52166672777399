import React, { useEffect, useState } from "react";
import { APICALL } from "../../../services/ApiServices";
import {
  fetchWorkflowStages,
  moveItemToAnyStage,
} from "../../../routes/ApiEndPoints";
import { t } from "../../../pages/Translations/TranslationUtils";
import CommonServices from "../../../services/CommonServices";
import Button from "../../atoms/Button";
import CommonShipmentServices from "../../../services/CommonShipmentServices";
import customAlert from "../../atoms/CustomAlert";
import style from "../organisms.module.css";
import ErrorMessage from "../../atoms/ErrorMessage";
import { useNavigate } from "react-router-dom";
import { CommonConstants } from "../../../pages/Shipment/Constants/ShipmentConstants";
interface MoveItemToAnyStageProps {
  renewalid: any;
  stagename: any;
  setShowMoveItemPopup: (show: boolean) => void;
  fetchHoldData?: () => void;
}

const MoveItemToAnyStage: React.FC<MoveItemToAnyStageProps> = ({
  renewalid,
  stagename,
  setShowMoveItemPopup,
  fetchHoldData
}) => {
  const navigate = useNavigate();
  const [state, setState] = useState<{
    stages: { value: any; label: string }[];
    selected: any[];
    error: string;
  }>({
    stages: [],
    selected: [],
    error: "",
  });
  const localdata = CommonShipmentServices.getPostData();

  useEffect(() => {
    if (renewalid != null) {
      fetchStages();
    }
  }, []);

  const fetchStages = async () => {
    const response = await APICALL.service(fetchWorkflowStages, "GET", "");
    const notinclude = ["data_entry", "sort", "internal_laundry", "category_sort", stagename];
    if (response.status === 200) {
      const options = response.data
        .filter((item: any) => !notinclude.includes(item.stage_name))
        .map((item: any) => ({
          value: item.stage_id,
          label: item.stage_name,
        }));

      setState((prev) => ({
        ...prev,
        stages: CommonServices.sortAlphabattically(
          options?.map((item: any) => ({
            ...item,
            label: CommonServices.capitalizeLabel(item?.label ?? ""),
          })),
          "label"
        ),
      }));
    }
  };

  const handleSelectChange = (value: any, label: string) => {
    if (state.error) {
      setState((prev) => ({ ...prev, error: "" }));
    }
    setState((prev) => ({ ...prev, selected: [{ value, label }] }));
  };

  const handleSave = async () => {
    try {
      if (state.selected.length) {
        const postdata = {
          stage: { ...state.selected[0], stage_name: (CommonServices.removeSpaceAndAddUnderscore(state?.selected[0]?.label))},
          renewalid: renewalid,
          currentstage: stagename,
          ...localdata,
        };
        if (fetchHoldData) {
          postdata.moveitemfromhold = true;
        }
        const response = await APICALL.service(
          moveItemToAnyStage,
          "POST",
          postdata
        );
        if (response.status === 200) {
          if (fetchHoldData) {
            fetchHoldData();
            setShowMoveItemPopup(false);
          } else {
            navigate(localStorage?.getItem('shipment_dashboard_url') ?? "/shipment-dashboard");
          }
        } else {
          customAlert("error", t(response.message), 6000);
        }
      } else {
        setState((prev) => ({ ...prev, error: t("Please select the stage") }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="d-flex flex-wrap">
        {state.stages.map((item) => (
          <div key={item.value} className={`${style.moveitem_to_any_stage}`}>
            <div
              title={t(item.label)}
              className={`w-100 shadow rounded cursor-pointer ${style.moveitem_to_any_stage_inner} ${
                state.selected.some(
                  (selectedItem) => selectedItem.value === item.value
                )
                  ? "border-danger border text-red"
                  : ""
              }`}
              onClick={() => handleSelectChange(item.value, item.label)}
            >
              {t(item.label)}
            </div>
          </div>
        ))}
      </div>
        {state.error && <ErrorMessage
          errorMessage={t(state.error)}
          className="text-center mt-5"
        />}
      <div className="d-flex flex-wrap justify-content-between mt-5">
        <Button
          title={t(CommonConstants.CANCEL)}
          className="back-btn"
          handleClick={() => setShowMoveItemPopup(false)}
        />
        <Button
          title={t(CommonConstants.SAVE)}
          className="submit-btn"
          handleClick={handleSave}
        />
      </div>
    </>
  );
};

export default MoveItemToAnyStage;
