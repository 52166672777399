import { fetchAllBrandPartnerUrl, saveTime } from "../routes/ApiEndPoints";
import { APICALL } from "./ApiServices";
import { useUserContext } from "../routes/Contextlib";
import CommonServices from "./CommonServices";

const CommonShipmentServices = {
  getPostData: function (): any {
    const { user } = useUserContext();
    let bpdetails: any = localStorage.getItem("bp_details");
    bpdetails =
      bpdetails != undefined || bpdetails != "" ? JSON.parse(bpdetails) : null;
    let shipmentdetails: any = localStorage.getItem("shipment_details");
    shipmentdetails =
      shipmentdetails != undefined || shipmentdetails != ""
        ? JSON.parse(shipmentdetails)
        : null;
    return {
      brand_partner_id: bpdetails != null ? bpdetails.brand_partner_id : "",
      bpname: bpdetails != null ? bpdetails.title : "",
      shipment_id: shipmentdetails != null ? shipmentdetails.shipment_id : "",
      user_id: user?.user_id ?? null,
    };
  },
  getFilteredTitle: function (
    data: any,
    name: string,
    id: any,
    field = ""
  ): any {
    if (id == null) return "";
    let fieldval = field != "" ? field : "title";
    const fieldvalue = data
      ?.filter((item: any) => item[name] == id)
      .map((item: any) => item[fieldval] ?? "");
    if (fieldvalue.length > 1) {
      return fieldvalue.join(', ');
    } else {
      return fieldvalue;
    }
  },
  getFilteredObject: function (list: any, name: string, id: any): any {
    const object = list
      .filter((item: any) => item[name] === id)
      .map((item: any) => item);

    return object;
  },
  saveTimerApi: async function (
    type: string = "renewal",
    parent_id: number | null = null,
    user_id: number | null = null,
    stage: string = "",
    reloaded: boolean = true,
    disabletimetrackerapi: boolean = false
  ) {
    let postdata = {
      type: type,
      parent_id: parent_id,
      stage_name: stage,
      user_id: user_id,
      reloaded: reloaded,
    };
    if (!disabletimetrackerapi) {
      const response = await APICALL.service(saveTime, "POST", postdata);
      return response.status === 200 && response.timedata.total_time;
    } else {
      return 0;
    }
  },
  setBpDetails: async (id: any) => {
    try {
      const postData = {
        method: "POST",
        data: { brandpartner_id: id },
      };

      const response = await APICALL.service(
        fetchAllBrandPartnerUrl,
        "POST",
        postData,
        true
      );
      if (response?.status == 200) {
        const data = response?.data;
        let bpdata = data?.first[0];
        let bpPermissiondata = data?.sixth;

        bpdata.filepath = data?.first[2] != null ? data.first[2].file_path : "";
        localStorage.setItem("bp_details", JSON.stringify(bpdata));
        localStorage.setItem(
          "bp_permissions_details",
          JSON.stringify(bpPermissiondata)
        );
        localStorage.setItem("bp_product_group", JSON.stringify(data.first[1]));
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  },
};
export default CommonShipmentServices;
