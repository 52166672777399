import React, { useState, useEffect } from "react";
import Popup from "../../../utils/popup";
import PublishListPopupBody from "./PublishListPopupBody";
import { t } from "../../../pages/Translations/TranslationUtils";

interface Props {
    published: any,
    rejected: any;
    handleSubmit:any;
}

const PublishListPopup: React.FC<Props> = ({ published, rejected, handleSubmit }) => {
    const viewYes = () => {
        let text = '';
        if(rejected > 0) {
            text = t('View') 
        }
        return text;
    }
    return(
        <Popup
            title={t("Published items list")}
            body={<PublishListPopupBody published={published} rejected={rejected}/>}
            cancel={() => handleSubmit(false)}
            submit={() => handleSubmit(true)}
            yestext={viewYes()}
            notext={t("Back")}
          />
    )

};

export default PublishListPopup;