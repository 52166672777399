import React, { useState, ChangeEvent, FocusEvent, MouseEvent, RefObject } from 'react';

const style = {};

interface Props {
  id?:string;
  type?: string;
  className?: string;
  value?: string;
  isDisabled?: boolean;
  placeholder?: string;
  customStyle?: React.CSSProperties;
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleClick?: (event: FocusEvent<HTMLInputElement>) => void;
  handleKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  handleBlur?: () => void;
  name?: string;
  error?:string;
  readOnly?: boolean;
  autoComplete?: boolean;
  inputRef?: RefObject<HTMLInputElement> | null;
  autoFocus?: boolean;
}

const InputTextfield: React.FC<Props> = ({
  id = '',
  type = "text",
  className = '',
  value = '',
  isDisabled = false,
  placeholder = '',
  customStyle = {},
  handleChange,
  handleClick,
  name = '',
  error = '',
  readOnly = false,
  handleKeyDown,
  autoComplete,
  handleBlur,
  inputRef = null,
  autoFocus = false
}) => {
  const [isFocused, setIsFocused] = useState(false);  

  return (
    <>
    <input
      style={{ ...style, ...customStyle }}
      id={id}
      type={type}
      className={className}
      disabled={isDisabled}
      placeholder={isFocused ? '' : placeholder}
      name={name}
      value={value}
      onChange={handleChange}
      onFocus={handleClick}
      onBlur={handleBlur}
      readOnly={readOnly}
      onKeyDown={handleKeyDown}
      autoComplete={autoComplete ? "" : "off"}
      ref={inputRef} 
      autoFocus={autoFocus}
    />
    {error && <span className="text-danger mt-2 fs-6">{error}</span>}
    </>
  );
};

export default InputTextfield;
