import React, { ReactNode, MouseEvent } from 'react';

interface Props {
  title?: ReactNode;
  handleClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  disabled?: boolean;
  children?: ReactNode;
  id?:string;
  hoverText?: string;
  name?: string;
}

const Button: React.FC<Props> = ({ title, handleClick, id, className = '', disabled = false, hoverText = '', children, name }) => {
  return (
    <button type="button" title={hoverText} className={className} id ={id} onClick={handleClick} disabled={disabled} name={name}>
      {title}
      {children}
    </button>
  );
};

export default Button;
