import React, { useRef, useState, useEffect } from "react";
import { t } from "../Translations/TranslationUtils";
import CustomAlert from "../../components/atoms/CustomAlert";
import styles from "../MediaLibrary/MediaLibrary.module.css";
import { Upload } from "../../static/icons/Upload";
import { APICALL } from "../../services/ApiServices";
import Pagination from "../../services/Paginations";
import * as XLSX from "xlsx";
import Popup from "../../utils/popup";
import {
  importProductCatalogue,
  fetchProductCatalogue,
  fetchAllBrandPartnerUrl,
  updateCatalogue,
} from "../../routes/ApiEndPoints";
import AccessControl from "../../services/AccessControl";
import TableStructure from "../../components/atoms/TableStructure";
import {
  CommonConstants,
  ProductCatalogueConstants,
} from "../Shipment/Constants/ShipmentConstants";
import { useNavigate } from "react-router-dom";
import InputWithButton from "../../components/molecules/InputWithButton";
import { CONSTANTS } from "../../applicationConstants/AppConstants";
import ShipmentReportsFilterBody from "../../components/molecules/ShipmentReportsFilterBody";
import Button from "../../components/atoms/Button";
import customAlert from "../../components/atoms/CustomAlert";
import CommonServices from "../../services/CommonServices";
import WindowHeightComponent from "../../commonComponents/WindowHeightComponent";
import { FolderIcon } from "../../static/icons/FolderIcon";
import InputTextfield from "../../components/atoms/InputTextField";

const ProductCatalogue: React.FC = () => {
  const navigate = useNavigate();
  const brand = JSON.parse(localStorage.getItem('catalogue_bp') ?? '{}');
  const [catalogue, setcatalogue] = useState<any[]>([]);
  const [brandpartners, setBrandPartners] = useState<any>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([
    "sku",
    "brand_partner_title",
    "product_description",
    "product_type",
    "product_group",
    "gender",
    "currency",
  ]);
  const [state, setState] = useState({
    showPopup: false,
    filterPopup: false,
    filteredTableHeaders: [{}],
    filteredTableValues: [{}],
  });
  const [popup, setPopup] = useState(false);
  const [archiveState, setArchiveState] = useState({ archive: "", id: "" });
  const [activeTab, setActiveTab] = useState("true");
  const [filterobj, setFilterObj] = useState<any>({
    search: "",
    limit: CONSTANTS.PAGINATION_LIMIT,
    brand_partner_id: null,
    offset: 0,
    status: "true",
  });
  const [stateobj, setStateObj] = useState<any>({
    currentpage: 1,
    totalpages: 1,
    count: 0,
    searchtext: ''
  });

  const filterValues = [
    { value: 1, title: t("Brand partner"), handle: "brand_partner_title" },
    { value: 2, title: t("Product description"), handle: "product_description", },
    { value: 3, title: t("Product type"), handle: "product_type" },
    { value: 4, title: t("Product group"), handle: "product_group" },
    { value: 5, title: t("Gender"), handle: "gender" },
    { value: 6, title: t("Currency"), handle: "currency" },
    { value: 7, title: t("Country"), handle: "country" },
    { value: 8, title: t("Renewal fee"), handle: "renewal_fee" },
    { value: 9, title: t("MSRP"), handle: "msrp" },
    { value: 10, title: t("Size"), handle: "size" },
    { value: 11, title: t("Color"), handle: "color_label" },
  ];

  useEffect(() => {
    !brandpartners && getBrandPartners();
    filterobj.brand_partner_id && getProductCatalogue();
  }, [filterobj]);

  const filteredBrandPartners = brandpartners?.filter((brandpartner: any) =>
    brandpartner.title.toLowerCase().includes(stateobj.searchtext.toLowerCase())
  );

  useEffect(() => {
    if (brand) {
      setFilterObj((prevState: any) => ({
        ...prevState,
        brand_partner_id: brand.brand_partner_id
      }));
    }
  }, []);

  useEffect(() => {
    const successMessage: any =
      localStorage?.getItem("successAlertMessage") ?? null;
    const errorMessage: any = localStorage?.getItem("errorAlertMessage") ?? "";
    localStorage.removeItem("successAlertMessage");
    if (successMessage) {
      customAlert("success", t(successMessage), 2000);
    } else if (errorMessage) {
      customAlert("error", t(errorMessage), 2000);
    }
  });

  useEffect(() => {
    const filteredHeaders =
      ProductCatalogueConstants.PRODUCT_CATALGUE_TABLE_HEADERS.filter(
        (header) => {
          return (
            selectedColumns.includes(header.handle) || header.handle === "sku"
          );
        }
      );
    const filteredValues =
      ProductCatalogueConstants.DATA_ENTRY_TABLE_VALUES.filter((value) => {
        return selectedColumns.includes(value) || value === "sku";
      });
    setState((prev) => ({ ...prev, filteredTableHeaders: filteredHeaders }));
    setState((prev) => ({ ...prev, filteredTableValues: filteredValues }));
  }, [selectedColumns]);

  async function getProductCatalogue() {
    try {
      let postdata = {
        method: "POST",
        data: {
          ...filterobj,
        },
      };
      const response = await APICALL.service(
        fetchProductCatalogue,
        "POST",
        postdata,
        true
      );
      if (response?.status == 200 && response?.data) {
        const modifiedData = response?.data?.data?.map((item: any) => {
          return {
            ...item,
            brand_partner_title: item.brand_partner?.title || "N/A",
            product_description: item?.product_description ?? "",
            product_group: item?.product_group ?? "",
            product_type: item?.product_type ?? "",
            gender: item?.gender ?? "",
            country: item?.country ?? "",
            currency: item?.currency ?? "",
            msrp: item?.msrp ?? "",
            renewal_fee: item?.renewal_fee ?? "",
            resale_price: item?.resale_price ?? "",
            resale_price_2: item?.resale_price_2 ?? "",
          };
        });
        setcatalogue(modifiedData);
        getTotalPagesCount(response?.data?.total_count);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleTabClick = (status: string) => {
    setStateObj((stateobj: any) => ({ ...stateobj, currentpage: 1 }));
    setActiveTab(status);
    setFilterObj((filterobj: any) => ({
      ...filterobj,
      offset: 0,
      status: status == "true" ? "true" : "false",
    }));
  };

  const getBrandPartners = async () => {
    const postData = {
      method: "POST",
      data: { issaved: [true] },
    };
    const response = await APICALL.service(
      fetchAllBrandPartnerUrl,
      "POST",
      postData,
      true
    );
    if (response.status === 200) {
      const sortedBrandPartners = CommonServices.sortAlphabattically(response?.data ?? []);
      setBrandPartners(sortedBrandPartners);
    }
  };

  const getTotalPagesCount = (totalcount: number) => {
    const totalpages = Math.ceil(totalcount / CONSTANTS.PAGINATION_LIMIT);
    setStateObj((prevState: any) => ({
      ...prevState,
      count: totalcount,
      totalpages: totalpages,
    }));
  };

  const handleFileUpload = async (data: any, headerRow: any) => {
    let postdata = {
      method: "POST",
      data: {
        data: data,
        headerRow: headerRow,
        brand_partner_id: filterobj?.brand_partner_id,
      }
    }
    let response = await APICALL.service(importProductCatalogue, "POST", postdata, true);
    if (response != null && response.status == 200 && response.data != null) {
      setStateObj((prevState: any) => ({
        ...prevState,
        currentpage: 1,
      }));
      const modifiedData = response?.data?.data?.map((item: any) => {
        return {
          ...item,
          brand_partner_title: item.brand_partner?.title || "N/A",
          product_description: item?.product_description ?? "",
          product_group: item?.product_group ?? "",
          product_type: item?.product_type ?? "",
          gender: item?.gender ?? "",
          country: item?.country,
          currency: item?.currency ?? "",
          msrp: item?.msrp ?? "",
          renewal_fee: item?.renewal_fee ?? "",
          resale_price: item?.resale_price ?? "",
          resale_price_2: item?.resale_price_2 ?? "",
        };
      });
      setcatalogue(modifiedData);
      setActiveTab('true');
      setFilterObj((prev: any) => ({ ...prev, status: 'true', offset: 0 }));
      getTotalPagesCount(response?.data?.total_count);
    }
    let status = response && response.status == 200 ? "success" : "error";
    let message = response && response.message ? response.message : "";
    CustomAlert(status, t(message ?? ""), 6000);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  }

  const validateData = (actualHeaders: string[], requiredHeaders: string[], headerRow?: any): any => {
    if (!actualHeaders || actualHeaders?.length === 0) {
      return t('No data found in the file');
    }
    if (!headerRow) {
      if (actualHeaders.length != requiredHeaders.length) {
        return t('One or more header column is empty or invalid');
      } else if (!requiredHeaders.every(header => header !== null && actualHeaders.includes(header))) {
        return t('Please upload an Excel file with valid and required headers');
      }
    } else {
      let index = headerRow.indexOf('sku');      
      if (actualHeaders.some((item) => !item[index])) {
        return t('Sku is missing in some of the rows');
      }
      return lengthValidation(actualHeaders, headerRow);
    }
    return false;
  };

  const lengthValidation = (actualHeaders: string[], headerRow: any) => {
    const columns = [
        { name: 'sku', error: t('Some of style number length exceeds 10 characters'), maxLength: 15 },
        { name: 'product_description', error: t('Some of product description length exceeds 80 characters'), maxLength: 80 },
        { name: 'product_group', error: t('Some of product group length exceeds 10 characters'), maxLength: 10 },
        // { name: 'gender', error: t('Some of gender length exceeds 1 character'), maxLength: 1 }
    ];
    let error = "";
    columns.forEach((col) => {
        let index = headerRow.indexOf(col.name);

        if (index !== -1) {
            const hasExceedingLength = actualHeaders.some((item) => {
                let data = item[index]?.toString().trim();
                return data && data.length > col.maxLength;
            });
            if (hasExceedingLength) {
                error += `<div>${col.error}</div>`;
            }
        }
    });    
    return error === "" ? false : error;
  };

  const handleImport = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const requiredHeaders = ['sku', 'product_description', 'product_group', 'product_type',
      'gender', 'country', 'currency', 'msrp', 'renewal_fee', 'resale_price', 'resale_price_2'
    ];
    let headerRow: string[] = [];
    const file = event.target.files && event.target.files[0];

    if (file) {
      const fileExtension = file.name.split(".").pop();
      if (['xls', 'xlsx'].includes(fileExtension ?? "")) {
        const workbook = XLSX.read(await file.arrayBuffer(), { type: "array" });
        // Assuming you want to read data from the first sheet
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        // Parse the data
        const importedData: any = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
        });
        headerRow = importedData[0]?.map((header: any) => {
          if (CommonServices.removeSpaceAndAddUnderscore(header ?? "") === "resale_price_(tier_2)") {
            return 'resale_price_2';
          }
          return CommonServices.removeSpaceAndAddUnderscore(header ?? "");
        });
        let validate: any = validateData(headerRow, requiredHeaders);
        if (validate) {
          CustomAlert('error', validate, 6000);
        } else {
          const filteredData = importedData.slice(1).filter((row: any) =>
            row.length > 0
          );
          validate = validateData(filteredData, requiredHeaders, headerRow);
          if (!validate) {
            await handleFileUpload(filteredData, headerRow);
          } else {
            CustomAlert('error', validate, 6000);
          }
        }
      } else {
        CustomAlert('error', t("Please upload excel file"), 6000)
      }
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };
  const handleSearch = (search: any) => {
    if (filterobj.search != search) {
      setStateObj((stateobj: any) => ({
        ...stateobj,
        currentpage: 1,
      }));
      setFilterObj((prevState: any) => ({
        ...prevState,
        search: search,
        offset: 0,
      }));
    }
  };

  const handlePaginationClick = (pagenumber: number) => {
    const newoffset = (pagenumber - 1) * CONSTANTS.PAGINATION_LIMIT;
    setFilterObj((filterobj: any) => ({
      ...filterobj,
      offset: newoffset,
    }));
    setStateObj((stateobj: any) => ({
      ...stateobj,
      currentpage: pagenumber,
    }));
  };

  const handleEdit = (e: any) => {
    navigate(
      `/product-catalogue/?edit=${e.product_catalogue_id}&search=${filterobj.brand_partner_id ? true : false
      }`
    );
  };

  const handleArchivePopup = async (e: any) => {
    setPopup(true);
    const status = e.status ? "true" : "false";
    setArchiveState((prev) => ({
      ...prev,
      archive: status,
      id: e.product_catalogue_id,
    }));
  };

  const handleArchive = async () => {
    const postData = {
      method: "POST",
      data: archiveState,
    };
    const response = await APICALL.service(
      updateCatalogue,
      "POST",
      postData,
      true
    );
    if (response?.status == 200) {
      CustomAlert("success", t(`Item ${archiveState.archive == "false" ? "activated" : "archived"
        } successfully`), 6000);
      setActiveTab(archiveState.archive == "true" ? "false" : "true");
      setFilterObj((prev: any) => ({
        ...prev,
        status: archiveState.archive == "true" ? "flase" : "true",
      }));
    } else {
      CustomAlert("success", t(response?.message), 6000);
    }
    setPopup(false);
  };

  const handleBrandClick = (brand: any) => {
    localStorage.setItem('catalogue_bp', JSON.stringify(brand));
    setFilterObj((prev: any) => ({
      ...prev,
      brand_partner_id: brand.brand_partner_id
    }))
  }

  const handleBack = () => {
    if (filterobj.brand_partner_id == null) {
      navigate("/application-configuration")
    } else {
      localStorage.removeItem('catalogue_bp');
      setStateObj((prev: any) => ({
        ...prev,
        searchtext: ''
      }));
      setActiveTab('true');
      setFilterObj((prev: any) => ({
        ...prev,
        brand_partner_id: null
      }));
    }
  }

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "product catalogue",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <div className="row">
        <div className="col-md-11 ms-auto px-4">

          <WindowHeightComponent>
            <div className="d-flex flex-column overflow-auto h-100">
              <div className="py-xxl-4 py-xl-3 py-lg-3 page-title top-0">
                {t("Manage product catalogue")}
              </div>

              <div className="flex-1 d-flex flex-column overflow-auto h-100">
                {filterobj.brand_partner_id ?
                  <div className="d-flex justify-content-between mb-2">
                    <div className="user-tabs my-3">
                      <Button
                        className={`${activeTab == "true" ? "active" : ""
                          } border-0 bg-transparent tab-btn me-4`}
                        handleClick={() => handleTabClick("true")}
                        title={t("Active")}
                      />
                      <Button
                        className={`${activeTab === "false" ? "active" : ""
                          } border-0 bg-transparent tab-btn me-4`}
                        handleClick={() => handleTabClick("false")}
                        title={t("Inactive")}
                      />
                    </div>
                    <AccessControl
                      requiredPermissions={[
                        {
                          permission: "product catalogue",
                          create: true,
                        },
                      ]}
                    >
                      <div className="d-flex">
                        <div className="text-center">
                          <InputWithButton
                            handleClick={handleSearch}
                            noerror={true}
                            placeholder={t("Search by style number")}
                            buttonClass="height45"
                          />
                        </div>
                        <div className="d-flex ms-3">
                          <Button
                            title={t("Filter")}
                            className="submit-btn height45"
                            handleClick={() =>
                              setState((prev) => ({ ...prev, filterPopup: true }))
                            }
                          />
                          <div className="mx-3">
                            <div className={`d-flex justify-content-center`}>
                              <label
                                className={`${styles.file_input} d-flex align-items-center  justify-content-center reject-btn  cursor-pointer mb-1`}
                                htmlFor="customFile"
                              >
                                <div>
                                  <Upload />
                                  <span className="ps-3">{t("Import")}</span>
                                  <input
                                    className={`visually-hidden `}
                                    type="file"
                                    accept="xls/*"
                                    id="customFile"
                                    onChange={handleImport}
                                    ref={fileInputRef}
                                  />
                                </div>
                              </label>
                            </div>
                            <p
                              className={`${styles.import_error_text} mb-0 text-gray text-break text-truncate`}
                              title={t("Please select excel file upto 10mb")}
                            >
                              {t("Please select excel file upto 10mb")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </AccessControl>
                  </div> :
                  <AccessControl
                    requiredPermissions={[
                      {
                        permission: "product catalogue",
                        create: true,
                      },
                    ]}
                  >
                    <div className="text-end">
                      <InputTextfield
                        placeholder={t('Search by name')}
                        value={stateobj.searchtext}
                        handleChange={(e) => setStateObj((prevState: any) => ({
                          ...prevState,
                          searchtext: e.target.value
                        }))}
                        className={`rounded`}
                      />
                    </div>
                  </AccessControl>
                }
                <div className="form-group mt-2 flex-1 overflow-auto d-flex flex-column h-100 overflow-auto">
                  {filterobj.brand_partner_id ?
                    <>
                      <div className="flex-1 overflow-auto">
                        <TableStructure
                          data={catalogue}
                          actionClass="w-10"
                          headers={state.filteredTableHeaders}
                          values={state.filteredTableValues}
                          isAction={true}
                          handleEdit={handleEdit}
                          handleArchive={handleArchivePopup}
                        />
                      </div>
                    </>
                    :
                    <div className="row mx-0  mb-3">
                      <div className={`border h-100 overflow-auto p-4 rounded shadow ${styles.mediaboxwithoutmodal}`}>
                        <div className="d-flex flex-wrap">
                          {filteredBrandPartners?.length ? filteredBrandPartners?.map((brandpartner: any) => (
                            <div key={brandpartner.brand_partner_id} className={`text-center ${styles?.media_folder_box_modal}`}>
                              <div
                                className={`${styles.media_folder_box_modal_inner} py-2 rounded d-flex
                                justify-content-center align-items-center flex-column
                                position-relative shadow w-100 h-100`}
                              >
                                <div
                                  id={brandpartner.brand_partner_id}
                                  className={`${styles.mediafoldericon} cursor-pointer`}
                                  onClick={() => handleBrandClick(brandpartner)}
                                >
                                  <FolderIcon />
                                </div>

                                <div
                                  className={`text-break mt-2 w-100 ${styles.edit_folder}
                                  d-flex justify-content-center flex-column px-1`}
                                >
                                  <div
                                    className={`text-break px-1 ${styles.medialibraryfoldername}`}
                                    id={brandpartner.brand_partner_id}
                                  >
                                    {brandpartner.title}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )) :
                            <div className="col-12 text-center text-danger">
                              {t(CommonConstants.NO_RESULTS_FOUND)}
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  }
                </div>
                <div className="row m-0 position-relative mt-2">
                  {stateobj.count > filterobj.limit && stateobj.totalpages > 1 && (
                    <Pagination
                      currentPage={stateobj.currentpage}
                      totalPages={stateobj.totalpages}
                      handlePaginationClick={handlePaginationClick}
                    />
                  )}
                  <div className="col-md-6 float-start p-0">
                    <Button
                      className="back-btn shadow-none float-start text-decoration-none"
                      handleClick={handleBack}
                      title={t(CommonConstants.BACK)}
                    />
                  </div>
                </div>
              </div>
            </div>

            {state.showPopup && (
              <Popup
                title={t("Upload excel file")}
                body={t(`Please upload excel file upto 10mb`)}
                cancel={() =>
                  setState((prev) => ({ ...prev, showPopup: !state.showPopup }))
                }
                submit={() =>
                  setState((prev) => ({ ...prev, showPopup: !state.showPopup }))
                }
                yestext={t("Okay")}
              />
            )}
            {state.filterPopup && (
              <Popup
                title={t("Select the columns to display in product catalogue")}
                body={
                  <ShipmentReportsFilterBody
                    setSelectedColumns={setSelectedColumns}
                    selectedColumns={selectedColumns}
                    filterValues={filterValues}
                  />
                }
                bodyclassName="text-revert"
                notext={t("Close")}
                cancel={() =>
                  setState((prev) => ({
                    ...prev,
                    filterPopup: !state.filterPopup,
                  }))
                }
              />
            )}
            {popup && (
              <Popup
                title={t("Confirmation")}
                body={t(`Are you sure you want to ${archiveState.archive == "true" ? "archive" : "activate"
                  } this?`)}
                yestext={t("Yes")}
                notext={t("No")}
                cancel={() => setPopup(false)}
                submit={handleArchive}
              />
            )}

          </WindowHeightComponent>
        </div>
      </div>
    </AccessControl>
  );
};

export default ProductCatalogue;
