import React, { useState, useEffect, useRef } from 'react';
import tinycolor from 'tinycolor2';
import InputTextfield from '../../../../components/atoms/InputTextField';
import styles from "./colorpicker.module.css"
import LabelField from '../../../../components/atoms/LabelField';
import Close from '../../../../static/icons/Close';
import Search from '../../../../static/icons/Search';
import { t } from '../../../Translations/TranslationUtils';
interface SelectedChildColors {
  [parentId: string]: { color: string; title?: string }[];
}

interface ColorPickerProps {
  selectedParentColors: string[];
  selectedChildColors: SelectedChildColors;
  setSelectedParentColors: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedChildColors: React.Dispatch<React.SetStateAction<SelectedChildColors>>;
}

const ColorPicker: React.FC<ColorPickerProps> = ({
  selectedParentColors,
  selectedChildColors,
  setSelectedChildColors,
  setSelectedParentColors,
}) => {
  const [colorShades, setColorShades] = useState<string[][]>([]);
  const colorPickerRef = useRef<HTMLDivElement>(null);
  const [searchColor, setSearchColor] = useState<string>('');
  const [error, setError] = useState<string>('');

  const mainColors = [
    { name: 'Violet', code: '#EE82EE' },
    { name: 'Indigo', code: '#4B0082' },
    { name: 'Blue', code: '#0000FF' },
    { name: 'Green', code: '#008000' },
    { name: 'Yellow', code: '#FFFF00' },
    { name: 'Orange', code: '#FFA500' },
    { name: 'Red', code: '#FF0000' },
  ];

  const handleParentColorChange = (color: { hex: string }) => {
    if (selectedParentColors.includes(color.hex)) {
      setSelectedParentColors([]);
    } else {
      setSelectedParentColors([color.hex]);
    }
  };

  const handleChildColorChange = (parentId: string, color: { hex: string }) => {
    const colorCode = color.hex;

    setSelectedChildColors((prevSelectedChildColors) => {
      const newSelectedChildColors = { ...prevSelectedChildColors };
      const parentColors = newSelectedChildColors[parentId] || [];
      const existingColorIndex = parentColors.findIndex((c) => c.color === colorCode);

      if (existingColorIndex !== -1) {
        // Color already selected, remove it
        const updatedColors = [...parentColors];
        updatedColors.splice(existingColorIndex, 1);
        newSelectedChildColors[parentId] = updatedColors;
      } else {
        // Color not selected, add it with empty title
        const newColor = { color: colorCode, title: colorCode };
        newSelectedChildColors[parentId] = [...parentColors, newColor];
      }
      setSearchedColor('');
      return newSelectedChildColors;
    });
  };

  const handleTextChange = (
    parentId: string,
    shade: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.stopPropagation(); // Prevent event bubbling to the parent div

    setSelectedChildColors((prevSelectedChildColors) => {
      const newSelectedChildColors = { ...prevSelectedChildColors };
      const parentColors = newSelectedChildColors[parentId] || [];

      const updatedColors = parentColors.map((colorObj) => {
        if (colorObj.color === shade) {
          return {
            ...colorObj,
            title: event.target.value,
          };
        }

        return colorObj;
      });

      newSelectedChildColors[parentId] = updatedColors;

      return newSelectedChildColors;
    });
  };

  //To remove selected colors
  const handleRemoveChildColor = (parentId: string, color: string) => {
    setSelectedChildColors((prevSelectedChildColors) => {
      const newSelectedChildColors = { ...prevSelectedChildColors };
      const parentColors = newSelectedChildColors[parentId] || [];
      const updatedColors = parentColors.filter((c) => c.color !== color);

      if (updatedColors.length === 0) {
        delete newSelectedChildColors[parentId]; // Remove the entry if the parent group is empty
      } else {
        newSelectedChildColors[parentId] = updatedColors;
      }
      return newSelectedChildColors;
    });
  };

  const [searchedColor, setSearchedColor] = useState<string | null>(null);
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setSearchColor(event.target.value);
    let hexcode = event.target.value;
    if (hexcode.length >= 7) {
      if (!isValidHexColor(hexcode)) {
        setError(t('Invalid hexcode'));
        return;
      }
      const isAlreadySelected = Object.values(selectedChildColors).some(colors =>
        colors.some(color => color.color === hexcode)
      );

      if (isAlreadySelected) {
        setError(t('Color already selected'));
        return;
      }
      setSearchedColor(hexcode);
      setError('');
      setSearchColor('');
    }
  };

  const isValidHexColor = (color: string): boolean => {
    const hexColorRegex = /^#[0-9a-fA-F]{6}$/;
    return hexColorRegex.test(color);
  };

  useEffect(() => {
    const shades = selectedParentColors.map((color) => generateColorShades(color, 23));
    setColorShades(shades);
  }, [selectedParentColors]);

  const generateColorShades = (baseColor: string, shadeCount: number): string[] => {
    const base = tinycolor(baseColor);
    const hsl = base.toHsl();
    const lightnessStep = (100 - hsl.l) / (shadeCount + 1);
    const saturationStep = hsl.s / (shadeCount + 1);

    const shades: string[] = [];

    // Add parent color as the first shade
    shades.push(base.toHexString());

    for (let i = 1; i <= shadeCount; i++) {
      const modifiedHsl = { ...hsl };
      modifiedHsl.l = hsl.l + lightnessStep * i;
      modifiedHsl.s = hsl.s - saturationStep * i;

      const shade = tinycolor(modifiedHsl).toHexString();
      shades.push(shade);
    }

    return shades;
  };

  return (
    <div ref={colorPickerRef}>
      <div className='d-flex justify-content-end z-index-1 position-sticky top-0'>
        <p className='mb-1'>
          <div className={`${styles.colorsearchbox} bg-white border rounded w-100 mb-1 d-flex align-items-center`}>
            <Search />
            <InputTextfield
              className='w-100 border-0'
              type="text"
              value={searchColor}
              handleChange={handleSearchChange}
              handleClick={(e) => (setError(''))}
              placeholder={t("Search by hexcode")}
            />
          </div>
          <div>
            {error && <div className='text-start' style={{ color: 'red' }}>{error}</div>}
          </div>
        </p>
      </div>
      {searchedColor && (
        <div className="p-2">
          <div className='mb-5  rounded position-relative'
            style={{
              backgroundColor: searchedColor,
              width: '50px',
              height: '70px',
              maxWidth: '150px',
              marginBottom: '10px',
              cursor: 'pointer',
            }}
            onClick={() => handleChildColorChange(searchedColor, { hex: searchedColor })}
          >
            <span className={`${styles.color_name} text-center`} style={{ position: 'absolute', bottom: '-35px' }}>{searchedColor}</span>
          </div>
        </div>
      )}
      {Object.keys(selectedChildColors).length > 0 && (
        <div className='border rounded p-3 mb-2 mb-xxl-2'>
          <LabelField
            title={t("Selected colors") + ':'}
            mandatory={true}
            className={` ${styles.label} mb-2`}
          />
          <div className='d-flex flex-wrap gap-4'>
            {Object.entries(selectedChildColors).map(([parentId, colors]) => (
              <div key={parentId} style={{ maxWidth: '150px', display: 'content' }} className={`${styles.selectedcolorbox}`}>
                {colors.map((color: any) => (
                  <div
                    className='p-1 rounded'
                    style={{
                      height: '150px',
                      width: '50px',
                      maxWidth: '150px',
                      marginBottom: '12px',
                    }}
                    key={color}
                  >
                    <div
                      className='rounded'
                      key={color.color}
                      style={{
                        backgroundColor: color.color,
                        width: '50px',
                        height: '70px',
                        maxWidth: '150px',
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <p
                        className={`${styles.closebtn}`}
                        onClick={() => handleRemoveChildColor(parentId, color.color)}
                      >
                        <Close />
                      </p>
                      <span className={`${styles.color_name} text-center`} style={{ position: 'absolute', bottom: '-35px' }}>{color.color}</span>
                      <InputTextfield
                        className={`border rounded mt-2 text-gray position-absolute text-truncate px-2 ${(color.title === '' || color.title.length > 20) ? styles.color_label : ''}`}
                        type="text"
                        value={color.title}
                        handleChange={(event) => handleTextChange(parentId, color.color, event)}
                        customStyle={{ width: '100%', textAlign: 'center', minHeight: 'unset', bottom: '-65px' }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="p-3">
        <LabelField
          title={t("Brand partner color family")}
          mandatory={true}
          className={` ${styles.label}`}
        />
        <div style={{ display: 'flex', alignItems: 'center' }} >
          {mainColors.map((color) => (
            <div
              className={`text-center d-flex rounded p-2 py-3 mx-xxl-4 mx-xl-2  ${styles.maincolor_box}  `}
              key={color.code}
              style={{
                border: selectedParentColors.includes(color.code) ? '2px solid red' : 'none',
              }}
              onClick={() => handleParentColorChange({ hex: color.code })}
            >
              <div
                className='border rounded'
                style={{
                  width: '50px',
                  height: '70px',
                  backgroundColor: color.code,
                  marginBottom: '5px',
                  border: selectedParentColors.includes(color.code) ? '2px solid red' : 'none',
                }}

              />
              <span className={`${styles.color_name} px-2 text-truncate `}>{t(color.name)}</span>
            </div>
          ))}
        </div>
      </div>

      {selectedParentColors.map((parentId) => {
        const parentColors = selectedChildColors[parentId] || [];
        return (
          <div key={parentId} className='p-3 border rounded mt-2 mt-xxl-4'>
            <LabelField
              title={mainColors.find((color) => color.code === parentId)?.name}
              mandatory={true}
              className={` ${styles.label} mb-2`}
            />
            {colorShades[selectedParentColors.indexOf(parentId)] && (
              <div className='flex-wrap d-flex'>
                {colorShades[selectedParentColors.indexOf(parentId)].map((shade) => {
                  const isSelected = parentColors.some((c) => c.color === shade);
                  return (
                    <div
                      className='p-1 rounded'
                      style={{
                        height: '150px',
                        width: '50px',
                        maxWidth: '150px',
                        marginBottom: '12px',
                        border: isSelected ? '1px solid #D20C14' : 'none',
                      }} key={shade}
                    >
                      <div
                        className='rounded'
                        key={shade}
                        style={{
                          backgroundColor: shade,
                          width: '50px',
                          maxWidth: '150px',
                          height: '70px',
                          cursor: 'pointer',
                          position: 'relative',

                        }}
                        onClick={() => handleChildColorChange(parentId, { hex: shade })}
                      >
                        <span className={`${styles.color_name} text-center`} style={{ position: 'absolute', bottom: '-35px' }}>{shade}</span>
                      </div>
                      {isSelected && (
                        <>
                          <InputTextfield
                            className='border rounded   text-gray text-truncate px-2'
                            type="text"
                            value={parentColors.find((c) => c.color === shade)?.title || ''}
                            handleChange={(event) => handleTextChange(parentId, shade, event)}
                            handleClick={(event) => event.stopPropagation()} // Prevent event bubbling to the parent div
                            customStyle={{ width: '100%', textAlign: 'center', minHeight: 'unset', marginTop: "34px" }}
                          />
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ColorPicker;
