import React, { useState, useEffect } from 'react'
import Layout from '../../pages/Shipment/Layout';
import styles from "./organisms.module.css";
import { ImageView } from '../molecules/QualityControlMolecules/ImageView';
import { ProductInfo } from '../molecules/QualityControlMolecules/ProductInfo';
import { APICALL } from '../../services/ApiServices';
import { APICALL as Axios } from '../../services/AxiosServices';
import CreateIssue from '../molecules/QualityControlMolecules/CreateIssue';
import Edit from '../../static/icons/Edit';
import CommonShipmentServices from '../../services/CommonShipmentServices';
import CommonServices from '../../services/CommonServices';
import { getqc, fetchList, updateqc, fetchEach, deleteqcissue, fetchBpwiseList } from '../../routes/ApiEndPoints';
import { CommonConstants, ConfigConstants } from '../../pages/Shipment/Constants/ShipmentConstants';
import ViewIssueMolecule from '../molecules/ViewIssueMolecule';
import { t } from "../../pages/Translations/TranslationUtils";
import Button from '../atoms/Button';
import { useNavigate } from 'react-router-dom';
import customAlert from '../atoms/CustomAlert';
import { WorkflowStageNames } from '../../utils/constants/WorkflowStageNames';
import ScreenDisable from '../../utils/ScreenDisable';
import IconData from '../../static/IconData';
import Popup from '../../utils/popup';
import * as markerjs2 from 'markerjs2';

const QualityControlOrganism: React.FC = () => {
    const navigate = useNavigate();
    const localdata = CommonShipmentServices.getPostData()
    const searchParams = new URLSearchParams(location.search);
    const editid = Number(searchParams.get("edit"));
    const [selectedIssueList, setSelectedIssueList] = useState<any>([]);
    const [viewCreateIssueBlock, setViewCreateIssueBlock] = useState(false);
    const [viewIssuesBlock, setViewIssuesBlock] = useState(true);
    const [editissue, setEditIssue] = useState([]);
    const [configdata, setConfigData] = useState<any>([]);
    const [qcdata, setqcdata] = useState<any>([]);
    const [issuecount, setissuecount] = useState(0);
    const [disableMarker, setDisableMarker] = useState(true);
    const [timer, setTimer] = useState(true);
    const [url, setUrl] = useState<any>({})
    const [currentstageid, setCurrentStageId] = useState();
    const [imageUrl, setImageUrl] = useState({
        front_image: '',
        back_image: ''
    })
    const bp_id = localStorage.getItem('bp_id');
    const [dataentry, setDataEntry] = useState<any>({})
    const [totaltime, setTotalTime] = useState(0);
    const [commentData, setCommentData] = useState({
        type: "renewal_record",
        type_id: null,
        stagename: "quality_control",
        commentdata: {}
    });
    const [areamarked, setAreaMarked] = useState({
        currentstate: null,
        imageElement: null
    });
    const [state, setState] = useState({
        deletePopup: false,
        deleteissue: null
    });
    useEffect(() => {
        const fetchConfigData = async () => {
            try {
                let postdata = {
                    "method": "POST",
                    "data": {
                        "list": [
                            ConfigConstants.ENVIRONMENT,
                            ConfigConstants.ZONE
                        ]
                    }
                }
                const response = await APICALL.service(fetchList, 'POST', postdata, true);
                if (response?.status === 200) {
                    setConfigData((prevState: any) => ({
                        ...prevState,
                        Environment: CommonServices.sortAlphabattically(response?.data?.Environment ?? []),
                        // IssueType: CommonServices.sortAlphabattically(response?.data?.IssueType ?? []),
                        Zone: CommonServices.sortAlphabattically(response?.data?.Zone ?? []),
                    }));
                }
            } catch (error) {
                console.log(error);
            }
        }
        fetchConfigData();
    }, []);

    useEffect(() => {
        const fetchBpwiseConfigData = async () => {
            try {
                let postdata = {
                    "method": "POST",
                    "data": {
                        "list": [
                            ConfigConstants.BPWISE_ISSUE_TYPE,
                        ],
                        "bp_id": [bp_id]
                    }
                }
                const response = await APICALL.service(fetchBpwiseList, 'POST', postdata, true);
                if (response?.status === 200) {
                    setConfigData((prev: any) => ({
                        ...prev,
                        IssueType: CommonServices.sortAlphabattically(response?.data?.cd_issue_type ?? [])
                    }))
                }
            } catch (error) {
                console.log(error);
            }
        }
        fetchBpwiseConfigData();
    }, [])

    useEffect(() => {
        if (localdata?.user_id != null && commentData?.type_id != null) {
            timeTracker();
        }
        return () => {
            if (localdata?.user_id != null && commentData?.type_id != null) {
                CommonShipmentServices.saveTimerApi(
                    'renewal',
                    commentData?.type_id,
                    localdata?.user_id,
                    WorkflowStageNames.QUALITYCONTROL,
                    false
                );
            }
        };
    }, [commentData?.type_id != null, localdata?.user_id != null]);

    const timeTracker = async () => {
        try {
            if (localdata?.user_id != null && commentData?.type_id != null) {
                let time = await CommonShipmentServices.saveTimerApi(
                    'renewal',
                    commentData?.type_id,
                    localdata?.user_id,
                    WorkflowStageNames.QUALITYCONTROL
                );
                // setTotalTime(time);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        const fetchFormData = async () => {
            try {
                let postdata = { ...localdata };
                postdata.quality_control_id = editid;
                const response = await APICALL.service(getqc, 'POST', postdata);
                if (response?.status === 200) {
                    if (response?.data != null && response?.data?.length != 0) {
                        let data = response?.data[0]
                        setCommentData({ ...commentData, type_id: data?.renewal_record?.renewal_id, commentdata: response?.commentdata })
                        setqcdata(data);

                        if (data.issue_count > 0) {
                            setissuecount(Number(data.issue_count))
                            setSelectedIssueList(data.quality_control_issues)
                        }

                        setCurrentStageId(response?.stage_id)
                        if (data?.renewal_record?.renewal_product_info) {
                            const datarequest = {
                                "method": "POST",
                                "data": {
                                    data: data?.renewal_record
                                }
                            }
                            const dataentryres = await APICALL.service(fetchEach, 'POST', datarequest, true);

                            if (dataentryres?.status == 200) {
                                setDataEntry(dataentryres?.data);
                                let productbackimage = (data.back_image ?? null);
                                let productfrontimage = (data.front_image ?? null);
                                if (productbackimage == null) {
                                    productbackimage = dataentryres?.data?.renewal_product_info?.back_image                                }
                                if (productfrontimage == null) {
                                    productfrontimage = dataentryres?.data?.renewal_product_info?.front_image
                                }

                                let excepttypes = ["", null, undefined]
                                setImageUrl({
                                    ...imageUrl,
                                    back_image: (!excepttypes.includes(productbackimage)) ? (productbackimage + '?t=' + Date.now()) : '',
                                    front_image: (!excepttypes.includes(productfrontimage)) ? (productfrontimage + '?t=' + Date.now()) : '',
                                })
                            }
                        }
                    } else {
                        navigate(`/shipment-dashboard`);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        }
        fetchFormData();
    }, [editid != undefined])

    const handleImageEdit = async (url: any, imagetype: string, markedarea: any) => {
        setAreaMarked(markedarea)
        let data: any = {
            image: url,
            type: imagetype
        }
        setUrl(data)
        updateState();
    }

    const handleSave = (response: any, count = false) => {
        try {
            updateState();
            if (response != '') {
                setSelectedIssueList(response?.issueslist);
                let countdet = count == true
                    ? setissuecount(prevcount => prevcount + 1)
                    : null
                if (response?.image?.url != '' && response?.image?.url != undefined) {
                    setImageUrl(prevImageUrl => ({
                        ...prevImageUrl,
                        [response?.image?.type]: (response?.image?.url + '?t=' + Date.now())
                    }));
                }
            } else {
                let image: any = imageUrl
                let imageElement: any = document.getElementById(((url?.type).replace(/_/g, '-')) + '-qc') as HTMLImageElement;
                imageElement.src = image[url?.type];
            }
            setEditIssue([]);
        } catch (error) {
            console.log(error);
        }
    }

    const handleEdit = (id: any) => {
        if (timer) {
            let issueobject = CommonShipmentServices.getFilteredObject(selectedIssueList, 'quality_control_issue_id', id);
            setEditIssue(issueobject[0]);
            updateState();
        }
    }

    const handleDeleteClick = (deleteissue: any, status = true) => {
        try {
            let deletedis: any = deleteissue != '' ? deleteissue : state.deleteissue
            let imageElement: any = document.getElementById(((deletedis.type).replace(/_/g, '-')) + '-qc') as HTMLImageElement;
            if (status) {
                setState((prev: any) => ({ ...prev, deletePopup: true, deleteissue: deleteissue }))
                let imageurl = dataentry?.renewal_product_info[deleteissue.type] != null ? dataentry?.renewal_product_info[deleteissue.type] : "";
                imageElement.src = getImageUrl(imageurl)
            }
            else {
                let url: any = imageUrl
                imageElement.src = getImageUrl(url[deletedis.type])
                setState((prev) => ({ ...prev, deletePopup: false, deleteissue: null }))
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getImageUrl = (url: any) => {
        url = (url != "" && url != undefined && url != null) ?
            url + '?t=' + Date.now() : ""
        return url;
    }

    const handleDeleteIssue = async () => {
        try {
            let qcissue: any = state.deleteissue;
            let blobdata = true;
            const formdata: any = new FormData();
            formdata.append('quality_control_id', qcdata?.quality_control_id)
            formdata.append('quality_control_issue_id', qcissue?.quality_control_issue_id)
            formdata.append('type', qcissue?.type)
    
            let restissues = selectedIssueList.filter((issue: any) => issue.quality_control_issue_id !== qcissue?.quality_control_issue_id)
            let markedAreaswithWH: any = restissues
                .filter((item: any) => item.type === qcissue?.type)
                .map((item: any) => JSON.parse(item.markedarea))
            let url = '';
            const waitForUrlUpdate = new Promise<void>((resolve) => {
                if (markedAreaswithWH != null && markedAreaswithWH.length > 0) {
                    let markedAreas = markedAreaswithWH.map((item: any) => item.markers).flat();
                    let imageElement: any = document.getElementById((qcissue?.type).replace(/_/g, '-') + '-qc') as HTMLImageElement;
                    let markerArea1 = new markerjs2.MarkerArea(imageElement);
                    markerArea1.renderState({
                        width: markedAreaswithWH[0].width,
                        height: markedAreaswithWH[0].height,
                        markers: markedAreas
                    });
                    markerArea1.addEventListener('render', event => {
                        url = event.dataUrl
                        resolve()
                    });
                } else {
                    url = dataentry?.renewal_product_info[qcissue?.type];
                    blobdata = false;
                    resolve()
                }
            });
    
            waitForUrlUpdate.then(() => {
                saveDeletedImage(formdata, url, qcissue?.type, restissues, blobdata)
            });
        } catch (error) {
            console.log(error);
        }
    }

    const saveDeletedImage = async (formdata: FormData, url: any, type: string, restissues: any, blobdata: boolean) => {
        try {
            let blob: any;
            if (blobdata) {
                blob = await CommonServices.convertBase64imageToBlob(url)
                formdata.append('image', blob)
            }
            const response: any = await Axios.service(deleteqcissue, 'POST', formdata);
    
            if (response?.status == 200) {
                setSelectedIssueList(restissues);
                setState((prev: any) => ({ ...prev, deletePopup: false, deleteissue: null }))
                setImageUrl({
                    ...imageUrl,
                    [type]: blobdata ? response?.data?.data : getImageUrl(url),
                })
            } else {
                customAlert("error", t("Error while deleting the issue"), 6000);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const updateState = () => {
        setDisableMarker(!disableMarker)
        setViewCreateIssueBlock(!viewCreateIssueBlock)
        setViewIssuesBlock(!viewIssuesBlock)
    }

    const handleSubmit = async (event: any) => {
        try {
            const data = { ...localdata };
            let postdata: any = {
                ...data,
                'quality_control_id': qcdata.quality_control_id,
                'serial_number': qcdata.serial_number,
                'back_image': CommonServices.removeSearchQueryFromUrl(imageUrl.back_image),
                'front_image': CommonServices.removeSearchQueryFromUrl(imageUrl.front_image)
            }
    
            const response = await APICALL.service(updateqc, 'POST', postdata);
    
            if (response?.status == 200) {
                navigate(`/shipment/quality-control/manage`);
            } else {
                customAlert(
                    "error",
                    t("Error while saving the quality control"),
                    6000
                )
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleBack = () => {
        navigate(`/shipment/quality-control/manage`);
    }

    const setTimerRunning = (timer: boolean) => {
        setTimer(timer);
    }

    return (
        <Layout
            pagename={('Quality control')}
            logo={true}
            timer={true}
            comments={commentData}
            hold={true}
            ongoing
            totaltime={totaltime}
            stage={WorkflowStageNames.QUALITYCONTROL}
            setTimerRunning={setTimerRunning}
        >
            <div className={`row m-0 h-75 ${timer ? '' : ' position-relative'}`} >
                <ScreenDisable display='none' />

                {/* left block start */}
                <div className="col-6 h-100 overflow-auto">
                    <div className="py-4 pb-2 px-5 border d-flex flex-column rounded shadow h-100">
                        <h3 className='text-gray text-break'>{qcdata.serial_number}</h3>

                        {/* image block start */}
                        {/* {(imageUrl.back_image != '' && imageUrl.back_image !== null) && */}

                        <div className="flex-1 overflow-auto">
                            <ImageView
                                disable={timer ? disableMarker : false}
                                handleImageEdit={handleImageEdit}
                                front_image={imageUrl?.front_image}
                                back_image={imageUrl?.back_image}
                            />
                        </div>
                        {/* } */}
                        {/* image block ends */}

                        {/* Product info block start */}
                        <ProductInfo
                            dataentry={dataentry?.renewal_product_info} />
                        {/* Product info block end  */}

                    </div>
                </div>
                {/* left block ends */}

                <div className="col-6 h-100 overflow-auto">
                    <div className="py-4 pb-2  px-xxl-5 px-4 border rounded shadow h-100 position-relative">
                        {/* right block to create issue starts */}
                        {viewCreateIssueBlock &&
                            <CreateIssue
                                configdata={configdata}
                                qcissueobject={editissue}
                                handleSubmit={handleSave}
                                qcid={editid}
                                count={issuecount}
                                url={url}
                                timer={timer}
                                markedarea={areamarked}
                                localdata={localdata}
                            />
                        }
                        {/* right block to create issue ends */}

                        {/* right block to view issue starts */}
                        {viewIssuesBlock && (
                            <div className="d-flex flex-column h-100 justify-content-between">
                                {selectedIssueList && Object.keys(configdata)?.length > 0
                                    && Object.keys(selectedIssueList)?.length > 0 ? (
                                    <div className={`${styles.itemsblock}`}>
                                        <div className='row'>
                                            {Object.values(selectedIssueList)?.map((item: any) => (
                                                <div className="mb-3 col-12 col-xl-6" key={item.quality_control_issue_id}>
                                                    <ViewIssueMolecule
                                                        key={item.quality_control_issue_id}
                                                        item={item}
                                                        configdata={configdata}
                                                        handleAction={handleEdit}
                                                        action={<div className={`${styles.edit}`} title={t('Edit')}><Edit /></div>}
                                                        // setState={setState}
                                                        setState={handleDeleteClick}
                                                        actiondelete={<div className={`${styles.edit}`} title={t('Delete')}>{IconData.DeleteIcon}</div>}
                                                        idname="quality_control_issue_id"
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ) :
                                    <div className={`${styles.itemsblock} d-flex align-items-center justify-content-center`}>
                                        <h4> {t('No issues selected')}</h4>
                                    </div>
                                }
                                <div className="d-flex justify-content-between mt-2">
                                    <Button
                                        title={t(CommonConstants.BACK)}
                                        className="back-btn shadow-none"
                                        handleClick={handleBack}
                                    />
                                    {currentstageid == dataentry.stage_id &&
                                        <Button
                                            title={t(CommonConstants.SAVE_AND_SCAN_NEXT)}
                                            className="submit-btn shadow-none"
                                            handleClick={handleSubmit}
                                        />
                                    }
                                </div>
                            </div>
                        )}
                        {/* right block to view issue ends */}
                        {/* Pop up to delete issue starts*/}
                        {state.deletePopup && (
                            <Popup
                                body={t('Are you sure you want to delete this issue?')}
                                modalSize="lg"
                                cancel={() => handleDeleteClick("", false)}
                                submit={handleDeleteIssue}
                                yestext={t('Yes')}
                                notext={t('No')}
                            />
                        )
                        }
                        {/* Pop up to delete issue ends */}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default QualityControlOrganism;
