import { useReducer, useEffect, useState, useRef } from "react";
import { UTILITYFN } from '../utility/Utility';
import LabelField from "../../../../components/atoms/LabelField";
import styles from "../../brandpartner.module.css"
import { GenerateRefreshToken } from "../../../../services/RefreshTokenService";
import InputTextfield from "../../../../components/atoms/InputTextField";
import { AddWidget } from "../../../../static/icons/AddWidget";
import Close from "../../../../static/icons/Close";
import { fetchList } from "../../../../routes/ApiEndPoints";
import { APICALL } from "../../../../services/ApiServices";
import { t } from "../../../Translations/TranslationUtils";
import ActionButtonGroup from "../../../../components/molecules/ActionButtonGroup";
import { useNavigate } from "react-router-dom";
import SelectedSizesAndDimensions from "../../../../components/molecules/SelectedSizesAndDimensions";
import customAlert from "../../../../components/atoms/CustomAlert";
import AddConfigToBrandPartner from "../../../../components/organisms/AddConfigToBrandPartner";
import FormValidation from "../../../../services/FormValidation";
import { ConfigConstants } from "../../../Shipment/Constants/ShipmentConstants";
import CommonServices from "../../../../services/CommonServices";

interface FetchedSizes {
  [key: number]: any
}

const initialForm = {
  sizes: [],
};

const sizeFieldState = {
  sizes: { errorState: false, label: "", errorLabel: t("Meter reading is a required field!") },
};

interface Props {
  draftSaved: boolean;
  backbutton: string;
  saveAsDraft: string;
  saveAndNext: string;
  arrowIosBackFill?: string;
  arrowIosBackFill2?: string;
  toggleAlertMessage: () => void;
  setAlertMessage: (message: string) => void;
  formPageState: any;
  dispatchFormPageState: any;
  setPageDirection: (page: number) => void;
}

function ThirdTab(props: Props) {
  const {
    draftSaved,
    backbutton,
    saveAsDraft,
    saveAndNext,
    arrowIosBackFill,
    arrowIosBackFill2,
    toggleAlertMessage,
    setAlertMessage,
    formPageState,
    dispatchFormPageState,
    setPageDirection
  } = props;

  function formReducer(prevState: any, newsState: any) {
    return { ...prevState, ...newsState };
  }

  const [state, dispatch] = useReducer(formReducer, initialForm);
  const [activePopError, setActivePopError] = useState(false);
  const [fetchedsizes, setFetchedSizes] = useState<FetchedSizes>([]);
  const [fetchedCategories, setFetchedCategories] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState<any>([]);
  const [optionSelected, setoptionSelected] = useState<any>({});
  const [errorState, setErrorState] = useState(sizeFieldState);
  const addSize = useRef<HTMLDivElement>(null);
  const addCategory = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  function toggleAlertPopMessage() {
    setActivePopError(!activePopError);
  }

  function DispatchErrorChanges(newState: any) {
    setErrorState(newState);
  }

  async function validateFormData(event: React.FormEvent<HTMLFormElement>, draft?: boolean) {
    event.preventDefault();
    let updatedFormPageState = { ...formPageState };
    let newErrorState = { ...sizeFieldState };

    const { requiredStatus, requiredMessage } = validateRequiredField();

    if (!draft && requiredStatus) {
      newErrorState.sizes.errorState = true;
      customAlert("error", t(requiredMessage), 6000);
      updatedFormPageState.pageThree.validationStatus = false;
      dispatchFormPageState({ ...formPageState, ...updatedFormPageState });
    } else {
      newErrorState.sizes.errorState = false;
      updatedFormPageState.pageThree.validationStatus = true;
      dispatchFormPageState({ ...formPageState, ...updatedFormPageState });

      let token = await GenerateRefreshToken();
      const bp_id = sessionStorage.getItem("brand_partner_id");
      const response = await fetch(
        process.env.REACT_APP_MASTER_DATA_URL +
        "api/master/create-brand-partner",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            // Encrypting the request data
            // method: "POST",
            // data: {
            "bp_size": state.sizes,
            "brand_partner_id": bp_id
            // },
          }),
        }
      );
      if (response?.status === 200) {
        if (draft) {
          navigate('/brand-partner/manage')
        }
        sessionStorage.setItem('sizeState', JSON.stringify(state));
        UTILITYFN.setCurrentPageData(props, 4);
      }
    }
    DispatchErrorChanges(newErrorState);
  }

  function validateRequiredField() {
    let errorResponse;
    if (state.sizes.length == 0) {
      errorResponse = {
        requiredStatus: true,
        requiredMessage: t("No size is selected")
      };
    } else {
      let invalidLabels = state.sizes?.filter((size: any) => FormValidation.nameValidation(size?.label, 10) != '');

      errorResponse = {
        requiredStatus: invalidLabels.length > 0,
        requiredMessage: invalidLabels.length > 0 ? t("Some labels are empty or exceeding 10 characters") : ""
      };
    }
    return errorResponse;
  }

  window.addEventListener('beforeunload', () => {
    sessionStorage.clear()
  });

  useEffect(() => {
    if (optionSelected.addsize) {
      window.scrollTo({
        top: addSize.current?.offsetTop,
        behavior: 'smooth',
      });
    }
    if (optionSelected.addcategory) {
      window.scrollTo({
        top: addCategory.current?.offsetTop,
        behavior: 'smooth',
      });
    }
    let newErrorState = { ...sizeFieldState };
    newErrorState.sizes.errorState = false;
    DispatchErrorChanges(newErrorState);
  }, [optionSelected]);

  useEffect(() => {
    if (sessionStorage.getItem('sizeState')) {
      const fetcheddata = JSON.parse(sessionStorage.getItem('sizeState') ?? '').sizes; // getting the size data from session storage
      const sizeids = new Set<number>(); // storing the size Ids without duplicate
      const formattedsizes = fetcheddata.filter((item: any) => { // removing the duplicate sizes
        if (!sizeids.has(item.size_id)) {
          sizeids.add(item.size_id);
          return true;
        }
        return false;
      });
      dispatch({ ...state, sizes: formattedsizes });
    } else {
      sessionStorage.setItem('sizeState', JSON.stringify(state));
    }
  }, []);

  useEffect(() => {
    fetchConfigData()
  }, [])

  const fetchConfigData = async () => {
    let postdata = {
      method: "POST",
      data: {
        list: [
          ConfigConstants.SIZE,
          ConfigConstants.CATEGORY
        ],
      },
    };
    const response = await APICALL.service(fetchList, "POST", postdata, true);
    if (response.status === 200) {
      setoptionSelected({ ...optionSelected, addsize: false })
      setFetchedSizes(response.data.Size)
      setFetchedCategories(response.data.Category)
      const selectOptions = response.data.Category.map((item: any) => { return { value: item.category_id, label: item.title } })
      setCategoryOptions(selectOptions)
    }
  };

  const handleSizeClick = (size: any) => {
    const isSizeAlreadyPresent = state.sizes?.find((s: any) => s.size_id === size.size_id);
    setoptionSelected({ ...optionSelected, size: size.size_id });
    if (isSizeAlreadyPresent == undefined) {
      dispatch({ ...state, sizes: [...state.sizes, { ...size, label: size.title }] });
    }
  }

  const handleLabelChange = (e: React.ChangeEvent<HTMLInputElement>, size_id: number) => {
    const updatedFetchedSizes = Object.values(fetchedsizes).map((sizeItem: any) => {
      if (sizeItem.size_id === optionSelected.size) {
        sizeItem.label = e.target.value
      }
      return sizeItem;
    });

    setFetchedSizes(updatedFetchedSizes);

    const updatedStateSizes = state.sizes.map((s: any) => {
      if (s.size_id === size_id) {
        return { ...s, label: e.target.value };
      }
      return s;
    });

    dispatch({ ...state, sizes: updatedStateSizes });
  }

  const addNewSize = (size: any) => {
    fetchConfigData();
    dispatch({ ...state, sizes: [...state.sizes, { ...size, label: size.title }] });
  }

  const removeSize = (size_id: number) => {
    const updatedSizes = state.sizes?.filter((size: any) => size.size_id !== size_id);
    dispatch({ ...state, sizes: updatedSizes });
  };

  const handleCategorySelect = (
    e: any) => {
    let { value, label } = e.target ?? e;
    setoptionSelected({ ...optionSelected, category: value, categoryname: label })
  }

  return (
    <>
      <form
        className="contract-meter-form flex-1 d-flex flex-column overflow-auto h-100"
        onSubmit={(e) => validateFormData(e)}
      >
        <div className="flex-1 overflow-auto">
          {activePopError ? t("Alert popup code here") : ""}
          <SelectedSizesAndDimensions
            title={t("Selected sizes")}
            selectedItems={state.sizes}
            onRemoveItem={removeSize}
            onLabelChange={handleLabelChange}
          />
          {
            <div className="border rounded p-4 mb-xxl-4 mb-3">
              <LabelField
                title={t("Select size")}
                mandatory={true}
                className={` ${styles.label} mb-3`}
              />
              <div className="d-flex flex-wrap">
                {Object.values(fetchedsizes)?.map((size: any) => (
                  <div
                    key={size.size_id}
                    className={`mb-3 position-relative p-4 py-3 rounded ${optionSelected.size === size.size_id
                      ? styles.activebrandpartnerselectBorder
                      : ""
                      } ${styles.bpsize_btn}`}
                  >
                    <div className={`position-relative d-flex flex-column h-100`}>
                      <div
                        title={size.title}
                        className={`${styles.color_btn
                          } d-flex align-items-center justify-content-center rounded text-break h-100 ${state.sizes?.some(
                            (s: any) => s.size_id === size.size_id
                          )
                            ? styles.activebrandpartnerselect
                            : ""
                          }`}
                        onClick={() =>
                          handleSizeClick({
                            ...size,
                            category_title: optionSelected.categoryname,
                          })
                        }
                      >
                        {size.title ?? ""}
                      </div>
                      {state.sizes?.some(
                        (s: any) => s.size_id === size.size_id
                      ) && (
                          <div
                            className={`${styles.selected_close_btn} position-absolute cursor-pointer top-0`}
                            onClick={() => removeSize(size.size_id)}
                          >
                            <Close />
                          </div>
                        )}
                      {optionSelected.size === size.size_id && (
                        <div className="position-relative">
                          <InputTextfield
                            className="border rounded mt-3 text-gray text-truncate"
                            value={
                              state.sizes?.find(
                                (d: any) => d.size_id === size.size_id
                              )?.label ??
                              size.title ?? "" ??
                              ""
                            }
                            handleChange={(e) =>
                              handleLabelChange(e, size.size_id)
                            }
                            handleClick={(event) => event.stopPropagation()}
                            customStyle={{ width: "100%", textAlign: "center" }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
                <div className={`rounded mb-3 p-4 py-3`}>
                  <span
                    className={`${styles.add_btn} cursor-pointer`}
                    onClick={() =>
                      setoptionSelected({ ...optionSelected, addsize: true })
                    }
                  >
                    <AddWidget />
                  </span>
                </div>
              </div>
            </div>
          }

          {optionSelected.addsize == true && (
            <div
              className="border rounded p-4 mb-3 mb-xxl-4"
              ref={addSize}
              id="addsize"
            >
              <span
                className={`float-end cursor-pointer ${styles.close_btn}`}
                onClick={() =>
                  setoptionSelected({ ...optionSelected, addsize: false })
                }
              >
                <Close />
              </span>
              <LabelField
                title={t("Add size")}
                mandatory={false}
                className={`${styles.label}`}
              />

              <AddConfigToBrandPartner
                addNewSize={addNewSize}
                category_id={optionSelected.category}
                category_title={optionSelected.categoryname}
                model={"Size"}
                handleCategorySelect={handleCategorySelect}
                categoryOptions={categoryOptions}
              />
            </div>
          )}
        </div>

        <ActionButtonGroup
          backTitle={t(backbutton)}
          saveAsDraftTitle={draftSaved ? "" : t(saveAsDraft)}
          saveAndNextTitle={t(saveAndNext)}
          handleBackClick={() => UTILITYFN.setCurrentPageData(props, 2)}
          handleSaveAsDraftClick={(e: any) => validateFormData(e, true)}
          handleSaveAndNextClick={(e: any) => validateFormData(e)}
        />
      </form>
    </>
  );
}

export default ThirdTab;
