import React, { useState } from 'react'
import Layout from '../Layout'
import styles from "./dataentry.module.css"
import Button from '../../../components/atoms/Button'
import { CommonConstants, DataEntryConstants } from '../Constants/ShipmentConstants'
import InputTextfield from '../../../components/atoms/InputTextField'
import ErrorMessage from '../../../components/atoms/ErrorMessage'
import FormValidation from '../../../services/FormValidation'
import { APICALL } from '../../../services/ApiServices'
import { fetchSKU } from '../../../routes/ApiEndPoints'
import ProductImageCard from '../../../components/atoms/ProductImageCard'
import { useNavigate } from 'react-router-dom'
import { t } from '../../Translations/TranslationUtils'

const CreateDataEntryWithSku = () => {
    const navigate = useNavigate();
    const [sku, setSku] = useState({
        value: '',
        isfetched: false
    });
    const [data, setData] = useState([]);
    const bpdetails = JSON.parse(localStorage.getItem('bp_details') ?? '{}');

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSku(prevState => ({ ...prevState, value: event.target.value }));
    };

    const validate = (value: string) => {
        let error = FormValidation.emptyValidation(value);
        return error === '';
    }

    const getSkuDetails = async () => {
        let isValidated = validate(sku.value);

        if (isValidated) {
            try {
                const postData = { sku: sku.value, bp_id: bpdetails.brand_partner_id }
                const response = await APICALL.service(fetchSKU, 'POST', postData);
                if (response?.status === 200) {
                    setData(response?.data);
                    setSku(prevState => ({ ...prevState, isfetched: true }));
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            setData([]);
        }
    }

    return (
        <Layout
            pagename={t('Data entry')}
            logo={true}
            timer={false}
        >
            {/* search row starts*/}
            <div className="d-flex align-items-start  my-3">
                <div className={`${styles.sku_title}`}>
                    {t(DataEntryConstants.ENTER_SKU_NUMBER)}
                </div>
                <div className='w-50 mx-xxl-5 mx-xl-4 mx-lg-3 mx-md-3 mx-3'>
                    <InputTextfield
                        className="form-control shadow-none"
                        value={sku.value}
                        handleChange={handleInputChange}
                        handleClick={(event) => event.stopPropagation()}
                    />
                </div>
                <div>
                    <Button
                        title={t(CommonConstants.SEARCH)}
                        className={`${styles.check_btn}`}
                        handleClick={getSkuDetails}
                    />
                </div>
            </div>
            {/* search row ends*/}
            {/* sku details  starts*/}
            {data.length !== 0 ? <div className='border rounded p-4 flex-1 overflow-auto shadow'>
                <div className="row">
                    {data.map((value: any) => (
                        <div key={value.product_catalogue_id}
                            className={`col-4 p-4 p-xxl-5`}>
                            <div className="shadow my-4 h-100 rounded border text-center cursor-pointer">
                                <ProductImageCard
                                    title={value.product_description}
                                    items={[value.product_group, value.gender, value.product_type, value.color_label, value.size]}
                                    imgClass={styles.skuimg}
                                    imgalt={value.product_description}
                                    handleClick={() => navigate(`/data-entry/overview?id=${value.product_catalogue_id}`)}
                                />
                            </div>

                        </div>
                    ))}
                </div>
            </div>
                : (sku.isfetched && <div className='col-12 text-center text-danger'>
                    {t(CommonConstants.NO_RESULTS_FOUND)}
                </div>)}
            {/* sku details  ends*/}
        </Layout>
    )
}

export default CreateDataEntryWithSku;