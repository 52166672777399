import { t } from "../../Translations/TranslationUtils";

export const ShipmentReportsConstant = {
  TABLE_HEADERS: [
    { name: t('Shipment Id'), handle: 'shipment_id', width: 10 },
    { name: t('Pallets'), handle: 'pallets', width: 10 },
    { name: t('Cartons'), handle: 'cartons', width: 10 },
    { name: t('Items'), handle: 'items', width: 10 },
    { name: t('Product group'), handle: 'product_group', width: 10 },
    { name: t('Date of delivery'), handle: 'dateof_delivery', width: 10 },
    { name: t('Location'), handle: 'location', width: 10 },
    { name: t('Weight'), handle: 'weight', width: 10 },
    { name: t('Publish status'), handle: 'publish_status', width: 10 },
  ],
  TABLE_VALUES: [
    'shipment_id',
    'pallets',
    'cartons',
    'items',
    'product_group',
    'dateof_delivery',
    'location',
    'weight',
    'publish_status',
  ],
};
