import React, { useEffect, useState } from "react";
import { fetchList, getHoldRecords } from "../../../routes/ApiEndPoints";
import { APICALL } from "../../../services/ApiServices";
import TableStructure from "../../atoms/TableStructure";
import {
  CommonConstants,
  ConfigConstants,
  HoldItemsConstants,
} from "../../../pages/Shipment/Constants/ShipmentConstants";
import CommonServices from "../../../services/CommonServices";
import CommonShipmentServices from "../../../services/CommonShipmentServices";
import Popup from "../../../utils/popup";
import { t } from "../../../pages/Translations/TranslationUtils";
import Pagination from "../../../services/Paginations";
import DownloadXlsx from "../../../services/DownloadXlsx";
import CustomFilterHeaderForReports from "../../molecules/CustomFilterHeaderForReports";
import { useUserContext } from "../../../routes/Contextlib";
import { useNavigate } from "react-router-dom";
import ActionButtonGroup from "../../molecules/ActionButtonGroup";
import MoveItemToAnyStage from "../MoveItemToAnyStage/MoveItemToAnyStage";
import IconWithLabel from "../../atoms/IconWithLabel";
import IconData from "../../../static/IconData";
import Button from "../../atoms/Button";
import WindowHeightComponent from "../../../commonComponents/WindowHeightComponent";

const ManageHoldItemsOrganism = () => {
  const [data, setData] = useState([]);
  const { user } = useUserContext();
  const navigate = useNavigate();
  const [state, setState] = useState({
    brandPartners: [],
    shipments: [],
    stages: [],
    productTiers: [],
    warehouseLocation: [],
    status: [
      { value: 1, label: t("Active") },
      { value: 2, label: t("Hold") },
      { value: 3, label: t("Published") },
      { value: 4, label: t("Rejected") },
    ],
    showPopup: false,
    movePopup: false,
    serialNumber: null,
    renewalid: null,
    stagename: "",
    totalRenewalRecordsLength: 0,
    currentpage: 1,
    totalPages: 1,
  });
  const [selectedFilterData, setSelectedFilterData] = useState({
    brand_partner_id: [],
    shipment_id: [],
    stage_id: [],
    tier: [],
    warehouse_location: [],
    status: [],
    search: "",
    offset: 0,
    limit: 10,
    orderby: "DESC",
    from_date: null,
    to_date: null,
    filter: "all",
  });

  const selectedColumns = [
    "serial_number",
    "brand_partner",
    "warehouse_location",
    "shipment_title",
    "tier_name",
    "stage_name",
    "hold_by",
    "hold_on",
  ];

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (state.brandPartners?.length) {
      fetchHoldData();
    }
  }, [state.brandPartners, selectedFilterData.offset, selectedFilterData.orderby]);

  useEffect(() => {
    if (state.brandPartners?.length) {
      fetchHoldData("shipments");
    }
  }, [selectedFilterData.brand_partner_id]);

  const fetchData = async () => {
    try {
      let configreqdata = {
        method: "POST",
        data: {
          list: [
            ConfigConstants.PRODUCTTIER,
            ConfigConstants.BRANDPARTNER,
            ConfigConstants.ACTIVE_WAREHOUSELOCATION,
          ],
        },
      };
      const configres = await APICALL.service(
        fetchList,
        "POST",
        configreqdata,
        true
      );
      if (configres.status === 200) {
        setState((prevState) => ({
          ...prevState,
          warehouseLocation: CommonServices.sortAlphabattically(configres?.data?.WarehouseLocation),
          productTiers: CommonServices.sortAlphabattically(configres?.data?.ProductTier ),
          brandPartners: CommonServices.sortAlphabattically(configres?.data?.BrandPartner),
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchHoldData = async (filter?: any) => {
    try {
      const postdata: any = { ...selectedFilterData };
      if (state.serialNumber) {
        postdata.serial_number =
          filter === "moveitemfromhold" ? "" : state.serialNumber;
        postdata.user_id = Number(localStorage?.getItem("user_id"));
      } else if (filter == "shipments") {
        postdata.filter = "shipments";
      } else if (filter === 'apply') {
        postdata.offset = 0;
        postdata.limit = 10;
        setState((prev) => ({ ...prev, currentpage: 1 }))
      } else if (filter) {
        postdata.offset = 0;
        postdata.limit = filter;
      }
      if (postdata.from_date) {
        postdata.from_date = await CommonServices.getTimezoneOffset(
          postdata.from_date
        );
      }
      if (postdata.to_date) {
        postdata.to_date = await CommonServices.getTimezoneOffset(
          postdata.to_date
        );
      }
      const response = await APICALL.service(getHoldRecords, "POST", postdata);
      if (response.status === 200) {
        if (postdata.filter === "shipments") {
          setState((prevState) => ({
            ...prevState,
            shipments: CommonServices.sortAlphabattically(response?.shipments),
            serialNumber: null,
          }));
        } else {
          const updatedByIds = response?.data?.map((item: any) =>
            item.updated_by ? Number(item.updated_by) : item.updated_by
          );
          const fetchedUsernames = await CommonServices.fetchUsernamesByIds(
            updatedByIds
          );
          const totalPages = Math.ceil(
            response.totalRenewalRecordsLength / selectedFilterData.limit
          );
          if (selectedFilterData.filter === "all") {
            setState((prevState) => ({
              ...prevState,
              totalPages,
              shipments: CommonServices.sortAlphabattically(response?.shipments),
              totalRenewalRecordsLength: response?.totalRenewalRecordsLength,
              stages: CommonServices.sortAlphabattically(
                response?.stages?.map((item: any) => ({
                  ...item,
                  stage_name: CommonServices.capitalizeLabel(
                    item?.stage_name ?? ""
                  ),
                })),
                "stage_name"
              ),
              serialNumber: null,
            }));
          } else {
            setState((prevState) => ({
              ...prevState,
              totalPages,
              totalRenewalRecordsLength: response?.totalRenewalRecordsLength,
              serialNumber: null,
            }));
          }
          // Filter items where "tier" is "active" and update stage_name
          const filteredData = response?.data.map((item: any) => {
            // Modify the stage_name property
            item.stage_name = CommonServices?.capitalizeLabel(
              item?.stages?.stage_name ?? ""
            );
            const warehouse_location = item.renewal_product_info
              ?.warehouse_location
              ? CommonShipmentServices.getFilteredTitle(
                state.warehouseLocation,
                "warehouse_location_id",
                item?.renewal_product_info?.warehouse_location
              )
              : "-";
            const tier_name = item.tier
              ? CommonShipmentServices.getFilteredTitle(
                state.productTiers,
                "product_tier_id",
                item.tier
              )
              : "-";
            const hold_on = item.updatedAt
              ? CommonServices.showDateBasedOnFormat(
                item.updatedAt,
                user?.date_format ?? "dd-MM-yyyy",
                true
              )
              : "-";
            const hold_by = item.updated_by
              ? fetchedUsernames[item.updated_by]
              : "-";
            const brand_partner = item.brand_partner_id
              ? CommonShipmentServices.getFilteredTitle(
                state.brandPartners,
                "brand_partner_id",
                item.brand_partner_id
              )
              : "-";
            const shipment_title = item.shipments?.title || "-";
            const icon = fetchIconData(item);
            const status = CommonServices.capitalizeLabel(item.status);
            return {
              ...item,
              icon,
              brand_partner: brand_partner[0],
              shipment_title,
              tier_name: tier_name[0],
              hold_on,
              warehouse_location: warehouse_location[0],
              hold_by,
              status
            };
          });

          if (filter && filter !== "shipments" && filter !== "moveitemfromhold" && filter !== 'apply') {
            const selectedFields = filteredData.map((obj: any) =>
              selectedColumns.reduce((acc: any, column: string) => {
                acc[column] = obj[column];
                return acc;
              }, {})
            );
            DownloadXlsx(selectedFields, "hold_overview");
          } else {
            // Set the filtered data as the state
            setData(filteredData);
          }
          if (selectedFilterData.filter === "all") {
            setSelectedFilterData((prevData) => ({
              ...prevData,
              filter: "",
            }));
          } else if (filter === 'apply') {
            setSelectedFilterData((prevData) => ({
              ...prevData,
              offset: 0,
              limit: 10,
            }));
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleView = async (value: any) => {
    localStorage.setItem('bpid', value.brand_partner_id)
    navigate(
      `/reports/view-renewal-record?edit=${value.renewal_id}`
    );
  };

  const fetchIconData = (item: any) => {
    switch (item.status) {
      case "active":
        return (
          <Button
            title={IconData.Openeye}
            hoverText={t("View")}
            className={
              "px-0 border-0 bg-transparent shadow-none manage-users-action me-4"
            }
            handleClick={() => handleView?.(item)}
          />
        );
      case "hold":
        return (
          <Button
            title={
              <IconWithLabel
                hoverText={t("Move item back to workflow")}
                svgData={IconData.MoveItemBackToWorkflow}
              />
            }
            className={
              "me-2 border-0 bg-transparent shadow-none manage-users-action"
            }
            handleClick={() => handlePopup(item)}
          />
        );
      case "rejected":
        return (
          <Button
            title={
              <IconWithLabel
                hoverText={t("Move item back to workflow")}
                svgData={IconData.MoveItemBackToWorkflow}
              />
            }
            className={
              "me-2 border-0 bg-transparent shadow-none manage-users-action"
            }
            handleClick={() => handlePopup(item)}
          />
        );
      case "published":
        return (
          <Button
            title={IconData.Openeye}
            hoverText={t("View")}
            className={
              "px-0 border-0 bg-transparent shadow-none manage-users-action me-4"
            }
            handleClick={() => handleView?.(item)}
          />
        );
      default:
        break;
    }
  };
  const getSelectOptions = (data: any, id: any, label: any) => {
    const convertedData: any = Object.values(data).map((item: any) => ({
      value: item[id],
      label: item[label],
    }));
    return convertedData;
  };

  const handleSelectedFilter = (value: any, key: string) => {
    setSelectedFilterData((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };
  const handleClearFilter = () => {
    setSelectedFilterData((prevValues) => ({
      ...prevValues,
      brand_partner_id: [],
      shipment_id: [],
      stage_id: [],
      tier: [],
      warehouse_location: [],
      status: [],
      search: "",
      offset: 0,
      limit: 10,
      orderby: "DESC",
      from_date: null,
      to_date: null,
      filter: "all",
    }));
  };
  // Define an array of filter objects with properties for rendering filters
  const filters: any = [
    {
      type: "multiSelect",
      label: t("Brand partner"),
      key: "brand_partner_id",
      selected: selectedFilterData.brand_partner_id,
      options: getSelectOptions(
        state.brandPartners,
        "brand_partner_id",
        "title"
      ),
    },
    {
      type: "multiSelect",
      label: t("Shipments"),
      key: "shipment_id",
      selected: selectedFilterData.shipment_id,
      options: getSelectOptions(state.shipments, "shipment_id", "title"),
    },
    {
      type: "multiSelect",
      label: t("Stage"),
      key: "stage_id",
      selected: selectedFilterData.stage_id,
      options: getSelectOptions(state.stages, "stage_id", "stage_name"),
    },
    {
      type: "multiSelect",
      label: t("Product tier"),
      key: "tier",
      selected: selectedFilterData.tier,
      options: getSelectOptions(state.productTiers, "product_tier_id", "title"),
    },
    {
      type: "multiSelect",
      label: t("Warehouse location"),
      selected: selectedFilterData.warehouse_location,
      key: "warehouse_location",
      options: getSelectOptions(
        state.warehouseLocation,
        "warehouse_location_id",
        "title"
      ),
    },
    {
      type: "multiSelect",
      label: t("status"),
      key: "status",
      selected: selectedFilterData.status,
      options: getSelectOptions(state.status, "value", "label"),
    },
    {
      type: "calender",
      label: t("From date"),
      key: "from_date",
      selected: selectedFilterData.from_date,
      max_date: selectedFilterData.to_date,
    },
    {
      type: "calender",
      label: t("To date"),
      key: "to_date",
      selected: selectedFilterData.to_date,
      min_date: selectedFilterData.from_date,
    },
    {
      type: "text",
      label: t("Search by serial number"),
      key: "search",
      selected: selectedFilterData.search,
    },
  ];

  const moveItemBack = async () => {
    await fetchHoldData();
    setState((prev) => ({ ...prev, showPopup: false, serialNumber: null }));
  };

  const handlePopup = (item: any) => {
    if (item.qa_failed) {
      setState((prev) => ({
        ...prev,
        movePopup: true,
        renewalid: item.renewal_id,
        serialNumber: item.serial_number,
        stagename: CommonServices.removeSpaceAndAddUnderscore(item.stage_name),
      }));
    } else {
      setState((prev) => ({
        ...prev,
        showPopup: true,
        serialNumber: item.serial_number,
      }));
    }
  };

  const handlePaginationClick = async (newPage: any) => {
    if (newPage === "sort") {
      setSelectedFilterData((prevData) => ({
        ...prevData,
        offset: 0,
        orderby: selectedFilterData.orderby === "ASC" ? "DESC" : "ASC",
      }));
      setState((prev) => ({
        ...prev,
        currentpage: 1,
      }));
    } else {
      // Calculate new offset based on the new page and items per page
      const newOffset = (newPage - 1) * selectedFilterData.limit;
      // Update current page and offset in the state
      setSelectedFilterData((prevData) => ({
        ...prevData,
        offset: newOffset,
      }));
      setState((prev) => ({
        ...prev,
        currentpage: newPage,
      }));
    }
  };

  return (
    <div className="row">
      <div className="col-11 ms-auto px-4">


        <WindowHeightComponent>
          <div className="d-flex flex-column overflow-auto h-100">
            <div className="py-xxl-4 py-xl-3 py-lg-3 page-title">
              {t(HoldItemsConstants.HOLDOVERVIEW)}
            </div>
            <div>
              <CustomFilterHeaderForReports
                filters={filters}
                handleSelectedFilter={handleSelectedFilter}
                // clearFilter={handleClearFilter}
                applyFilter={() => fetchHoldData('apply')}
                handleDownload={() => fetchHoldData(state.totalRenewalRecordsLength)}
              />
            </div>

            <div className="flex-1 overflow-auto">
              {data?.length <= 0 ? (
                <span className="">{t("No result found")}</span>
              ) : (
                <TableStructure
                  // isAction
                  // handleMoveBackItem={handlePopup}
                  data={data}
                  headers={HoldItemsConstants.HOLD_OVERVIEW_TABLE_HEADERS}
                  values={HoldItemsConstants.HOLD_OVERVIEW_TABLE_VALUES}
                  handleSort={handlePaginationClick}
                  sort={selectedFilterData.orderby}
                  actionClass=""
                />
              )}
            </div>
            <div className="position-relative">
              {state.totalRenewalRecordsLength > selectedFilterData.limit &&
                state.totalPages > 1 && (
                  <Pagination
                    currentPage={state.currentpage}
                    totalPages={state.totalPages}
                    handlePaginationClick={handlePaginationClick}
                  />
                )}
              <ActionButtonGroup
                backTitle={t("Back")}
                handleBackClick={() => navigate("/reports")}
              />
            </div>
          </div>
        </WindowHeightComponent>
      </div>
      {state.showPopup && (
        <Popup
          title={t("Action")}
          body={t("Do you want to return this item to the workflow") + "?"}
          cancel={() =>
            setState((prev) => ({
              ...prev,
              showPopup: false,
              serialNumber: null,
            }))
          }
          submit={() => moveItemBack()}
          yestext={t(CommonConstants.SAVE)}
          notext={t(CommonConstants.CANCEL)}
        />
      )}
      {state.movePopup && (
        <Popup
          title={t("Are you sure you want to move this item") + "?"}
          body={
            <MoveItemToAnyStage
              renewalid={state.renewalid}
              stagename={state.stagename}
              setShowMoveItemPopup={() =>
                setState((prev) => ({
                  ...prev,
                  movePopup: false,
                  serialNumber: null,
                }))
              }
              fetchHoldData={() => fetchHoldData("moveitemfromhold")}
            />
          }
          modalSize="xl"
          bodyclassName={"moving_item_popup"}
          cancel={() =>
            setState((prev) => ({
              ...prev,
              movePopup: false,
              serialNumber: null,
            }))
          }
        />
      )}

    </div>
  );
};

export default ManageHoldItemsOrganism;
