import { t } from "../Translations/TranslationUtils";
import { useNavigate } from "react-router-dom";
import IconData from "../../static/IconData";
import styles from "./reports.module.css";
import AccessControl from "../../services/AccessControl";
import ActionButtonGroup from "../../components/molecules/ActionButtonGroup";

export default function Reports() {
    const navigate = useNavigate();

    const reportLinks = [
        { title: "Shipment reports", link: "/reports/shipment", icon: IconData.ShipmentsOverview, requiredPermissions: [{ permission: "shipment reports", read: true }] },
        // { title: "Published records", link: "/reports/published", icon: IconData.ReportIcon, requiredPermissions: [{ permission: "published records", read: true }] },
        { title: "Operations overview", link: "/reports/operations-overview", icon: IconData.OperationsOverview, requiredPermissions: [{ permission: "hold overview", read: true }] },
        { title: "Time tracker overview", link: "/reports/time-tracker-overview", icon: IconData.TimeTrackerOverview, requiredPermissions: [{ permission: "time tracker overview", read: true }] },
        { title: "Sort overview", link: "/reports/sort-overview", icon: IconData.SortOverview, requiredPermissions: [{ permission: "time tracker overview", read: true }] },
    ];

    return (
        <div className="row">
            <div className="col-md-11 ms-auto px-4 mb-4">
                <div className="py-xxl-4 py-xl-3 py-lg-3 page-title">
                    {t("Reports")}
                </div>

                <div className="row gx-5 mt-3">
                    {reportLinks.map((report, index) => (
                        <AccessControl requiredPermissions={report.requiredPermissions}>
                            <div key={index} className={`col-md-6 mb-4 ${styles.reportsdashboard}`}>
                                <div className="link-border pb-3 ps-4" >
                                    <a className="text-decoration-none page-links" onClick={() => navigate(report.link)}>
                                        <span className="me-2">
                                            {report.icon}
                                        </span>{" "}
                                        {t(report.title)}
                                    </a>
                                </div>
                            </div>
                        </AccessControl>
                    ))}
                </div>
                <ActionButtonGroup
                    backTitle={t("Back")}
                    handleBackClick={()=> navigate('/')}
                />
            </div>
        </div>
    );
}
