import React, { useEffect, useReducer, useState, useRef } from "react";
import { UTILITYFN } from "../utility/Utility";
import LabelField from "../../../../components/atoms/LabelField";
import InputTextfield from "../../../../components/atoms/InputTextField";
import ErrorMessage from "../../../../components/atoms/ErrorMessage";
import styles from "../../brandpartner.module.css";
import MultiSelectField from "../../../../components/atoms/MultiSelectField";
import { APICALL as AXIOSCALL } from "../../../../services/AxiosServices";
import { APICALL } from "../../../../services/ApiServices";
import Popup from "../../../../utils/popup";
import FormValidation from "../../../../services/FormValidation";
import {
  fetchAllBrandPartnerUrl,
  fetchConfigData,
  fetchList,
} from "../../../../routes/ApiEndPoints";
import { Upload } from "../../../../static/icons/Upload";
import { t } from "../../../Translations/TranslationUtils";
import RadioField from "../../../../components/atoms/RadioField";
import ActionButtonGroup from "../../../../components/molecules/ActionButtonGroup";
import { useNavigate } from "react-router-dom";
import { CommonConstants, ConfigConstants } from "../../../Shipment/Constants/ShipmentConstants";
import customAlert from "../../../../components/atoms/CustomAlert";

interface FirstTabProps {
  backbutton: string;
  saveAsDraft: string;
  saveAndNext: string;
  formPageState: any;
  draftSaved: boolean;
  setDraftSaved: any;
  setPageDirection: (page: number) => void;
  dispatchFormPageState: (formData: any) => void;
}
interface FormErrors {
  title: string;
  warehouse_location: string;
  product_group: string;
  product_type: string;
  issue_type: string;
  work_area: string;
  wms_bp_id: string;
  brand_logo: string;
  product_tier: string;
}

interface Data {
  [key: string]: {
    discount: number;
    currency: number;
  };
}
interface WarehouseLocation {
  value: string;
  label: string;
  currency: string;
}

interface ColorData {
  color: string;
  title: string;
}

interface ConvertedObject {
  colors: {
    [colorCode: string]: ColorData[];
  };
}
interface ConfigData {
  value: string;
  label: string;
}

interface FormState {
  title: string;
  warehouse_location: WarehouseLocation[];
  product_group: ConfigData[];
  product_type: ConfigData[];
  issue_type: ConfigData[];
  work_area: ConfigData[];
  wms_bp_id: string;
  brand_logo: string;
  isSku: boolean;
  product_tier: ConfigData[];
  tier3_publishable: boolean;
}

const initialForm: FormState = {
  title: "",
  warehouse_location: [],
  product_group: [],
  product_type: [],
  issue_type: [],
  work_area: [],
  wms_bp_id: "",
  brand_logo: "",
  isSku: false,
  product_tier: [],
  tier3_publishable: false
};

const FirstTab: React.FC<FirstTabProps> = (props) => {
  const {
    draftSaved,
    setDraftSaved,
    backbutton,
    saveAsDraft,
    saveAndNext,
    formPageState,
    dispatchFormPageState,
  } = props;
  // Reducer function to manage form state
  function formReducer(prevState: FormState, newsState: Partial<FormState>) {
    return { ...prevState, ...newsState };
  }
  const searchParams = new URLSearchParams(location.search);
  const editId = Number(searchParams.get("edit"));
  const [file, setFile] = useState<File>();
  const [fileName, setFileName] = useState("");
  const [state, dispatch] = useReducer(formReducer, initialForm);
  const [hasError, setHasError] = useState<FormErrors>();
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [warehouseLocation, setWarehouseLocation] = useState<
    WarehouseLocation[]
  >([]);
  const [productGroup, setProductGroup] = useState<ConfigData[]>([]);
  const [productType, setProductType] = useState<ConfigData[]>([]);
  const [issueType, setIssueType] = useState<ConfigData[]>([]);
  const [workArea, setWorkArea] = useState<ConfigData[]>([]);
  const [productTier, setProductTier] = useState<ConfigData[]>([]);
  const [previewImage, setPreviewImage] = useState("");
  const imageInputRef = useRef<HTMLInputElement | null>(null);

  const navigate = useNavigate();
  //To handle changes of title and wms_bp_id
  function DispatchBrandPartnerChanges(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const { name, value } = event.target;
    dispatch({ [name]: value });
  }

  // to handle the changes of radio buttons
  const DispatchRadioButtonChanges = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: string
  ) => {
    let value = event.target.value;
    dispatch({ [name]: value === "yes" });
  };

  //To remove error
  const removeError = (fieldName: any) => {
    setHasError((prevErrors) => {
      if (!prevErrors) {
        return prevErrors;
      }
      return {
        ...prevErrors,
        [fieldName]: "", // Set the value of the field to an empty string
      };
    });
  };

  //To handle location changes
  function DispatchLocationChanges(
    newValue: WarehouseLocation[],
    actionMeta: any
  ) {
    dispatch({ warehouse_location: newValue });
  }

  // To handle product group changes
  function DispatchMultiSelectChanges(
    newValue: ConfigData[],
    actionMeta: any
  ) {
    dispatch({ [actionMeta.name]: newValue });
  }

  window.addEventListener("beforeunload", () => {
    sessionStorage.clear();
  });

  const fetchProductGroup = async () => {
    try {
      let postdata = {
        method: "POST",
        data: {
          list: [
            ConfigConstants.ACTIVEPRODUCTGROUP,
            ConfigConstants.PRODUCTTYPE,
            ConfigConstants.ISSUETYPE,
            ConfigConstants.ACTIVE_WAREHOUSELOCATION,
            ConfigConstants.PRODUCTTIER,
            ConfigConstants.WORKAREA
          ],
        },
      };
      const response = await APICALL.service(
        fetchList,
        "POST",
        postdata,
        true
      );

      if (response.status == "200") {
        const mappedBrandPartners: ConfigData[] = response.data['ProductGroup']?.map(
          (item: any) => ({
            value: item.product_group_id,
            label: item.title,
          })
        );
        const mappedproducttypes: ConfigData[] = response.data['ProductType']?.map(
          (item: any) => ({
            value: item.product_type_id,
            label: item.title,
          })
        );
        const mappedissuetypes: ConfigData[] = response.data['IssueType']?.map(
          (item: any) => ({
            value: item.issue_type_id,
            label: item.title
          })
        );
        const mappedworkarea: ConfigData[] = response.data['WorkArea']?.map(
          (item: any) => ({
            value: item.work_area_id,
            label: item.title
          })
        );
        const mappedproducttiers: ConfigData[] = response.data['ProductTier']?.map(
          (item: any) => ({
            value: item.product_tier_id,
            label: item.title
          })
        );
        const mappedLocations: WarehouseLocation[] =
          response.data['WarehouseLocation']?.map((item: any) => ({
            value: item.warehouse_location_id,
            label: item.title,
            currency: item?.currency
          }));

        // Sort locations alphabetically by location names
        mappedLocations?.sort((a, b) => a.label.localeCompare(b.label));

        // Sort brand partners alphabetically by name
        mappedBrandPartners?.sort((a, b) => a.label.localeCompare(b.label));
        mappedproducttypes?.sort((a, b) => a.label.localeCompare(b.label));
        mappedissuetypes?.sort((a, b) => a.label.localeCompare(b.label));
        mappedworkarea?.sort((a, b) => a.label.localeCompare(b.label));
        mappedproducttiers?.sort((a, b) => a.label.localeCompare(b.label));

        setProductGroup(mappedBrandPartners);
        setProductType(mappedproducttypes);
        setIssueType(mappedissuetypes);
        setWorkArea(mappedworkarea);
        setProductTier(mappedproducttiers);
        setWarehouseLocation(mappedLocations);
      }
    } catch (error) {
      console.error(error);
    }
  };

  async function getBrandPartners() {
    try {
      const postData = {
        method: "POST",
        data: { brandpartner_id: editId }
      }
      const response = await APICALL.service(fetchAllBrandPartnerUrl, 'POST', postData, true)

      if (response?.status === 200) {
        let responseData = response?.data.first[0];
        let productGroupdata = response?.data.first[1];
        let productTypedata = response?.data.first[2];
        let issueTypedata = response?.data.first[3];
        let workareadata = response?.data?.first[4];
        if (responseData?.issaved === true) {
          setDraftSaved(true);
        }
        if (responseData.brand_logo != null) {
          setFileName(responseData.brand_logo);
        }

        sessionStorage?.setItem("brand_partner_id", JSON.stringify(editId)); //store brand partner id in session
        dispatch({ title: responseData.title }); //store title in state
        //store warehouse location in state
        let warehouse: any =
          warehouseLocation?.filter(
            (item: any) => item.value === responseData.warehouse_location
          ) ?? "";
        dispatch({ warehouse_location: warehouse[0] });
        dispatch({ wms_bp_id: responseData.wms_bp_id }); //store wms_bp_id in state
        dispatch({
          //store product_group in state
          product_group: Object.values(productGroup)?.filter((item: any) =>
            productGroupdata.includes(item.value)
          ),
        });
        dispatch({
          //store product_type in state
          product_type: Object.values(productType)?.filter((item: any) =>
            productTypedata.includes(item.value)
          ),
        });
        dispatch({
          //store issue_type in state
          issue_type: Object.values(issueType)?.filter((item: any) =>
            issueTypedata.includes(item.value)
          ),
        });
        dispatch({
          //store issue_type in state
          work_area: Object.values(workArea)?.filter((item: any) =>
            workareadata.includes(item.value)
          ),
        });
        let producttier: any = productTier?.find(
          (item: any) => item.value === responseData.product_tier
        ) ?? null;
        dispatch({ product_tier: producttier }); // store the hold tier
        dispatch({ brand_logo: responseData.brand_logo ?? "" }); //store logo path in state
        dispatch({ isSku: responseData.isSku ?? false }); // store the radio button value in state
        dispatch({ tier3_publishable: responseData.tier3_publishable ?? false }); // store the radio button value in state
        //Setting color for edit
        const convertedObject = convertObjectToDesiredFormat(
          response?.data.second
        );

        const organizedPermissions = organizePermissionData(response?.data.sixth);

        sessionStorage.setItem(
          "sizeState",
          JSON.stringify({ sizes: response?.data.third })
        );
        sessionStorage.setItem("GeneralPropertiesState", JSON.stringify(state)); //save general data in session
        sessionStorage.setItem("colorState", JSON.stringify(convertedObject)); //save color in session
        //setting size dimension for edit
        sessionStorage.setItem(
          "dimensionsState",
          JSON.stringify({ dimensions: response?.data.fourth })
        );
        sessionStorage.setItem(
          "resalePriceState",
          JSON.stringify({ resale_price: response?.data.fifth })
        );
        sessionStorage.setItem(
          "bpPermission",
          JSON.stringify({ bpPermission: organizedPermissions })
        );
        sessionStorage.setItem(
          "merchRackState",
          JSON.stringify({ merchracks: response?.data.seventh })
        );
      }
    } catch (error) {
      console.log(error);
    }
  }

  function organizePermissionData(data: any) {
    const organizedData: any = [];

    for (const stage_id in data) {
      if (data.hasOwnProperty(stage_id)) {
        const users = data[stage_id].map((permission: any) => permission.user_id);
        organizedData.push({
          stage_id: parseInt(stage_id),
          users: users,
        });
      }
    }
    return organizedData;
  }
  //Function to get color object wthile editing
  const convertObjectToDesiredFormat = (
    givenObject: Record<string, any>
  ): ConvertedObject => {
    const outputObj: ConvertedObject = { colors: {} };
    for (const parentColor in givenObject) {
      if (Object.prototype.hasOwnProperty?.call(givenObject, parentColor)) {
        const colorData = givenObject[parentColor];
        outputObj.colors[parentColor] = colorData?.map(
          (color: Record<string, any>) => ({
            color: color.color_code,
            title: color.title,
          })
        );
      }
    }
    return outputObj;
  };

  useEffect(() => {
    let refresh = false;
    if (productGroup && warehouseLocation) {
      refresh = true;
    }

    if (editId && refresh) {
      getBrandPartners();
    }
  }, [productGroup, warehouseLocation])

  useEffect(() => {
    // Store the pageStatus object in session storage
    if (sessionStorage?.getItem("GeneralPropertiesState")) {
      dispatch({
        ...JSON.parse(sessionStorage.getItem("GeneralPropertiesState") || ""),
      });
    } else {
      sessionStorage?.setItem("GeneralPropertiesState", JSON.stringify(state));
    }
    fetchProductGroup();
  }, []);

  useEffect(() => {
    setFileName(state.brand_logo);
  }, [state.brand_logo]);

  useEffect(() => {
    if (editId) {
      sessionStorage.setItem("GeneralPropertiesState", JSON.stringify(state));
    }
  }, [state.product_group]);

  //To handle photo change
  const handlePhotoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (imageInputRef.current) {
      imageInputRef.current.value = '';
    }
    if (file == undefined) return;
    if (file && file.size < 2 * 1024 * 1024) {
      const reader: any = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
      setFile(file);
      setFileName(file.name);
    } else {
      setShowPopup(true);
    }
  };

  //To handle validations
  function checkRequiredFields(draft?: any) {
    const validateErrors: FormErrors = {
      title: "",
      wms_bp_id: "",
      brand_logo: "",
      warehouse_location: "",
      product_group: "",
      product_type: "",
      issue_type: "",
      work_area: "",
      product_tier: ""
    };
    validateErrors.title = FormValidation.nameValidation(state.title);
    validateErrors.wms_bp_id = !draft ? FormValidation.nameValidation(state.wms_bp_id ?? "", 20) : "";
    validateErrors.warehouse_location = !draft ? FormValidation.selectValidation(
      state.warehouse_location
    ) : "";

    validateErrors.product_group = !draft ? FormValidation.multiselectValidation(
      state.product_group
    ) : "";

    validateErrors.product_type = !draft ? FormValidation.multiselectValidation(
      state.product_type
    ) : "";

    validateErrors.issue_type = !draft ? FormValidation.multiselectValidation(
      state.issue_type
    ) : "";

    validateErrors.work_area = !draft ? FormValidation.multiselectValidation(
      state.work_area
    ) : "";

    validateErrors.product_tier = !draft ? FormValidation.selectValidation(
      state.product_tier
    ) : "";

    if (!file && state.brand_logo == '') {
      validateErrors.brand_logo = (!draft) ? t('This field is required') : "";
    }
    setHasError(validateErrors);
    if (
      validateErrors.title !== "" ||
      validateErrors.wms_bp_id !== "" ||
      validateErrors.warehouse_location !== "" ||
      validateErrors.brand_logo !== '' ||
      validateErrors.product_group !== "" ||
      validateErrors.product_type !== "" ||
      validateErrors.issue_type !== "" ||
      validateErrors.work_area !== "" ||
      validateErrors.product_tier !== ""
    ) {
      return false;
    }
    return true;
  }

  //Thandle submit function
  const validateFormData = async (event: any, draft?: boolean) => {
    event.preventDefault();
    let updatedFormPageState = { ...formPageState };
    const isValidated = checkRequiredFields(draft);

    if (!isValidated) {
      updatedFormPageState.pageOne.validationStatus = false;
      dispatchFormPageState({ ...formPageState, ...updatedFormPageState });
    } else {
      try {
        const bpid = sessionStorage?.getItem("brand_partner_id") ?? "";
        let loc: any = state.warehouse_location;
        let tier: any = state.product_tier;
        const formdata = new FormData();
        if (file) {
          formdata.append("bplogo", file);
        }
        formdata.append("title", state.title.toString());
        bpid && formdata.append("brand_partner_id", bpid.toString());
        (loc != null &&
          loc != "") ?
          formdata.append("warehouse_location", JSON.stringify(loc["value"])) :
          formdata.append("warehouse_location", 'null');
        state.product_group?.length > 0 ?
          formdata.append("product_group", JSON.stringify(state.product_group)) :
          formdata.append("product_group", JSON.stringify([]));
        state.product_type?.length > 0 ?
          formdata.append("product_type", JSON.stringify(state.product_type)) :
          formdata.append("product_type", JSON.stringify([]));
        state.issue_type?.length > 0 ?
          formdata.append("issue_type", JSON.stringify(state.issue_type)) :
          formdata.append("issue_type", JSON.stringify([]));
        state.work_area?.length > 0 ?
          formdata.append("work_area", JSON.stringify(state.work_area)) :
          formdata.append("work_area", JSON.stringify([]));
        (state.wms_bp_id !== '' && state.wms_bp_id !== null) ?
          formdata.append("wms_bp_id", state.wms_bp_id.toString()) :
          formdata.append("wms_bp_id", 'null');
        (tier != '' && tier != null) ?
          formdata.append("product_tier", JSON.stringify(tier['value'])) :
          formdata.append("product_tier", 'null');
        formdata.append("isSku", state.isSku.toString());
        formdata.append("tier3_publishable", state.tier3_publishable.toString());
        let response: any = await AXIOSCALL.service(
          process.env.REACT_APP_MASTER_DATA_URL +
          "api/master/create-brand-partner",
          "POST",
          formdata,
          1
        );
        if (response?.status === 200) {
          if (draft) {
            navigate('/brand-partner/manage');
          }
          let sessionvalues = JSON.parse(JSON.stringify(state));
          if (response["data"]["brand_logo"]) {
            dispatch({ brand_logo: response["data"]["brand_logo"] });
            sessionvalues.brand_logo = response["data"]["brand_logo"];
          }
          sessionStorage?.setItem(
            "GeneralPropertiesState",
            JSON.stringify(sessionvalues)
          );

          sessionStorage.setItem("brand_partner_id", response.data.bpid);
          if (sessionStorage?.getItem("brand_partner_id")) {
            updatedFormPageState.pageOne.validationStatus = true;
            dispatchFormPageState({
              ...formPageState,
              ...updatedFormPageState,
            });
            UTILITYFN.setCurrentPageData(props, 2);
          }
        } else if (response?.status === 409) {
          const validateErrors: FormErrors = {
            title: response?.message,
            wms_bp_id: "",
            brand_logo: "",
            warehouse_location: "",
            product_group: "",
            product_type: "",
            issue_type: "",
            work_area: "",
            product_tier: ""
          };
          setHasError(validateErrors);
        } else {
          customAlert('error', t("Error while updating brand partner"), 6000)
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const backToDashBoard = () => {
    sessionStorage.clear();
    if (editId) {
      navigate('/brand-partner/manage')
    } else {
      navigate('/brand-partner')
    }
  };

  return (
    <>
      <form
        noValidate
        autoComplete="off"
        className="contract-owner-form needs-validation  flex-1 overflow-auto"
      >
        <div className="row m-0">
          <div className="col-6">
            <div className="row pe-xxl-5 pe-3">
              <div className="col-6 mb-xxl-5 mb-4">
                <LabelField
                  title={t("Name") + ':'}
                  mandatory={true}
                  className={` ${styles.label}`}
                />
              </div>
              <div className="col-6">
                <InputTextfield
                  name="title"
                  id="title"
                  type="text"
                  value={state.title}
                  handleChange={DispatchBrandPartnerChanges}
                  handleClick={() => removeError("title")}
                  className={`form-control input-lg ${hasError?.title ? "border-danger" : ""
                    }`}
                />
                {hasError?.title && (
                  <ErrorMessage
                    errorMessage={t(hasError.title)}
                    className="text-danger"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-6 mb-xxl-5 mb-4">
            <div className="row ps-xxl-5 ps-3">
              <div className="col-6">
                <LabelField
                  className={` ${styles.label}`}
                  title={t("Warehouse location") + ":"}
                  mandatory={true}
                />
              </div>
              <div className="col-6">
                <MultiSelectField
                  name="warehouse_location"
                  options={warehouseLocation}
                  standards={state.warehouse_location}
                  handleChange={DispatchLocationChanges}
                  handleClick={() => removeError("warehouse_location")}
                  customStyle={
                    hasError?.warehouse_location
                      ? { border: "1px solid red", borderRadius: "5px" }
                      : undefined
                  }
                  className={`input-lg`}
                  isClearable
                  isSearchable
                />
                {hasError?.warehouse_location && (
                  <ErrorMessage
                    errorMessage={t(hasError?.warehouse_location)}
                    className="text-danger"
                  />
                )}
              </div>
            </div>
          </div>

          <div className="col-6 mb-xxl-5 mb-4">
            <div className="row pe-xxl-5 pe-3">
              <div className="col-6">
                <LabelField
                  className={` ${styles.label}`}
                  title={t("Brand partner group") + ":"}
                  mandatory={true}
                />
              </div>
              <div className="col-6">
                <MultiSelectField
                  name="product_group"
                  options={productGroup}
                  standards={state.product_group}
                  handleChange={DispatchMultiSelectChanges}
                  handleClick={() => removeError("product_group")}
                  customStyle={
                    hasError?.product_group
                      ? { border: "1px solid red", borderRadius: "5px" }
                      : undefined
                  }
                  className={`input-lg`}
                  isClearable
                  isMulti
                  isSearchable
                />
                {hasError?.product_group && (
                  <ErrorMessage
                    errorMessage={t(hasError.product_group)}
                    className="text-danger"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-6 mb-xxl-5 mb-4">
            <div className="row ps-xxl-5 ps-3">
              <div className="col-6">
                <LabelField
                  className={` ${styles.label}`}
                  title={t("WMS brand partner id") + ":"}
                  mandatory={true}
                />
              </div>
              <div className="col-6">
                <InputTextfield
                  name="wms_bp_id"
                  id="wms_bp_id"
                  type="text"
                  value={state.wms_bp_id}
                  handleChange={DispatchBrandPartnerChanges}
                  handleClick={() => removeError("wms_bp_id")}
                  className={`form-control ${hasError?.wms_bp_id ? "border-danger" : ""
                    }`}
                />
                {hasError?.wms_bp_id && (
                  <ErrorMessage
                    errorMessage={t(hasError.wms_bp_id)}
                    className="text-danger"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-6 mb-xxl-5 mb-4">
            <div className="row pe-xxl-5 pe-3">
              <div className="col-6">
                <LabelField
                  className={` ${styles.label}`}
                  title={t("Brand partner product type") + ":"}
                  mandatory={true}
                />
              </div>
              <div className="col-6">
                <MultiSelectField
                  name="product_type"
                  options={productType}
                  standards={state.product_type}
                  handleChange={DispatchMultiSelectChanges}
                  handleClick={() => removeError("product_type")}
                  customStyle={
                    hasError?.product_type
                      ? { border: "1px solid red", borderRadius: "5px" }
                      : undefined
                  }
                  className={`input-lg`}
                  isClearable
                  isMulti
                  isSearchable
                />
                {hasError?.product_type && (
                  <ErrorMessage
                    errorMessage={t(hasError.product_type)}
                    className="text-danger"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-6 mb-xxl-5 mb-4">
            <div className="row ps-xxl-5 ps-3">
              <div className="col-6">
                <LabelField
                  className={` ${styles.label}`}
                  title={t("Brand partner issue type") + ":"}
                  mandatory={true}
                />
              </div>
              <div className="col-6">
                <MultiSelectField
                  name="issue_type"
                  options={issueType}
                  standards={state.issue_type}
                  handleChange={DispatchMultiSelectChanges}
                  handleClick={() => removeError("issue_type")}
                  customStyle={
                    hasError?.issue_type
                      ? { border: "1px solid red", borderRadius: "5px" }
                      : undefined
                  }
                  className={`input-lg`}
                  isClearable
                  isMulti
                  isSearchable
                />
                {hasError?.issue_type && (
                  <ErrorMessage
                    errorMessage={t(hasError.issue_type)}
                    className="text-danger"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-6 mb-xxl-5 mb-4">
            <div className="row pe-xxl-5 pe-3">
              <div className="col-6">
                <LabelField
                  className={` ${styles.label}`}
                  title={t("Brand partner work area") + ":"}
                  mandatory={true}
                />
              </div>
              <div className="col-6">
                <MultiSelectField
                  name="work_area"
                  options={workArea}
                  standards={state.work_area}
                  handleChange={DispatchMultiSelectChanges}
                  handleClick={() => removeError("work_area")}
                  customStyle={
                    hasError?.work_area
                      ? { border: "1px solid red", borderRadius: "5px" }
                      : undefined
                  }
                  className={`input-lg`}
                  isClearable
                  isMulti
                  isSearchable
                />
                {hasError?.work_area && (
                  <ErrorMessage
                    errorMessage={t(hasError.work_area)}
                    className="text-danger"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-6 mb-xxl-5 mb-4">
            <div className="row ps-xxl-5 ps-3">
              <div className="col-6">
                <LabelField
                  className={` ${styles.label}`}
                  title={t("Hold tier") + ":"}
                  mandatory
                />
              </div>
              <div className="col-6">
                <MultiSelectField
                  name="product_tier"
                  options={productTier}
                  standards={state.product_tier}
                  handleChange={DispatchMultiSelectChanges}
                  handleClick={() => removeError("product_tier")}
                  customStyle={
                    hasError?.product_tier
                      ? { border: "1px solid red", borderRadius: "5px" }
                      : undefined
                  }
                  className={`input-lg`}
                  isClearable
                  isSearchable
                />
                {hasError?.product_tier && (
                  <ErrorMessage
                    errorMessage={t(hasError?.product_tier)}
                    className="text-danger"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-6 mb-xxl-5 mb-4">
            <div className="row pe-xxl-5 pe-3">
              <div className="col-6">
                <LabelField
                  className={` ${styles.label}`}
                  title={t("Is tier 3 publishable") + ":"}
                />
              </div>
              <div className="col-6">
                <RadioField
                  className="pe-5"
                  label={t(CommonConstants.YES)}
                  checked={state.tier3_publishable}
                  value={"yes"}
                  handleChange={
                    (e: React.ChangeEvent<HTMLInputElement>) => DispatchRadioButtonChanges(e, 'tier3_publishable')
                  }
                />
                <RadioField
                  label={t(CommonConstants.NO)}
                  checked={!state.tier3_publishable}
                  value={"no"}
                  handleChange={
                    (e: React.ChangeEvent<HTMLInputElement>) => DispatchRadioButtonChanges(e, 'tier3_publishable')
                  }
                />
              </div>
            </div>
          </div>
          <div className="col-6 mb-xxl-5 mb-4">
            <div className="row ps-xxl-5 ps-3">
              <div className="col-6">
                <LabelField
                  className={` ${styles.label}`}
                  title={t("SKU available") + ":"}
                />
              </div>
              <div className="col-6">
                <RadioField
                  className="pe-5"
                  label={t(CommonConstants.YES)}
                  checked={state.isSku}
                  value={"yes"}
                  handleChange={
                    (e: React.ChangeEvent<HTMLInputElement>) => DispatchRadioButtonChanges(e, 'isSku')
                  }
                />
                <RadioField
                  label={t(CommonConstants.NO)}
                  checked={!state.isSku}
                  value={"no"}
                  handleChange={
                    (e: React.ChangeEvent<HTMLInputElement>) => DispatchRadioButtonChanges(e, 'isSku')
                  }
                />
              </div>
            </div>
          </div>
          <div className="col-6 mb-xxl-5 mb-4">
            <div className="row pe-xxl-5 pe-3">
              <div className="col-6">
                <LabelField
                  className={` ${styles.label}`}
                  title={t("Upload logo") + ":"}
                  mandatory={true}
                />
              </div>
              <div className="col-6">
                <div
                  className="position-relative"
                  onClick={() => removeError("brand_logo")}
                >
                  <label
                    className={`${styles.file_input} w-100 text-center cursor-pointer`}
                    htmlFor="customFile"
                  >
                    <Upload />
                    <span className="ps-3">{t("Choose file")}</span>
                    <input
                      className={`visually-hidden `}
                      type="file"
                      accept="image/*"
                      id="customFile"
                      onChange={handlePhotoChange}
                      ref={imageInputRef}
                    />
                  </label>
                  {fileName ? (
                    file ? (
                      <div>
                        <div className="mt-2">{t("Preview image")}:</div>
                        <div className="border rounded text-center">
                          <img
                            src={previewImage}
                            alt={t("Preview")}
                            className={`${styles.logo_oreview_first}`}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="border rounded mt-2 text-center">
                        <img
                          src={state.brand_logo}
                          alt={t("Preview")}
                          className={`${styles.logo_oreview_first}`}
                        />
                      </div>
                    )
                  ) : (
                    <p> {t("Please select logo of 512 x 512 upto 2mb")}</p>
                  )}
                </div>
                {hasError?.brand_logo && (
                  <ErrorMessage
                    errorMessage={t(hasError.brand_logo)}
                    className="text-danger"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
      <ActionButtonGroup
        backTitle={t(backbutton)}
        saveAsDraftTitle={draftSaved ? "" : t(saveAsDraft)}
        saveAndNextTitle={t(saveAndNext)}
        handleBackClick={backToDashBoard}
        handleSaveAsDraftClick={(e: any) => validateFormData(e, true)}
        handleSaveAndNextClick={validateFormData}
      />
      {showPopup && (
        <Popup
          title={t("Upload logo")}
          body={t(`Upload a file less than 2 mb`)}
          cancel={() => setShowPopup(!showPopup)}
          submit={() => setShowPopup(!showPopup)}
          yestext={t("Okay")}
          notext={""}
        />
      )}
    </>
  );
};
export default FirstTab;
