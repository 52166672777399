import React, { useState, useEffect } from "react";
import IconData from "../../../static/IconData";

interface Props {
    published: number,
    rejected: number;
}

const PublishListPopupBody: React.FC<Props> = ({ published, rejected }) => {
    return(
        <div className="w-25 m-auto py-3">
          <div className="d-flex justify-content-around mb-2">  <span>{IconData.PublishedItems}</span>:<span className="border fw-bold px-2">{published}</span></div>
            {rejected > 0 && 
             (<div className="d-flex justify-content-around"><span>{IconData.RejectedItems}</span>:<span className="border fw-bold px-2">{rejected}</span></div>)
            }
        </div>
    )

};

export default PublishListPopupBody;