import React from 'react';
import Select, { StylesConfig, ActionMeta, CSSObjectWithLabel, MenuPlacement, Theme, components } from 'react-select';
import { t } from '../../pages/Translations/TranslationUtils';

interface CustomOptionProps extends CSSObjectWithLabel {
  backgroundColor?: string;
  color?: string;
}

export type OptionValue = {
  value: string | number;
  label: string;
  color_code?: string;
};

export type SelectOption = OptionValue & {
  children?: SelectOption[];
};

export type SelectValue = SelectOption | SelectOption[] | null;

export interface Props<T> {
  handleChange?: (newValue: T, actionMeta: ActionMeta<unknown>) => void;
  handleClick?: () => void;
  standards?: T | T[] | null;
  options?: SelectOption[];
  disabled?: boolean;
  isMulti?: boolean;
  name?: string;
  placeholder?: string;
  className?: string;
  customStyle?: React.CSSProperties;
  classNamePrefix?: string;
  isSearchable?: boolean;
  isClearable?: boolean;
  menuPlacement?: MenuPlacement;
  styles?: StylesConfig;
  value?: T | null;
  inputRef?: any;
}
// Custom Single Value component with selected color display
const CustomSingleValue: React.FC<any> = ({ children, data, ...props }) => {
  const colorCode = data?.color_code;

  return (
    <components.SingleValue {...props}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {colorCode && (
          <div
            style={{
              width: '40px',
              height: '40px',
              marginRight: '8px',
              backgroundColor: colorCode,
            }}
          />
        )}
        {children}
      </div>
    </components.SingleValue>
  );
};

// Custom Option component with color logic
const CustomOption: React.FC<any> = (props) => {
  const { children, label, data, ...rest } = props;
  const colorCode = (data as OptionValue)?.color_code;
  return (
    <components.Option {...rest}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {colorCode && (
          <div
            style={{
              width: '40px',
              height: '40px',
              marginRight: '8px',
              backgroundColor: colorCode,
            }}
          />
        )}
        {label}
      </div>
    </components.Option>
  );
};

const MultiSelect = <T extends SelectValue>({
  handleChange,
  handleClick,
  standards,
  options,
  disabled,
  isMulti = false,
  name = '',
  placeholder = t('Select'),
  className = '',
  customStyle = {},
  classNamePrefix = '',
  isSearchable = true,
  isClearable = true,
  menuPlacement = 'auto',
  styles = {},
  inputRef
}: Props<T>) => {
  const customStyles: StylesConfig = {
    control: (base) => ({
      ...base,
      height: 35,
      minHeight: 35,
      borderColor: 'red',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? 'lightgray' : undefined,
      color: 'black',
    }) as CustomOptionProps,
  };

  const handleSelectChange = (newValue: unknown, actionMeta: ActionMeta<unknown>) => {
    handleChange && handleChange(newValue as T, actionMeta);
  };

  const themeModifier = (theme: Theme): Theme => ({
    ...theme,
    borderRadius: 3,
    // Modify other theme properties if needed
  });

  const handleSelectClick = () => {
    if (handleClick) {
      handleClick();
    }
  };

  return (
    <div style={customStyle} onClick={handleSelectClick}>
      <Select
        ref={inputRef}
        styles={styles}
        components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
        placeholder={placeholder}
        value={standards}
        onChange={handleSelectChange}
        isDisabled={disabled}
        options={options}
        menuPlacement={menuPlacement}
        isSearchable={isSearchable}
        isMulti={isMulti}
        name={name}
        className={className}
        classNamePrefix={classNamePrefix || 'react-select-prefix'}
        maxMenuHeight={300}
        noOptionsMessage={() => 'No option'}
        isClearable={isClearable}
        theme={themeModifier}
      />
    </div>
  );
};

export default MultiSelect;
